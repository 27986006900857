import { call, put } from "redux-saga/effects";
import { message } from "antd";
// import { verify } from "../modules/auth/service";
import request from "./api";
import { getToken, getRefreshToken } from "./token";

message.config({
  duration: 2,
  maxCount: 1,
});

export default function* processAction(action) {
  let { params, service, success, failed, secondAction, secondParams } = action;
  try {
    const payload = yield call(service,params);
    // check if there is a second parameter then send it to reducer
    yield put({
      type: success,
      payload: secondParams ? { ...payload, secondParams } : payload,
    });
    
    if( secondAction !== undefined ) {
      // sync call action if success
      yield put({ type: secondAction.action, [Object.keys(secondAction)[1]]: Object.values(secondAction)[1]});
    }
    

    
  } catch (error) {
    //refactor later
    if (error.message === "Access Denied"){
      
      try {
        const body = {
          accessToken: getToken(),
          refreshToken: getRefreshToken(),
        }
        service = function() {
         return request('users/auth/app/authenticate', {method: "POST", body })
        } 
        const payload = yield call(service,params)
        yield put({
          type: "REFRESH_TOKEN",
          payload
        })
      } catch (error) {
        yield put({
          type: "LOGOUT_SUCCESS"
        })
        message.error("Unathorized Access");
      }
      
      
      // yield put({
      //   type: "LOGOUT_SUCCESS",
      // })
      // message.error("Session Expired")

    } else {
      if((error.status === 'SERVICE_UNAVAILABLE' || error.status === 503)) {
        message.error('The service is under maintenance. We apologize for the inconvenience.');
      }
      else if (error.details === null || error.details === undefined) {
        message.error(error.message);
      } 
      else {
        message.error(error.details);
      }
    }
    
    
    yield put({
      type: failed,
      error
    });
  }
}
