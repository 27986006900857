
import {
  Button,
  Col,
  Row,
  Divider
} from "antd";
import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import ContactModal from "./Modal";
import "./styles.css";

function IdleTimerContainer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [timer, setTimer] = useState(60000 * 60);
  const IdleTimerRef = useRef(null);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
      logOut();
    }
  }, [countDown, runTimer]);

  const seconds = (String(countDown % 60).padStart(2, 0));
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const onIdle = () => {
    setModalIsOpen(true);
    setRunTimer(true)
  };

  const logOut = () => {
    setModalIsOpen(false);
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = "/";
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
    }
    window.location.reload(true);
  };

  const stayLoggedIn = () => {
    setModalIsOpen(false);
    setTimer(60000 * 30);
    setRunTimer(false);
  };

  //Tab close event
  window.addEventListener("beforeunload", function (e) {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "";
  });

  return (
    <div>
      <ContactModal title={"Inactive Session"} width={600}
        visible={modalIsOpen}
        onOk={() => logOut()}
        onCancel={() => logOut()}
        content={
          <div  >
            <Row>
              <Divider className="add-piclup-divider-style" />
              <p className="finalize-popup-spacebar" style={{ color: "#343A40", margin: "40px 0 40px 0", fontSize: "16px" }}>
                Due to browser inactivity this past
                <span style={{ color: "#BF391E", fontWeight: 600 }}> 1 hour</span>
                , your session is about to expire. You will be log out in <span style={{ color: "#BF391E", fontWeight: 600 }}> {minutes}:{seconds}</span>  until you confirmed your session. </p>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col className="space-btwn-btns" span={11}>
                <Button key="back" className="session-stay-btn-style" onClick={() => stayLoggedIn()}>
                  Stay Connected
                </Button>
              </Col>
              <Col span={2} />
              <Col className="space-btwn-btns" span={11}>
                <Button key="back" className="session-logout-btn-style" onClick={() => logOut()}>
                  Logout
                </Button>
              </Col>
            </Row>
          </div>
        }
      />

      <IdleTimer ref={IdleTimerRef} timeout={timer} onIdle={onIdle}></IdleTimer>

    </div>
  );
}
export default IdleTimerContainer;

