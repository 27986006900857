import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/admin",
    exact: true,
    admin: true,
    role: 'ROLE_ADMIN',
    component: Loadable({
      loader: () => import("./modules"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/test",
    exact: true,
    admin: true,
    role: 'ROLE_ADMIN',
    component: Loadable({
      loader: () => import("./modules/tracking"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/surcharge",
    exact: true,
    admin: true,
    role: 'ROLE_RETAIL_ADMIN',
    component: Loadable({
      loader: () => import("./modules/surcharge"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/cod-clients",
    exact: true,
    admin: true,
    role: 'ROLE_RETAIL_ADMIN',
    component: Loadable({
      loader: () => import("./modules/cod-clients"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/finance",
    authL: true,
    exact: true,
    admin: true,
    role: 'ROLE_FINANCE',
    component: Loadable({
      loader: () => import("./modules/finance"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/kiosk",
    authL: true,
    exact: true,
    admin: true,
    role: 'ROLE_KIOSK_P',
    component: Loadable({
      loader: () => import("./modules/kiosk/pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/kiosk/book",
    exact: true,
    authL: true,
    admin: true,
    role: 'ROLE_KIOSK_P',
    component: Loadable({
      loader: () => import("./modules/kiosk/pages/add-booking-kiosk"),
      loading: LoadingComponent
    })
  },
];
