import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
    getKioskById as getKioskService,
} from "./service";
import processAction from "../../../../utils/processAction";



function* getKioskById({ id }) {
  yield processAction({
    params: id,
    service: getKioskService,
    success: actionTypes.GET_KIOSK_SUCCESS,
    failed: actionTypes.GET_KIOSK_FAILED
  });
}


function* watchKioskRequests() {
  yield all([
    takeLatest(actionTypes.GET_KIOSK, getKioskById),
  ]);
}

export default [watchKioskRequests()];
