import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import { cancelPackages as cancelPackagesService } from "./service";
import processAction from "../../utils/processAction"; 

function* cancelPackages({ body }) {
  yield processAction({
    params: body,
    service: cancelPackagesService,
    success: actionTypes.CANCEL_PACKAGES_SUCCESS,
    failed: actionTypes.CANCEL_PACKAGES_FAILED,
  });
}

function* watchDashboardRequests() {
  yield all([takeLatest(actionTypes.CANCEL_PACKAGES_REQUEST, cancelPackages)]);
}

export default [watchDashboardRequests()];
