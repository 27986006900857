
export const actionTypes = {
    GET_USER_CREDITS: "GET_USER_CREDITS",
    GET_USER_CREDITS_SUCCESS: "GET_USER_CREDITS_SUCCESS",
    GET_USER_CREDITS_FAILED: "GET_USER_CREDITS_FAILED",

    ADD_CREDITS: "ADD_CREDITS",
    ADD_CREDITS_SUCCESS: "ADD_CREDITS_SUCCESS",
    ADD_CREDITS_FAILED: "ADD_CREDITS_FAILED",

    CONSUME_CREDITS: "CONSUME_CREDITS",
    CONSUME_CREDITS_SUCCESS: "CONSUME_CREDITS_SUCCESS",
    CONSUME_CREDITS_FAILED: "CONSUME_CREDITS_FAILED",

    CLAIM_PROMO: "CLAIM_PROMO",
    CLAIM_PROMO_SUCCESS: "CLAIM_PROMO_SUCCESS",
    CLAIM_PROMO_FAILED: "CLAIM_PROMO_FAILED",

    RESET_ACTION_CREDITS: "RESET_ACTION_CREDITS",
};

export const actionCreators = {
    getUserCredits: id => ({ type: actionTypes.GET_USER_CREDITS, id }),
    consumeCredits: body => ({ type: actionTypes.CONSUME_CREDITS, body }),
    addCredits: body => ({ type: actionTypes.ADD_CREDITS, body }),
    claimPromoCode: body => ({ type: actionTypes.CLAIM_PROMO, body }),
    resetActionCredits: () => ({ type: actionTypes.RESET_ACTION_CREDITS }),

};

const initialState = {
    credits: {},
    consumation: [],
    claimCode: {},
    actionCredits: '',
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_CREDITS:
        case actionTypes.ADD_CREDITS:
        case actionTypes.CLAIM_PROMO:
        case actionTypes.CONSUME_CREDITS:
            return Object.assign({}, state, {
                isLoading: true
            });
        case actionTypes.GET_USER_CREDITS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                actionCredits: action.type,
                credits: action.payload
            });
        case actionTypes.ADD_CREDITS_SUCCESS:
            return Object.assign({}, state, {
                actionCredits: action.type,
                isLoading: false,
            });
        case actionTypes.CLAIM_PROMO_SUCCESS:
            return Object.assign({}, state, {
                actionCredits: action.type,
                isLoading: false,
                claimCode: action.payload
            });


        case actionTypes.CONSUME_CREDITS_SUCCESS:
            return Object.assign({}, state, {
                actionCredits: action.type,
                isLoading: false,
                consumation: action.payload
            });

        case actionTypes.GET_USER_CREDITS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                credits: {},
            });

        case actionTypes.ADD_CREDITS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
            });

        case actionTypes.CLAIM_PROMO_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                actionCredits: action.type,
                error: action.error,
                claimCode: {},
            });

        case actionTypes.CONSUME_CREDITS_FAILED:
            return Object.assign({}, state, {
                actionCredits: action.type,
                isLoading: false,
                error: action.error,
                consumation: [],
            });
        case actionTypes.RESET_ACTION_CREDITS:
            return Object.assign({}, state, {
                actionCredits: '',
            })


        default:
            return state;
    }
};
