import React from 'react'
import "./styles.css";
import { Link } from "react-router-dom";
import {
  Breadcrumb, Menu,
  message
} from 'antd';
// import LogoutIcon from '../../../images/logout-icon.svg';
import profile_new from '../../../images/profile_new.svg';
import addresses_new from '../../../images/addresses_new.svg';
import bank_account from '../../../images/bank_account.svg';
import logout_new from '../../../images/logout_new.svg';
import Drawer from "../../Drawer";
// import BankIcon from '../../../images/bank-icon.svg';
// import AddressIcon from '../../../images/address-icon.svg';
// import ProfileIcon from '../../../images/profile-icon.svg';
import UpdateProfile from "../../../modules/profile/pages/update/index";
import UpdateBank from "../../../modules/profile/pages/update-bank/index";
import air21Logo from "../../../images/Air21Booking.svg";
import { withRouter } from "react-router";
class Page extends React.Component {
  state = {
    collapsed: false,
    modalVisible: false,
    drawerVisible: false,
    drawerBankVisible: false,
    visibleProfile: false,
    visibleBank: false
  };


  componentDidMount() {
    const { profile } = this.props;
    const { authorities } = profile;
    if (this.props.isAuthenticated && Object.keys(this.props.profile).length) {
      this.props.getBankDetails(this.props.profile.userId);
    }
    if (authorities && authorities.length === 0) {
      const client = {
        op: "assign",
        roleDetails: [
          {
            row: 1,
            userId: this.props.profile.userId,
            roles: [
              "ROLE_COD_CLIENT"
            ]
          }
        ]
      }
      this.props.assignCodClientToUsers(client);
      this.props.resetAuthActionType()
    }
    if (this.props.actionType === "GOOGLE_LOGIN_SUCCESS" || this.props.actionType === "FACEBOOK_LOGIN_SUCCESS") {
      const client = {
        op: "assign",
        roleDetails: [
          {
            row: 1,
            userId: this.props.profile.userId,
            roles: [
              "ROLE_COD_CLIENT"
            ]
          }
        ]
      }
      this.props.assignCodClientToUsers(client);
      this.props.resetAuthActionType()
    }
  }

  componentDidUpdate() {
    if (this.props.actionType === "UPDATE_PROFILE_SUCCESS") {
      message.success("Profile updated!");
      this.setState({ visibleProfile: false });
      this.props.resetAuthActionType();
    }
  }

  handleUpdateProfile = () => {
    this.setState({ visibleProfile: true })
  };
  handleUpdateBank = () => {
    this.setState({ visibleBank: true })
  };
  handleCloseBank = () => {
    this.setState({ visibleBank: false })
  };
  handleCloseProfile = () => {
    this.setState({ visibleProfile: false })
  };
  render() {
    //destructuring state
    let { visibleBank, visibleProfile } = this.state;
    //destructuring Props
    let { profile, logout } = this.props;
    const menu = (
      <Menu>
        <Menu.Item className='dropdown-style'>
          <div onClick={() => this.handleUpdateProfile()}><img id='dropdown-icon-style' src={profile_new} alt="" /> My Profile</div>
        </Menu.Item>
        <Menu.Item className='dropdown-style'>
          <Link to="/addresses"><div><img id='dropdown-icon-style' src={addresses_new} alt="" /> My Addresses</div></Link>
        </Menu.Item>
        <Menu.Item className='dropdown-style'>
          <div onClick={() => this.handleUpdateBank()}><img id='dropdown-icon-style' src={bank_account} alt="" /> My Bank Details</div>
        </Menu.Item>
        <hr className='divider-style' />
        <Menu.Item className='dropdown-style'>
          <div id="signout" onClick={() => logout()}>
            <img id='dropdown-icon-style' src={logout_new} alt="" /> Logout
          </div>
        </Menu.Item>
      </Menu>
      // <Menu>
      //   <Menu.Item className='dropdown-style'>
      //     <div onClick={() => this.handleUpdateProfile()}><img id='dropdown-icon-style' src={ProfileIcon} alt="" /> My Profile</div>
      //   </Menu.Item>
      //   <Menu.Item className='dropdown-style'>
      //     <Link to="/addresses"><div><img id='dropdown-icon-style' src={AddressIcon} alt="" /> My Addresses</div></Link>
      //   </Menu.Item>
      //   <Menu.Item className='dropdown-style'>
      //     <div onClick={() => this.handleUpdateBank()}><img id='dropdown-icon-style' src={BankIcon} alt="" /> My Bank Details</div>
      //   </Menu.Item>
      //   <hr className='divider-style' />
      //   <Menu.Item className='dropdown-style'>
      //     <div id="signout" onClick={() => logout()}>
      //       <img id='dropdown-icon-style' src={LogoutIcon} alt="" /> Logout
      //     </div>
      //   </Menu.Item>
      // </Menu>
    );

    let pathname = this.props.history.location.pathname.replaceAll("/", '')

    return (
      <div className="modified-header">
        <div className="my-2">
          <div className="container-fluid">
            <div className="row margin-three-rl align-items-center">
              {pathname === "dashboard" || pathname === "book" ? (
                <div className='col-8 d-flex align-items-center'>
                  <div className='hand-icon-continer cusm-svg-container' onClick={() => {
                    document.querySelector(".bookingroute").style.left = "0px"
                    document.querySelector(".site-layout").style.filter = "brightness(0.5)"
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
                      <path d="M0 0V2.57143H25V0H0ZM0 7.71429V10.2857H25V7.71429H0ZM0 15.4286V18H25V15.4286H0Z" fill="#50B1A9" />
                    </svg>
                  </div>
                  <img id='MainLogoBooking' src={air21Logo} alt="" style={{ height: "30px" }}/>
                </div>
              ) : null}
              <div className={pathname === "dashboard" || pathname === "book" ? 'col-2' : 'col-10'}>
                {/* <div style={{width:'45vw'}}>
                  <Input className='search-text' placeholder="Search booking ID" />
                </div> */}
              </div>

              {/* <div className='col-10'> */}
              {/* <div style={{ width: '45vw' }}>
                  <Input className='search-text' placeholder="Search booking ID" />
                </div> */}
              {/* </div> */}

              <div className='col-2 margin-three-rl justify-content-right'>
                {<Breadcrumb.Item overlay={menu} separator="">
                  Hello,{profile.firstName}

                </Breadcrumb.Item>}
              </div>
            </div>
          </div>
        </div>
        <Drawer
          title="Update Profile"
          width={525}
          destroyOnClose={true}
          onClose={this.handleCloseProfile}
          visible={visibleProfile}
          content={<UpdateProfile onClose={() => this.handleCloseProfile()} />}
        />
        <Drawer
          title="Update Bank"
          width={525}
          destroyOnClose={true}
          onClose={this.handleCloseBank}
          visible={visibleBank}
          content={<UpdateBank onClose={() => this.handleCloseBank()} />}
        />
      </div>
    )
  }
}

export default withRouter(Page)