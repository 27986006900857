export const actionTypes = {
  GET_PROVINCE_DROPDOWNS_REQUEST: "GET_PROVINCE_REQUEST",
  GET_PROVINCE_DROPDOWNS_SUCCESS: "GET_PROVINCE_SUCCESS",
  GET_PROVINCE_DROPDOWNS_FAILED: "GET_PROVINCE_FAILED",

  GET_CITY_DROPDOWNS_PICKUP_REQUEST: "GET_CITY_DROPDOWNS_PICKUP_REQUEST",
  GET_CITY_DROPDOWNS_PICKUP_SUCCESS: "GET_CITY_DROPDOWNS_PICKUP_SUCCESS",
  GET_CITY_DROPDOWNS_PICKUP_FAILED: "GET_CITY_DROPDOWNS_PICKUP_FAILED",

  GET_CITY_DROPDOWNS_DESTINATION_REQUEST: "GET_CITY_DROPDOWNS_DESTINATION_REQUEST",
  GET_CITY_DROPDOWNS_DESTINATION_SUCCESS: "GET_CITY_DROPDOWNS_DESTINATION_SUCCESS",
  GET_CITY_DROPDOWNS_DESTINATION_FAILED: "GET_CITY_DROPDOWNS_DESTINATION_FAILED",

  GET_CALCULATOR_REQUEST: "GET_CALCULATOR_REQUEST",
  GET_CALCULATOR_SUCCESS: "GET_CALCULATOR_SUCCESS",
  GET_CALCULATOR_FAILED: "GET_CALCULATOR_FAILED",

  GET_ESTIMATE_DATES_REQUEST: "GET_ESTIMATE_DATES_REQUEST",
  GET_ESTIMATE_DATES_SUCCESS: "GET_ESTIMATE_DATES_SUCCESS",
  GET_ESTIMATE_DATES_FAILED: "GET_ESTIMATE_DATES_FAILED",

  RESET_STATE: "RESET_STATE",
  RESET_ACTION_TYPE: "RESET_STATE_ACTION_TYPE"
};

export const actionCreators = {

  getProvinceDropDown: () => ({
    type: actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST,
  }),

  getPickUpCityDropDown: (provinceId) => {
    return ({
      type: actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST,
      provinceId,
    })
  },

  getCityDropDownDestination: (provinceId) => ({
    type: actionTypes.GET_CITY_DROPDOWNS_DESTINATION_REQUEST, provinceId
  }),
  // getCalculatedValue: (body) => ({
  //   type: actionTypes.GET_CALCULATOR_REQUEST, body
  // }),
  // getEstimatedDate: (body) => ({
  //   type: actionTypes.GET_ESTIMATE_DATES_REQUEST, body
  // }),
  resetState: () => {
    return {
      type: actionTypes.RESET_STATE,
      payload: "",
    }
  },
  resetActionType: () => ({
    type: actionTypes.RESET_ACTION_TYPE
  }),
};

const initialState = {
  actionType: "",
  isLoading: false,
  cityForPickUpDropDown: {},
  cityForDestinationDropDown: {},
  provincesDropDown: [],
  // calculatedInfo: [],
  estimatedDateInfo: {},
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        actionType: action.type,
      });
    case actionTypes.GET_PROVINCE_DROPDOWNS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        provincesDropDown: action.payload,
      });
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,

      });
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        cityForPickUpDropDown: action.payload,
      });
    case actionTypes.RESET_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: "",
      });

    case actionTypes.GET_CITY_DROPDOWNS_DESTINATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_CITY_DROPDOWNS_DESTINATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cityForDestinationDropDown: action.payload,
      });

    case actionTypes.GET_CALCULATOR_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_CALCULATOR_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        calculatedInfo: action.payload,
      });

    case actionTypes.GET_ESTIMATE_DATES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_ESTIMATE_DATES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        estimatedDateInfo: action.payload,
      });
    case actionTypes.GET_ESTIMATE_DATES_FAILED:
    case actionTypes.GET_PROVINCE_DROPDOWNS_FAILED:
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_FAILED:
    case actionTypes.GET_CITY_DROPDOWNS_DESTINATION_FAILED:
    case actionTypes.GET_CALCULATOR_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.RESET_STATE:
      return Object.assign({}, state, {
        actionType: "RESET",
        isLoading: false,
        cityForPickUpDropDown: {},
        cityForDestinationDropDown: {},
        provincesDropDown: [],
        // calculatedInfo: [],
        // estimatedDateInfo: {},
        error: null,
      });
    default:
      return state;
  }
};
