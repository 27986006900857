import React from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Select,
  message,
  Checkbox,
  // Upload,
  // Icon,
  // Tooltip,
} from "antd";
import "./styles.css";
import listOfCities from "../../../../utils/listOfCities";
//import listOfIds from "../../../../utils/listOfIds";
// import listOfProvinceIds from "../../../../utils/listOfProvinceIds";

const { Option } = Select;

class Page extends React.Component {
  state = {
    city: {},
    barangay: {},
    province: {},
    //idType: "",
    //uploadedFile: null,
  };

  componentDidMount() {
    const {
      profile: { addressList },
    } = this.props;

    if (addressList.length) {
      const { cityId, provinceId } = addressList[addressList.length - 1];
      this.props.getServiceableProvinces();
      this.props.getMunicipalitiesSAC(provinceId, cityId);
    } else {
      this.props.resetAddress();
    }
  }

  componentDidUpdate(_, state) {
    if (this.props.actionType === "UPDATE_PROFILE_SUCCESS") {
      message.success("Profile updated!");
      this.props.resetBookingActionType();
      this.props.onClose();
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { form, profile, updateProfile,
      updateIdentification
    } = this.props;
    //const { uploadedFile } = this.state;
    const { accountIdentified = false } = profile;

    form.validateFields((err, fields) => {
      if (!err) {
        const body = {
          userId: profile.userId,
          firstName: fields.firstName,
          lastName: fields.lastName,
          birthday: null,
          picture: "sample.jpg",
          addressList: [
            {
              buildingUnit: fields.buildingUnit,
              street: fields.street,
              barangayId: JSON.parse(fields.barangayId).id,
              cityId: JSON.parse(fields.cityId).id,
              provinceId: JSON.parse(fields.provinceId).id,
              isActive: true,
              isPrimary: true,
            },
          ],
          businessName: fields.businessName ? fields.businessName : null,
          businessNamePreferred: fields.businessNamePreferred,
          contactList: [
            {
              phone: fields.phone,
              type: fields.type,
              isActive: true,
              isPrimary: true,
            },
          ],
        };

        if (accountIdentified) {
          updateProfile(profile.userId, body);
        }
        else {
          const formData = new FormData();
          // formData.append("idType", fields.idType);
          // formData.append("fileData", uploadedFile);
          updateIdentification(profile.userId, formData, body);
        }
      }
    });
  };

  // filterCities = () => {
  //   const { province = {}, form } = this.props;
  //   const { cities = [] } = province;
  //   const filteredList = cities.filter((city) =>
  //     listOfCities.includes(city.name)
  //   );
  //   const provinceId = form.getFieldValue("provinceId") || JSON.stringify({});
  //   const parsed = JSON.parse(provinceId);
  //   if (parsed.id === 43044) {
  //     return cities.map((item, i) => (
  //       <Option key={i} value={JSON.stringify(item)}>
  //         {item.name}
  //       </Option>
  //     ));
  //   } else {
  //     return filteredList.map((item, i) => (
  //       <Option key={i} value={JSON.stringify(item)}>
  //         {item.name}
  //       </Option>
  //     ));
  //   }
  // };

  locationValidation = () => {
    const { province = {}, city = {} } = this.props;
    if (province.id === 43044) {
      return Object.keys(city).length > 0;
    } else {
      const validateCity = listOfCities.includes(city.name);

      return validateCity;
    }
  };

  // clearFile = () => {
  //   const { form } = this.props;
  //   form.setFieldsValue({ uploadedId: undefined });
  //   this.setState({
  //     uploadedFile: null,
  //   });
  // };

  filterProvinces = () => {
    const { provinces = [] } = this.props;
    // const filteredRprovinces = provinces.filter((province) => 
    // listOfProvinceIds.includes(province.id)
    // );
    return provinces.map((item, i) => (
      <Option key={i} value={JSON.stringify(item)}>
        {item.name}
      </Option>
    ));
  };

  render() {
    const { form, isLoading, profile, city, province } = this.props;
    const { barangays = [] } = city;
    const { cities = [] } = province;
    //const { uploadedFile } = this.state;

    const {
      addressList,
      //accountIdentified = false,
      //userIdentification = {},
    } = profile;
    //const { originalFile = "", type: idType = "" } = userIdentification;

    form.getFieldDecorator("address_id", {
      initialValue: addressList.length
        ? addressList[addressList.length - 1].id
        : null,
    });

    // const uploadButton = (
    //   <div>
    //     <Icon type={this.state.loading ? "loading" : "plus"} />
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    // );

    // const uploadProps = {
    //   beforeUpload: (file) => {
    //     const isJpgOrPng =
    //       file.type === "image/jpeg" || file.type === "image/png";
    //     if (!isJpgOrPng) {
    //       return message.error("You can only upload JPG/PNG file!");
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 5;
    //     if (!isLt2M) {
    //       return message.error("Image must smaller than 5MB!");
    //     }
    //     this.setState({
    //       uploadedFile: file,
    //       loading: false,
    //     });
    //     // prevent api call
    //     return false;
    //   },
    // };
    return (
      <div>
        <Spin spinning={isLoading}>
          {!profile.contactList.length && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Alert
                  message="Please update your contact number."
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
          )}
          {!profile.addressList.length && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Alert
                  message="Please update your address."
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
          )}

          <Form onSubmit={this.onSubmit}>
            <Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <label className="profile-label-text">*First Name</label>
                    {form.getFieldDecorator("firstName", {
                      rules: [
                        { required: true, message: "This field is required" },
                      ],
                      initialValue: profile.firstName,
                    })(<Input className="material-input" />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <label className="profile-label-text">*Last Name</label>
                    {form.getFieldDecorator("lastName", {
                      rules: [
                        { required: true, message: "This field is required" },
                      ],
                      initialValue: profile.lastName,
                    })(<Input className="material-input" />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <label className="profile-label-text">Business Name</label>
                    {form.getFieldDecorator("businessName", {
                      initialValue: profile.businessName,
                      rules: [
                        {
                          required: form.getFieldValue("businessNamePreferred"),
                          message: "This field is required",
                        },
                      ],
                    })(<Input className="material-input" />)}
                  </Form.Item>

                  <Form.Item>
                    {form.getFieldDecorator("businessNamePreferred", {
                      initialValue: profile.businessNamePreferred,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        Do you want the business name to appear in the waybill?
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">Email Address</label>
                  {form.getFieldDecorator("email_address", {
                    initialValue: profile.email,
                  })(<Input className="material-input" disabled />)}
                </Form.Item>
              </Col>

              {/* <Form.Item>
                <label className="profile-label-text">
                  *ID Type:
                  <Tooltip
                    className="ml-2"
                    title="This field can only be updated once."
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </label>

                {form.getFieldDecorator("idType", {
                  rules: [{ required: true, message: "ID Type is required." }],
                  initialValue: accountIdentified ? idType : null,
                })(
                  <Select
                    showSearch
                    disabled={accountIdentified}
                    placeholder="Select Type"
                  >
                    {listOfIds.map((item, i) => (
                      <Option
                        key={i}
                        value={item.value}
                        onClick={() => {
                          this.setState({ idType: item.value });
                        }}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item> */}

              {/* <Form.Item>
                <label className="profile-label-text">
                  *Uploaded ID:
                  <Tooltip
                    className="ml-2"
                    title={
                      <>
                        <p>Valid file types for this field (JPEG,JPG,PNG). </p>
                        <p>Max File Size 5MB.</p>
                        <p>
                          Non-compliance to uploading a valid government ID will
                          prohibit you to book a package pickup.
                        </p>
                        <p>This Field can only be updated once.</p>
                      </>
                    }
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </label>
                {form.getFieldDecorator("uploadedId", {
                  rules: [{ required: true, message: "ID is required." }],
                  initialValue: accountIdentified ? originalFile : null,
                })(
                  <div className="row">
                    {accountIdentified ? null : (
                      <div className="col-lg-3">
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          {...uploadProps}
                        >
                          {uploadButton}
                        </Upload>
                      </div>
                    )}
                    <div className="col-lg-9">
                      <div className={accountIdentified ? "py-3" : "p-5"}>
                        <span>
                          {accountIdentified ? originalFile : null}{" "}
                          {uploadedFile ? uploadedFile.name : null}
                        </span>
                        {accountIdentified || !uploadedFile ? null : (
                          <Button
                            icon="delete"
                            onClick={this.clearFile}
                            className="float-right"
                            size="small"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Form.Item> */}

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item>
                    <label className="profile-label-text">*Type</label>
                    {form.getFieldDecorator("type", {
                      rules: [
                        { required: true, message: "this field is required" },
                      ],
                      initialValue: profile.contactList.length
                        ? profile.contactList[profile.contactList.length - 1]
                          .type
                        : null,
                    })(
                      <Select
                        className="entrego-select"
                        showSearch
                        placeholder="Select Type"
                      >
                        {["LANDLINE", "MOBILE"].map((item, i) => (
                          <Option key={i} value={item}>
                            {item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase()}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    <label className="profile-label-text">*Phone</label>
                    {form.getFieldDecorator("phone", {
                      rules: [
                        { required: true, message: "This field is required" },
                        {
                          pattern: /^(09)\d{9}$/,
                          message: "Invalid mobile number",
                        },
                      ],
                      initialValue: profile.contactList.length
                        ? profile.contactList[profile.contactList.length - 1]
                          .phone
                        : null,
                      getValueFromEvent: (e) => {
                        const {
                          target: { value },
                        } = e;
                        const num = value[value.length - 1];

                        // if (form.getFieldValue("type") === "FAX") {
                        //   return value;
                        // }

                        if (
                          parseInt(num) &&
                          !isNaN(num) &&
                          value.replace(/\s/g, "").length <= 11
                        ) {
                          return value;
                        } else if (
                          parseInt(num) === 0 &&
                          value.replace(/\s/g, "").length <= 11
                        ) {
                          return value;
                        } else return value.slice(0, -1);
                      },
                    })(
                      <Input
                        className="material-input"
                        disabled={!form.getFieldValue("type")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">Building Unit</label>
                  {form.getFieldDecorator("buildingUnit", {
                    initialValue: addressList.length
                      ? addressList[addressList.length - 1].buildingUnit
                      : null,
                  })(<Input className="material-input" />)}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Street Address</label>
                  {form.getFieldDecorator("street", {
                    rules: [
                      { required: true, message: "This field is required" },
                    ],
                    initialValue: addressList.length
                      ? addressList[addressList.length - 1].street
                      : null,
                  })(<Input className="material-input" />)}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <label>*Province</label>
                  {form.getFieldDecorator("provinceId", {
                    rules: [
                      { required: true, message: "This field is required." },
                    ],
                    initialValue: Object.keys(this.props.province).length
                      ? JSON.stringify({
                        id: this.props.province.id,
                        name: this.props.province.name,
                      })
                      : null,
                  })(
                    <Select
                      showSearch
                      className="entrego-select"
                      onChange={(item) => {
                        this.props.getMunicipalitiesSAC(JSON.parse(item).id);
                        form.setFieldsValue({ barangayId: null, cityId: null });
                        this.setState({ provinces: false });
                      }}
                      filterOption={this.onSearchSelect}
                      notFoundContent="Sorry - that area is not yet covered."
                    >
                      {this.filterProvinces()}

                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*City</label>
                  {form.getFieldDecorator("cityId", {
                    rules: [
                      { required: true, message: "This field is required." },
                    ],
                    initialValue: this.locationValidation()
                      ? JSON.stringify({
                        id: this.props.city.id,
                        name: this.props.city.name,
                      })
                      : null,
                  })(
                    <Select
                      showSearch
                      className="entrego-select"
                      onChange={(item) => {
                        this.props.getBarangays(JSON.parse(item).id);
                        form.setFieldsValue({ barangayId: null });
                      }}
                      style={{ width: "100%" }}
                    >
                      {/* {this.filterCities()} */}
                      {(cities || []).map((item, i) => (
                        <Option key={i} value={JSON.stringify(item)}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Barangay</label>
                  {form.getFieldDecorator("barangayId", {
                    rules: [
                      { required: true, message: "This field is required." },
                    ],
                    initialValue:
                      Object.keys(this.props.city).length &&
                        profile.addressList.length !== 0 &&
                        this.locationValidation()
                        ? JSON.stringify(
                          barangays.filter(
                            (e) =>
                              e.id ===
                              addressList[addressList.length - 1].barangayId
                          )[0]
                        )
                        : null,
                  })(
                    <Select
                      showSearch
                      className="entrego-select"
                      style={{ width: "100%" }}
                    >
                      {(barangays || []).map((item, i) => (
                        <Option key={i} value={JSON.stringify(item)}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} type="flex" justify="space-between">
              {profile.contactList.length || profile.addressList.length ? (
                <Col span={12}>
                  <Button
                    className="btn btn-white mt-3"
                    style={{ width: "100%" }}
                    onClick={this.props.onClose}
                  >
                    Cancel
                  </Button>
                </Col>
              ) : null}
              <Col span={12}>
                <Button
                  id="update-profile"
                  htmlType="submit"
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create()(Page);
