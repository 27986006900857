import React from "react";
import { Modal } from "antd";

const ModalComponent = ({
  content,
  onCancel,
  handleOk,
  title,
  visible,
  width,
  footer = null,
  style,
  className,
  closable,
  destroyOnClose,
}) => {
  return (
    <Modal
      closable={closable}
      className={className}
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={width}
      footer={footer}
      style={style}
      destroyOnClose={destroyOnClose}
    >
      {content}
    </Modal>
  );
};

export default ModalComponent;
