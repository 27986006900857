import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { actionCreators as authActionCreators } from "../../modules/auth/reducer";
import { Button, Col, Divider, Dropdown, Icon, Menu, Row, message } from "antd";
import ProfileModal from "../Modal";
import Drawer from "../Drawer";
import UpdateProfile from "../../modules/profile/pages/update/index";
import UpdateBank from "../../modules/profile/pages/update-bank/index";
import PropTypes from "prop-types";
import "./styles.css";
import BankIcon from '../../images/bank-icon.svg'
import AddressIcon from '../../images/address-icon.svg'
import LogoutIcon from '../../images/logout-icon.svg'
import ProfileIcon from '../../images/profile-icon.svg'
import Air21Logo from '../../images/Air21Booking.svg'
class Header extends Component {
  state = {
    collapsed: false,
    modalVisible: false,
    drawerVisible: false,
    drawerBankVisible: false,
    visibleProfile: false,
    visibleBank: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated && Object.keys(this.props.profile).length) {
      this.props.getBankDetails(this.props.profile.userId);
    }
  }

  componentDidUpdate() {
    if (this.props.actionType === "UPDATE_PROFILE_SUCCESS") {
      message.success("Profile updated!");
      this.setState({ visibleProfile: false });
      this.props.resetAuthActionType();
    }
  }

  handleUpdateProfile = () => {
    this.setState({ visibleProfile: true });
  };
  handleCloseProfile = () => {
    this.setState({ visibleProfile: false });
  };

  handleUpdateBank = () => {
    this.setState({ visibleBank: true });
  };
  handleCloseBank = () => {
    this.setState({ visibleBank: false });
  };

  render() {
    const { profile, bookings, bankDetails, isAuthenticated } = this.props;
    const menu = (
      <Menu>
        <Menu.Item>
          <div onClick={() => this.handleUpdateProfile()}><img src={ProfileIcon} alt="" /> My Profile</div>
        </Menu.Item>
        <Menu.Item>
        <Link to="/addresses"><div><img src={AddressIcon} alt="" /> My Addresses</div></Link>
        </Menu.Item>
        <Menu.Item>
        <div onClick={() => this.handleUpdateBank()}><img src={BankIcon} alt="" /> My Bank Details</div>
        </Menu.Item>
        <hr style={{width: '50%', textAlign: 'center'}} />

        <Menu.Item>
          <div id="signout" onClick={() => this.props.logout()}>
          <img src={LogoutIcon} alt="" /> Logout
          </div>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="header">
        <div className="my-2">
          <div className="container-fluid">
            <div className="row margin-five-rl align-items-center">
              {!this.state.collapsed ? (
                <div className="mr-auto p-2">
                  <Link to="/dashboard">
                    <img
                      src={Air21Logo}
                      className="logo"
                      alt="air21_logo"
                    />
                  </Link>
                </div>
              ) : null}
              {this.state.collapsed ? (
                <div className="mr-auto p-2 d-lg-block d-xl-block">
                  {this.props.isAuthenticated ? (
                    <Icon
                      type="setting"
                      className="margin-five font-icon text-gray"
                      onClick={() => this.setState({ modalVisible: true })}
                    />
                  ) : null}
                  {isAuthenticated ? (
                    <Link className="margin-five" to="/rates">
                      RATES
                    </Link>
                  ) : null}
                  <Link className="margin-five" to="/faqs">
                    FAQS
                  </Link>
                  <a
                    href="https://www.air21.com.ph/contact/"
                    className="margin-five text-gray"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </div>
              ) : null}
              {isAuthenticated ? (
                <div className="d-none d-lg-block mx-3 p-2">
                  <Link to="/rates">RATES</Link>
                </div>
              ) : null}
              <div className="text-gray d-none d-lg-block mx-3 p-2">
                Contact us:{" "}
                <a href="mailto:customercare@af2100.com">
                customercare@af2100.com
                </a>
              </div>
              <div className="p-2 d-none d-lg-block">
                {this.props.isAuthenticated ? (
                  <div className="icon-container">
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomCenter"
                    >
                      <Icon
                        type="setting"
                        className="margin-five font-icon text-gray"
                      />
                    </Dropdown>
                  </div>
                ) : null}
              </div>
              {this.props.isAuthenticated && this.state.collapsed ? ( 
                <span
                  id="signout"
                  className="d-lg-none d-xl-none text-gray pointer"
                  onClick={() => this.props.logout()}
                >
                  Signout
                </span>
               ) : null}
              <div className="p-2 d-lg-none d-xl-none">
                <Button
                  onClick={() => {
                    this.setState({
                      collapsed: !this.state.collapsed,
                    });
                  }}
                  style={{
                    border: "none",
                    padding: 0,
                    background: "transparent",
                  }}
                >
                  <Icon
                    type={this.state.collapsed ? "close" : "menu-fold"}
                    style={{
                      cursor: "pointer",
                      color: "#707070",
                      fontSize: 26,
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          title="Update Profile"
          width={525}
          destroyOnClose={true}
          onClose={this.handleCloseProfile}
          visible={this.state.visibleProfile}
          content={<UpdateProfile onClose={this.handleCloseProfile} />}
        />
        <Drawer
          title="Update Bank"
          width={525}
          destroyOnClose={true}
          onClose={this.handleCloseBank}
          visible={this.state.visibleBank}
          content={<UpdateBank onClose={this.handleCloseBank} />}
        />

        <ProfileModal
          className="profile-modal"
          style={{ top: "0", height: "100%" }}
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          content={
            <Row
              gutter={32}
              type="flex"
              justify="center"
              style={{ background: "#F0FAFE", padding: "50px" }}
            >
              <Col xs={24} lg={9} className="details">
                <Row style={{ flexDirection: "column" }}>
                  <Col className="profile-container">
                    <p style={{ width: "100%" }}>
                      <span
                        className="section-color font-weight-bold"
                        style={{ fontSize: "20px" }}
                      >
                        USER DETAILS
                      </span>
                      <br />
                      <span className="details-created-at text-gray">
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/user.png"
                              alt="user"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        {profile.firstName} {profile.lastName}
                      </span>
                      <br />
                      <span className="details-created-at text-gray">
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/phone_gray.png"
                              alt="phone"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        {/* {profile.contactList !== undefined
                        ? profile.contactList[profile.contactList.length - 1].phone
                        : "N/A"} */}
                        {profile.contactList === undefined
                          ? "N/A"
                          : profile.contactList.length
                          ? profile.contactList[profile.contactList.length - 1]
                              .phone
                          : "N/A"}
                      </span>
                      <br />

                      <span className="details-created-at text-gray">
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/mail_gray.png"
                              alt="mail"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />{" "}
                        {profile.email}
                      </span>
                      <br />
                      <span className="details-created-at text-gray">
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/time.png"
                              alt="time"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />{" "}
                        Created:{" "}
                        {moment(profile.registrationDate).format(
                          "MMMM D, YYYY"
                        )}
                      </span>
                      <br />
                      <span className="details-created-at text-gray">
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/login.png"
                              alt="login"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />{" "}
                        Last Login:{" "}
                        {moment(profile.lastLogin).format(
                          "MMMM D, YYYY hh:mm A"
                        )}
                      </span>
                      <br />
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={2}>
                <Divider className="div-horizontal" />
              </Col>

              <Col xs={24} lg={9} className="details">
                <Row style={{ flexDirection: "column" }}>
                  <Col className="profile-container">
                    {bankDetails !== undefined ? (
                      <p style={{ width: "100%" }}>
                        <span
                          className="section-color font-weight-bold"
                          style={{ fontSize: "20px" }}
                        >
                          BANK DETAILS
                        </span>
                        <br />
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/bank.png"
                              alt="bank"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        <span className="details-created-at text-gray">
                          {Object.keys(bankDetails).length
                            ? bankDetails.bankProviderName
                            : "No Bank Details Found"}
                          {/* {bankDetails} {profile.lastName} */}
                        </span>
                        <br />
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/phone_gray.png"
                              alt="phone"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        <span className="details-created-at text-gray">
                          {Object.keys(bankDetails).length
                            ? bankDetails.accountType
                            : null}
                        </span>
                        <br />
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/acctname.png"
                              alt="account"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        <span className="details-created-at text-gray">
                          {Object.keys(bankDetails).length
                            ? bankDetails.accountName
                            : null}
                        </span>
                        <br />
                        <Icon
                          component={() => (
                            <img
                              src="https://s3-ap-southeast-1.amazonaws.com/entrego-hyperion/wallet.png"
                              alt="wallet"
                              className="mr-1"
                              height="15"
                              width="15"
                            />
                          )}
                        />
                        <span className="details-created-at text-gray">
                          {Object.keys(bankDetails).length
                            ? bankDetails.accountNumber
                            : null}
                        </span>
                      </p>
                    ) : (
                      <span className="section-color">BANK DETAILS</span>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={2}>
                <Divider className="div-horizontal" />
              </Col>

              <Col xs={24} lg={9} className="details pt-0">
                <Row style={{ flexDirection: "column" }}>
                  <Col className="details-bookings mt-0 mb-2">
                    <Row type="flex" justify="space-between">
                      <Col xs={24}>
                        <span
                          className="section-color font-weight-bold"
                          style={{ fontSize: "20px" }}
                        >
                          TOTAL BOOKINGS
                        </span>
                        <div className="text-gray" style={{ display: "flex" }}>
                          <span>{bookings.length}</span> Bookings
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={9} className="details">
                <Row style={{ textAlign: "center" }}>
                  <Col xs={24}>
                    <Button
                      className="btn btn-orange"
                      onClick={() => this.setState({ drawerVisible: true })}
                    >
                      UPDATE PROFILE
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={9} className="details">
                <Row style={{ textAlign: "center" }}>
                  <Col xs={24}>
                    <Button
                      className="btn btn-orange"
                      onClick={() => this.setState({ drawerBankVisible: true })}
                    >
                      UPDATE BANK
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Drawer
                title="Update Profile"
                width={525}
                destroyOnClose={true}
                onClose={() => this.setState({ drawerVisible: false })}
                visible={this.state.drawerVisible}
                content={<UpdateProfile onClose={this.onClose} />}
              />

              <Drawer
                title="Update Bank"
                width={525}
                destroyOnClose={true}
                onClose={() => this.setState({ drawerBankVisible: false })}
                visible={this.state.drawerBankVisible}
                content={<UpdateBank onClose={this.onClose} />}
              />
            </Row>
          }
        />
      </div>
    );
  }
}

Header.defaultProps = {
  isAuthenticated: false,
  profile: {},
  bookings: {},
  bankDetails: {},
  actionType: "",
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.instanceOf(Object),
  bookings: PropTypes.instanceOf(Object),
  bankDetails: PropTypes.instanceOf(Object),
  actionType: PropTypes.string,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  profile: state.auth.profile,
  bookings: state.booking.bookings,
  bankDetails: state.auth.bankDetails,
  actionType: state.auth.actionType,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(authActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
