import React from "react";
import { Drawer } from "antd";
import "./styles.css";

const DrawerComponent = ({
  content,
  onClose,
  title,
  visible,
  destroyOnClose,
  width,
  booking,
  ...props
}) => {
  return (
    <Drawer
      className="drawer"
      title={title}
      onClose={onClose}
      destroyOnClose={destroyOnClose}
      visible={visible}
      width={width}
      booking={booking}
      {...props}
    >
      {content}
    </Drawer>
  );
};

export default DrawerComponent;
