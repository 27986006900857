import { setToken, deleteToken } from "../../utils/token";

export const actionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILED: "LOGOUT_FAILED",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILED: "REGISTER_FAILED",

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED",

  GET_BANK: "GET_BANK",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  GET_BANK_FAILED: "GET_BANK_FAILED",

  GET_BANK_DETAILS: "GET_BANK_DETAILS",
  GET_BANK_DETAILS_SUCCESS: "GET_BANK_DETAILS_SUCCESS",
  GET_BANK_DETAILS_FAILED: "GET_BANK_FAILED",

  UPDATE_BANK_DETAILS: "UPDATE_BANK_DETAILS",
  UPDATE_BANK_DETAILS_SUCCESS: "UPDATE_BANK_DETAILS_SUCCESS",
  UPDATE_BANK_DETAILS_FAILED: "UPDATE_BANK_FAILED",

  CREATE_BANK_DETAILS: "CREATE_BANK_DETAILS",
  CREATE_BANK_DETAILS_SUCCESS: "CREATE_BANK_DETAILS_SUCCESS",
  CREATE_BANK_DETAILS_FAILED: "CREATE_BANK_FAILED",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

  UPDATE_IDENTIFICATION: "UPDATE_IDENTIFICATION",
  UPDATE_IDENTIFICATION_SUCCESS: "UPDATE_IDENTIFICATION_SUCCESS",
  UPDATE_IDENTIFICATION_FAILED: "UPDATE_IDENTIFICATION_FAILED",

  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILED: "GET_LOCATION_FAILED",

  GOOGLE_LOGIN_REQUEST: "GOOGLE_LOGIN_REQUEST",
  GOOGLE_LOGIN_SUCCESS: "GOOGLE_LOGIN_SUCCESS",
  GOOGLE_LOGIN_FAILED: "GOOGLE_LOGIN_FAILED",

  FACEBOOK_LOGIN_REQUEST: "FACEBOOK_LOGIN_REQUEST",
  FACEBOOK_LOGIN_SUCCESS: "FACEBOOK_LOGIN_SUCCESS",
  FACEBOOK_LOGIN_FAILED: "FACEBOOK_LOGIN_FAILED",

  GET_AUTHENTICATION_REQUEST: "GET_AUTHENTICATION_REQUEST",
  GET_AUTHENTICATION_SUCCESS: "GET_AUTHENTICATION_SUCCESS",
  GET_AUTHENTICATION_FAILED: "GET_AUTHENTICATION_FAILED",

  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_FAILED: "VERIFY_FAILED",

  RESEND_VERIFICATION_REQUEST: "RESEND_VERIFICATION_REQUEST",
  RESEND_VERIFICATION_SUCCESS: "RESEND_VERIFICATION_SUCCESS",
  RESEND_VERIFICATION_FAILED: "RESEND_VERIFICATION_FAILED",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",

  RENEW_PASSWORD_REQUEST: "RENEW_PASSWORD_REQUEST",
  RENEW_PASSWORD_SUCCESS: "RENEW_PASSWORD_SUCCESS",
  RENEW_PASSWORD_FAILED: "RENEW_PASSWORD_FAILED",

  CLEAR_BANK_DETAILS_REQUEST: "CLEAR_BANK_DETAILS_REQUEST",
  CLEAR_BANK_DETAILS_SUCCESS: "CLEAR_BANK_DETAILS_SUCCESS",
  CLEAR_BANK_DETAILS_FAILED: "CLEAR_BANK_DETAILS_FAILED",

  FORGOT_PASSWORD_VALIDATE_REQUEST: "FORGOT_PASSWORD_VALIDATE_REQUEST",
  FORGOT_PASSWORD_VALIDATE_SUCCESS: "FORGOT_PASSWORD_VALIDATE_SUCCESS",
  FORGOT_PASSWORD_VALIDATE_FAILED: "FORGOT_PASSWORD_VALIDATE_FAILED",

  CEP_PROFILE_REQUEST: "CEP_PROFILE_REQUEST",
  CEP_PROFILE_SUCCESS: "CEP_PROFILE_SUCCESS",
  CEP_PROFILE_FAILED: "CEP_PROFILE_FAILED",

  UPDATE_BANK_PROOF: "UPDATE_BANK_PROOF",
  UPDATE_BANK_PROOF_SUCCESS: "UPDATE_BANK_PROOF_SUCCESS",
  UPDATE_BANK_PROOF_FAILED: "UPDATE_BANK_PROOF_FAILED",

  RESET_AUTH_ACTION_TYPE: "RESET_AUTH_ACTION_TYPE",
  RESET_ERROR: "RESET_ERROR",
  REFRESH_TOKEN: "REFRESH_TOKEN",

  RESET_BANK_PROOF_ACTION_TYPE: "RESET_BANK_PROOF_ACTION_TYPE",
};

export const actionCreators = {
  login: body => ({ type: actionTypes.LOGIN_REQUEST, body }),
  googleLogin: body => ({ type: actionTypes.GOOGLE_LOGIN_REQUEST, body }),
  facebookLogin: body => ({ type: actionTypes.FACEBOOK_LOGIN_REQUEST, body }),
  googleLoginFailed: () => ({
    type: actionTypes.GOOGLE_LOGIN_FAILED
  }),
  facebookLoginFailed: () => ({
    type: actionTypes.FACEBOOK_LOGIN_FAILED
  }),
  verify: body => ({ type: actionTypes.VERIFY_REQUEST, body }),
  resendVerification: body => ({
    type: actionTypes.RESEND_VERIFICATION_REQUEST,
    body
  }),
  forgotPassword: body => ({ type: actionTypes.FORGOT_PASSWORD_REQUEST, body }),
  renewPassword: body => ({ type: actionTypes.RENEW_PASSWORD_REQUEST, body }),
  forgotPasswordValidateToken: body => ({
    type: actionTypes.FORGOT_PASSWORD_VALIDATE_REQUEST,
    body
  }),
  register: body => ({ type: actionTypes.REGISTER_REQUEST, body }),
  logout: () => ({ type: actionTypes.LOGOUT_REQUEST }),
  getProfile: id => ({ type: actionTypes.GET_PROFILE, id }),
  getBankDetails: id => ({ type: actionTypes.GET_BANK_DETAILS, id }),
  createBankDetails: body => ({ type: actionTypes.CREATE_BANK_DETAILS, body }),
  updateBankDetails: body => ({ type: actionTypes.UPDATE_BANK_DETAILS, body }),
  getBank: () => ({ type: actionTypes.GET_BANK }),
  getAddress: address => ({ type: actionTypes.GET_LOCATION_REQUEST, address }),
  updateProfile: (id, body) => ({ type: actionTypes.UPDATE_PROFILE, id, body }),
  updateIdentification: (id, body, profilePayload) => ({ type: actionTypes.UPDATE_IDENTIFICATION, id, body, profilePayload }),
  uploadBankProof: (body) => ({ type: actionTypes.UPDATE_BANK_PROOF, body}),
  resetAuthActionType: () => ({ type: actionTypes.RESET_AUTH_ACTION_TYPE }),
  resetError: () => ({ type: actionTypes.RESET_ERROR }),
  clearBankDetails: id => ({ type: actionTypes.CLEAR_BANK_DETAILS_REQUEST, id }),
  resetBankProofActiontype: () => ({ type: actionTypes.RESET_BANK_PROOF_ACTION_TYPE }),
};

const initialState = {
  actionType: "",
  isAuthenticated: false,
  isLoading: false,
  accessToken: null,
  error: null,
  device: null,
  location: null,
  bank: [],
  bankDetails: {},
  verify: {},
  profile: {},
  registeredId: null,
  tokenIsValid: true,
  bankProofActionType: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.REGISTER_REQUEST:
    case actionTypes.GET_PROFILE:
    case actionTypes.GET_BANK:
    case actionTypes.GET_BANK_DETAILS:
    case actionTypes.CREATE_BANK_DETAILS:
    case actionTypes.UPDATE_BANK_DETAILS:
    case actionTypes.GET_LOCATION_REQUEST:
    case actionTypes.UPDATE_PROFILE:
    case actionTypes.UPDATE_IDENTIFICATION:
    case actionTypes.LOGOUT_REQUEST:
    case actionTypes.RENEW_PASSWORD_REQUEST:
    case actionTypes.FORGOT_PASSWORD_REQUEST:
    case actionTypes.FORGOT_PASSWORD_VALIDATE_REQUEST:
    case actionTypes.GOOGLE_LOGIN_REQUEST:
    case actionTypes.FACEBOOK_LOGIN_REQUEST:
    case actionTypes.GET_AUTHENTICATION_REQUEST:
    case actionTypes.VERIFY_REQUEST:
    case actionTypes.RESEND_VERIFICATION_REQUEST:
    case actionTypes.CLEAR_BANK_DETAILS_REQUEST:
    case actionTypes.UPDATE_BANK_PROOF:    
      return Object.assign({}, state, {
        isLoading: true
      });

    case actionTypes.GET_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        location: action.payload.address,
        isLoading: false,
      });

    case actionTypes.REFRESH_TOKEN:
      setToken(action.payload.accessToken, action.payload.refreshToken);
      return Object.assign({}, state, {
        isLoading: false,
        errors: null
      });

    case actionTypes.GOOGLE_LOGIN_SUCCESS:
    case actionTypes.FACEBOOK_LOGIN_SUCCESS:
    case actionTypes.LOGIN_SUCCESS:
      setToken(action.payload.accessToken, action.payload.refreshToken);
      return Object.assign({}, state, {
        actionType:action.type,
        isLoading: false,
        errors: null,
        isAuthenticated: true,
        device: window.navigator.userAgent,
        profile: action.payload
      });

    case actionTypes.LOGOUT_SUCCESS:
      deleteToken();
      return Object.assign({}, state, {
        isLoading: false,
        isAuthenticated: false,
        accessToken: null
      });

    case actionTypes.REGISTER_SUCCESS:
      return Object.assign({}, state, {
        registeredId: action.payload.userId,
        isLoading: false,
        actionType: actionTypes.REGISTER_SUCCESS
      });
    
    case actionTypes.CREATE_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bankDetails: action.payload,
        actionType: actionTypes.CREATE_BANK_DETAILS_SUCCESS
      });

    case actionTypes.UPDATE_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bankDetails: action.payload,
        actionType: actionTypes.UPDATE_BANK_DETAILS_SUCCESS
    });
    case actionTypes.GET_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        profile: action.payload
      });

    case actionTypes.GET_BANK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bank: action.payload
      });

    case actionTypes.CLEAR_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bank: action.payload
      });
  
    case actionTypes.GET_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bankDetails: action.payload
      });

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        profile: action.payload,
        actionType: actionTypes.UPDATE_PROFILE_SUCCESS
      });

    case actionTypes.UPDATE_IDENTIFICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.UPDATE_IDENTIFICATION_SUCCESS
      });
  

    case actionTypes.RESET_AUTH_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: ""
      });

    case actionTypes.RESET_BANK_PROOF_ACTION_TYPE:
      return Object.assign({}, state, {
        bankProofActionType: ""
      });

    case actionTypes.GET_AUTHENTICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      });

    case actionTypes.RESET_ERROR:
      return Object.assign({}, state, {
        error: null,
        isLoading: false
      });
    case actionTypes.VERIFY_SUCCESS:
      return Object.assign({}, state, {
        verify: action.payload,
        error: null,
        isLoading: false
      });

    case actionTypes.RESEND_VERIFICATION_SUCCESS:
      return Object.assign({}, state, {
        verify: action.payload,
        actionType: actionTypes.RESEND_VERIFICATION_SUCCESS,
        isLoading: false
      });

    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.FORGOT_PASSWORD_SUCCESS
      });

    case actionTypes.RENEW_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.RENEW_PASSWORD_SUCCESS
      });

    case actionTypes.FORGOT_PASSWORD_VALIDATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        tokenIsValid: action.payload.tokenIsValid,
        actionType: actionTypes.FORGOT_PASSWORD_VALIDATE_SUCCESS
      });


    case actionTypes.CEP_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepAccountId: action.id,
        actionType: actionTypes.CEP_PROFILE_SUCCESS
      });

    case actionTypes.UPDATE_BANK_PROOF_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bankProofActionType: actionTypes.UPDATE_BANK_PROOF_SUCCESS
      });   

    case actionTypes.CREATE_BANK_DETAILS_FAILED:
    case actionTypes.UPDATE_BANK_DETAILS_FAILED:
    case actionTypes.GET_BANK_DETAILS_FAILED:
        return Object.assign({}, state, {
          isLoading: false,
          error:action.error,
          bankDetails: {},
        });
      
    case actionTypes.UPDATE_BANK_PROOF_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        bankProofActionType: actionTypes.UPDATE_BANK_PROOF_FAILED,
        error: action.error,
      });
       
    case actionTypes.GET_BANK_FAILED:
      return Object.assign({},state,{
        isLoading: false,
        error:action.error,
        bank: [],
      });    

    case actionTypes.CLEAR_BANK_DETAILS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        error:action.error,
        bankDetails: {},
      });

    case actionTypes.LOGOUT_FAILED:
    case actionTypes.LOGIN_FAILED:
    case actionTypes.REGISTER_FAILED:
    case actionTypes.GET_AUTHENTICATION_FAILED:
    case actionTypes.GET_LOCATION_FAILED:
    case actionTypes.UPDATE_PROFILE_FAILED:
    case actionTypes.UPDATE_IDENTIFICATION_FAILED:
    case actionTypes.GOOGLE_LOGIN_FAILED:
    case actionTypes.FACEBOOK_LOGIN_FAILED:
    case actionTypes.GET_PROFILE_FAILED:
    case actionTypes.CREATE_BANK_FAILED:
    case actionTypes.UPDATE_BANK_FAILED:
    case actionTypes.RESEND_VERIFICATION_FAILED:
    case actionTypes.VERIFY_FAILED:
    case actionTypes.RENEW_PASSWORD_FAILED:
    case actionTypes.FORGOT_PASSWORD_FAILED:
    case actionTypes.FORGOT_PASSWORD_VALIDATE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error
      });

    default:
      return state;
  }
};
