import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import { getProfileService } from "./service";
import processAction from "../../utils/processAction";

function* getProfile({ id }) {
  const actions = Object.values({ id }).map(val => val);

  yield processAction({
    params: [...actions],
    service: getProfileService,
    success: actionTypes.GET_PROFILE_SUCCESS,
    failed: actionTypes.GET_PROFILE_FAILED
  });
}

function* watchProfileRequests() {
  yield all([takeLatest(actionTypes.GET_PROFILE, getProfile)]);
}

export default [watchProfileRequests()];
