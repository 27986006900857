
import tracking from './modules/tracking/reducer';
import finance from './modules/finance/reducer';
import kiosk from './modules/kiosk/reducer';
import surcharge from './modules/surcharge/reducer';
import codClients from './modules/cod-clients/reducer';

const admin = {
    tracking,
    finance,
    kiosk,
    surcharge,
    codClients,
}

export default admin