import request from "../../utils/api";

export const getProvincesDropDown = () => request(`locations/provinces/serviceable`);
export const getPickUpcityDropDown = (provinceId) => request(`locations/provinces/${provinceId}/cities/serviceable?coverageType=SA-C`);
export const getDestinationcityDropDown = (provinceId) => request(`locations/provinces/${provinceId}/cities/serviceable`);
export const getPouchBoxCalculatedValue = (body) => request(`bookings/rates/packaging`, { method: "POST", body });
export const getBarangaysUnfiltered = (id) => request(`locations/cities/${id}/barangays`);
export const pouchBoxBooking = (body) => request(`bookings`, { method: "POST", body });
export const pouchBoxPackage = (body) => request(`bookings/packaging`, { method: "POST", body });
export const pouchBoxFinalize = (body) => request(`bookings/status-update`, { method: "POST", body });
