import { all, takeLatest, select, call, put } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getUserByRole as getUserByRoleService,
  getUserByEmail as getUserByEmailService,
  updateUserRole as updateUserRoleService,
} from "./service";
import processAction from "../../../../utils/processAction";
import { message } from "antd";

function* getUserByRole() {
  yield processAction({
    service: getUserByRoleService,
    success: actionTypes.GET_USER_BY_ROLE_SUCCESS,
    failed: actionTypes.GET_USER_BY_ROLE_FAILED
  });
}

function* updateUserRole({ body }) {
  try {
    const data = yield call(updateUserRoleService, body);
    if ( data.op === 'remove' ) {
      yield put({ type: actionTypes.RESET_ALL });
      yield put({ type: actionTypes.GET_USER_BY_ROLE_REQUEST });
      message.success('Removed Successfully')
    } else {
      yield put({ type: actionTypes.UPDATE_USER_ROLE_SUCCESS, payload: data });
    }
  } catch (error) {

    yield put({ type: actionTypes.RESET_ALL });
    yield put({ type: actionTypes.GET_USER_BY_ROLE_REQUEST });
    yield put({ type: actionTypes.UPDATE_USER_ROLE_FAILED, error });
  }
}


function* getUserByEmail({ body }) {
  try {
    const state = yield select();
    const { codClients } = state;
    const { usersByEmail } = codClients;

    const data = yield call(getUserByEmailService, body);
    const userExist = usersByEmail.filter(users => users.userId === data.users[0].userId);
    let concatData = usersByEmail;
    if ( userExist.length === 0 ) {
      concatData = usersByEmail.concat(data.users);
    }
    yield put({ type: actionTypes.GET_USER_BY_EMAIL_SUCCESS, payload: concatData });
  } catch (error) {
    yield put({ type: actionTypes.GET_USER_BY_EMAIL_FAILED, error });
  }
}


function* watchCodClientsRequest() {
  yield all([
    takeLatest(actionTypes.GET_USER_BY_ROLE_REQUEST, getUserByRole),
    takeLatest(actionTypes.UPDATE_USER_ROLE_REQUEST, updateUserRole),
    takeLatest(actionTypes.GET_USER_BY_EMAIL_REQUEST, getUserByEmail),
  ]);
}

export default [watchCodClientsRequest()];
