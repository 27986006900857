import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getProvincesDropDown as getProvincesDropDownService,
  getPickUpcityDropDown as getPickUpcityDropDownService,
  getDestinationcityDropDown as getDestinationcityDropDownService,
  getCalculatedValue as getCalculatedValueService,
  getEstimatedDate as getEstimatedDateService
} from "./service";
import processAction from "../../utils/processAction";

function* getProvincesDropDown() {
  yield processAction({
    service: getProvincesDropDownService,
    success: actionTypes.GET_PROVINCE_DROPDOWNS_SUCCESS,
    failed: actionTypes.GET_PROVINCE_DROPDOWNS_FAILED,
  });

}
function* getPickUpCityDropDown({provinceId}) {
  yield processAction({
    params: provinceId,
    service: getPickUpcityDropDownService,
    success: actionTypes.GET_CITY_DROPDOWNS_PICKUP_SUCCESS,
    failed: actionTypes.GET_CITY_DROPDOWNS_PICKUP_FAILED,
  });
  
}
function* getDestinationcityDropDown({provinceId}) {
  yield processAction({
    params: provinceId,
    service: getDestinationcityDropDownService,
    success: actionTypes.GET_CITY_DROPDOWNS_DESTINATION_SUCCESS,
    failed: actionTypes.GET_CITY_DROPDOWNS_DESTINATION_FAILED,
  });
  
}
function* getCalculatedValue({body}) {
  yield processAction({
    params: body,
    service: getCalculatedValueService,
    success: actionTypes.GET_CALCULATOR_SUCCESS,
    failed: actionTypes.GET_CALCULATOR_FAILED,
  });
}

function* getEstimatedDate({body}) {
  yield processAction({
    params: body,
    service: getEstimatedDateService,
    success: actionTypes.GET_ESTIMATE_DATES_SUCCESS,
    failed: actionTypes.GET_ESTIMATE_DATES_FAILED,
  });
}

function* watchHomeRequests() {
  yield all([
    takeLatest(actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST, getPickUpCityDropDown),
    takeLatest(actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST, getProvincesDropDown),
    takeLatest(actionTypes.GET_CITY_DROPDOWNS_DESTINATION_REQUEST, getDestinationcityDropDown),
    takeLatest(actionTypes.GET_CALCULATOR_REQUEST, getCalculatedValue),
    takeLatest(actionTypes.GET_ESTIMATE_DATES_REQUEST, getEstimatedDate),
  ]);
}

export default [watchHomeRequests()];
