import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
    getUserCredits as getUserCreditsService,
    consumeCredits as consumeCreditsService,
    claimPromoCode as claimPromoCodeService,
    addCredits as addCreditsService,
} from "./service";
import processAction from "../../utils/processAction";


function* getUserCredits({ id }) {
    yield processAction({
        params: id,
        service: getUserCreditsService,
        success: actionTypes.GET_USER_CREDITS_SUCCESS,
        failed: actionTypes.GET_USER_CREDITS_FAILED
    });
}

function* addCredits({ body }) {
    yield processAction({
        params: body,
        service: addCreditsService,
        success: actionTypes.ADD_CREDITS_SUCCESS,
        failed: actionTypes.ADD_CREDITS_FAILED
    });
}

function* consumeCredits({ body }) {
    yield processAction({
        params: body,
        service: consumeCreditsService,
        success: actionTypes.CONSUME_CREDITS_SUCCESS,
        failed: actionTypes.CONSUME_CREDITS_FAILED
    });
}

function* claimPromoCode({ body }) {
    yield processAction({
        params: body,
        service: claimPromoCodeService,
        success: actionTypes.CLAIM_PROMO_SUCCESS,
        failed: actionTypes.CLAIM_PROMO_FAILED
    });
}


function* watchCreditsRequest() {
    yield all([
        takeLatest(actionTypes.CONSUME_CREDITS, consumeCredits),
        takeLatest(actionTypes.ADD_CREDITS, addCredits),
        takeLatest(actionTypes.CLAIM_PROMO, claimPromoCode),
        takeLatest(actionTypes.GET_USER_CREDITS, getUserCredits),
    ]);
}

export default [watchCreditsRequest()];
