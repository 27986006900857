export const actionTypes = {
  GET_PROVINCE_DROPDOWNS_REQUEST: "GET_PROVINCE_REQUEST",
  GET_PROVINCE_DROPDOWNS_SUCCESS: "GET_PROVINCE_SUCCESS",
  GET_PROVINCE_DROPDOWNS_FAILED: "GET_PROVINCE_FAILED",

  GET_CITY_DROPDOWNS_PICKUP_REQUEST: "GET_CITY_DROPDOWNS_PICKUP_REQUEST",
  GET_CITY_DROPDOWNS_PICKUP_SUCCESS: "GET_CITY_DROPDOWNS_PICKUP_SUCCESS",
  GET_CITY_DROPDOWNS_PICKUP_FAILED: "GET_CITY_DROPDOWNS_PICKUP_FAILED",

  GET_CALCULATOR_POUCH_BOX__REQUEST: "GET_CALCULATOR_POUCH_BOX__REQUEST",
  GET_CALCULATOR_POUCH_BOX__SUCCESS: "GET_CALCULATOR_POUCH_BOX__SUCCESS",
  GET_CALCULATOR_POUCH_BOX__FAILED: "GET_CALCULATOR_POUCH_BOX__FAILED",

  GET_POUCH_BOX_BOOKING__REQUEST: "GET_POUCH_BOX_BOOKING__REQUEST",
  GET_POUCH_BOX_BOOKING__SUCCESS: "GET_POUCH_BOX_BOOKING__SUCCESS",
  GET_POUCH_BOX_BOOKING__FAILED: "GET_POUCH_BOX_BOOKING__FAILED",

  GET_POUCH_BOX_PACKAGE__REQUEST: "GET_POUCH_BOX__PACKAGE__REQUEST",
  GET_POUCH_BOX_PACKAGE__SUCCESS: "GET_POUCH_BOX__PACKAGE__SUCCESS",
  GET_POUCH_BOX_PACKAGE__FAILED: "GET_POUCH_BOX__PACKAGE__FAILED",

  GET_POUCH_BOX_FINALIZE__REQUEST: "GET_POUCH_BOX__FINALIZE__REQUEST",
  GET_POUCH_BOX_FINALIZE__SUCCESS: "GET_POUCH_BOX_FINALIZE__SUCCESS",
  GET_POUCH_BOX_FINALIZE__FAILED: "GET_POUCH_BOX_FINALIZE__FAILED",

  RESET_POUCH_STATE: "RESET_POUCH_STATE",
  RESET_POUCH_ACTION_TYPE: "RESET_STATE_ACTION_TYPE",

  GET_POUCH_BOX_BARANGAYS_UNFILTERED_REQUEST: "GET_POUCH_BOX_BARANGAYS_UNFILTERED_REQUEST",
  GET_POUCH_BOX_BARANGAYS_UNFILTERED_SUCCESS: "GET_POUCH_BOX_BARANGAYS_UNFILTERED_SUCCESS",
  GET_POUCH_BOX_BARANGAYS_UNFILTERED_FAILED: "GET_POUCH_BOX_BARANGAYS_UNFILTERED_FAILED",
};

export const actionCreators = {

  getProvinceDropDown: () => ({
    type: actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST,
  }),

  getPickUpCityDropDown: (provinceId) => {
    return ({
      type: actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST,
      provinceId,
    })
  },
  getBarangaysUnfiltered: (id) => ({
    type: actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_REQUEST,
    id,
  }),
  getCityDropDownDestination: (provinceId) => ({
    type: actionTypes.GET_CITY_DROPDOWNS_DESTINATION_REQUEST, provinceId
  }),
  getPouchBoxCalculatedValue: (body) => ({
    type: actionTypes.GET_CALCULATOR_POUCH_BOX__REQUEST, body
  }),
  pouchBoxCreateBooking: (body) => ({
    type: actionTypes.GET_POUCH_BOX_BOOKING__REQUEST, body
  }),
  pouchBoxFinalize: (body) => ({
    type: actionTypes.GET_POUCH_BOX_FINALIZE__REQUEST, body
  }),
  pouchBoxPackage: (body) => ({
    type: actionTypes.GET_POUCH_BOX_PACKAGE__REQUEST, body
  }),
  pouchBoxResetState: () => {
    return {
      type: actionTypes.RESET_POUCH_STATE,
      payload: "",
    }
  },
  resetActionType: () => ({
    type: actionTypes.RESET_ACTION_TYPE
  }),
};

const initialState = {
  actionType: "",
  isLoading: false,
  cityForPickUpDropDown: {},
  cityForDestinationDropDown: {},
  provincesDropDown: [],
  calculatedPouchBoxInfo: [],
  error: null,
  barangays: {},
  booking: {},
  package: {},
  finalize: {}
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        actionType: action.type,
        calculatedPouchBoxInfo: []
      });
    case actionTypes.GET_PROVINCE_DROPDOWNS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        provincesDropDown: action.payload,
      });
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,

      });
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        cityForPickUpDropDown: action.payload,
      });
    case actionTypes.RESET_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: "",
      });

    case actionTypes.GET_CITY_DROPDOWNS_DESTINATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_CITY_DROPDOWNS_DESTINATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cityForDestinationDropDown: action.payload,
      });

    case actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_SUCCESS,
        barangays: action.payload,
      });

    case actionTypes.GET_CALCULATOR_POUCH_BOX__REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_POUCH_BOX_BOOKING__REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.GET_POUCH_BOX_PACKAGE__REQUEST:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_POUCH_BOX_PACKAGE__REQUEST,
        isLoading: true,
      });
    case actionTypes.GET_POUCH_BOX_FINALIZE__REQUEST:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_POUCH_BOX_FINALIZE__REQUEST,
        isLoading: true,
      });
    case actionTypes.GET_CALCULATOR_POUCH_BOX__SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_CALCULATOR_POUCH_BOX__SUCCESS,
        calculatedPouchBoxInfo: action.payload,
      });
    case actionTypes.GET_POUCH_BOX_PACKAGE__SUCCESS:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_POUCH_BOX_PACKAGE__SUCCESS,
        package: action.payload,
      });
    case actionTypes.GET_POUCH_BOX_FINALIZE__SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        finalize: action.payload,
      });
    case actionTypes.GET_POUCH_BOX_BOOKING__SUCCESS:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_POUCH_BOX_BOOKING__SUCCESS,
        booking: action.payload,
      });
    case actionTypes.GET_PROVINCE_DROPDOWNS_FAILED:
    case actionTypes.GET_CITY_DROPDOWNS_PICKUP_FAILED:
    case actionTypes.GET_CALCULATOR_POUCH_BOX__FAILED:
    case actionTypes.GET_POUCH_BOX_BOOKING__FAILED:
    case actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_FAILED:
    case actionTypes.GET_POUCH_BOX_PACKAGE__FAILED:
    case actionTypes.GET_POUCH_BOX_FINALIZE__FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_POUCH_BOX_PACKAGE__FAILED,
        error: action.error && action.error.message,
      });

    case actionTypes.RESET_POUCH_STATE:
      return Object.assign({}, state, {
        actionType: "RESET",
        isLoading: false,
        cityForPickUpDropDown: {},
        cityForDestinationDropDown: {},
        provincesDropDown: [],
        calculatedInfo: [],
        calculatedPouchBoxInfo: [],
        error: null,
        barangays: {},
        booking: {},
        package: {},
        finalize: {}
      });
    default:
      return state;
  }
};
