import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as profileActionCreators } from "../../../auth/reducer";

import Page from "./Page";

const mapStateToProps = state => ({
  profile: state.auth.profile,
  isLoading: state.auth.isLoading,
  bank: state.auth.bank,
  bankDetails: state.auth.bankDetails,
  actionType: state.auth.actionType,
  bankProofActionType: state.auth.bankProofActionType,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(profileActionCreators, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
