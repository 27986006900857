import request from "../../utils/api";

export const login = body =>
  request(`users/auth/login`, { method: "POST", body });

export const logout = () => {
  const url = "https://jsonplaceholder.typicode.com/posts/";
  return fetch(url, {
    method: "POST",
    body: {
      hello: "world"
    }
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => err);
};

export const getProfile = id => request(`users/account/get/${id}`);

export const getBank = () => request(`users/bank`);

export const getBankDetails = id => request(`users/account/${id}/bank`);

export const clearBankDetails = id => request(`users/account/${id}/bank`, { method: "DELETE" });

export const createBankDetails = body => request(`users/account/bank`, { method: "POST", body });

export const updateBankDetails = body => request(`users/account/bank`, { method: "PATCH", body });

export const updateProfile = body =>
  request(`users/account/update`, { method: "PUT", body });

// export const updateIdentification = (id, body) =>
//   request(`users/account/${id}/identification/upload`, { method: "PATCH", body });

export const register = body =>
  request(`users/account/signup`, { method: "POST", body });

export const googleLogin = body =>
  request("users/auth/google", { method: "POST", body });

export const facebookLogin = body =>
  request("users/auth/social", { method: "POST", body });

export const resendVerification = body =>
  request(`users/verification/resend/${body.id}`, { method: "POST" });

export const verify = body =>
  request(`users/verification?token=${body.token}`);

export const forgotPassword = body =>
  request(`users/account/reset`, { method: "POST", body });

export const renewPassword = body =>
  request(`users/account/password`, { method: "PUT", body });

export const forgotPasswordValidateToken = body =>
  request(`users/account/reset?token=${body.token}`)

export const getCepProfile = (id) =>
  request(`pbookings/accounts/personnel/verification/${id}`);

export const uploadBankProof = (body) =>
  request(`users/account/${body.id}/bank/upload`, { method: "POST", body: body.formData });