export const actionTypes = {
    GET_TEST_REQUEST: "GET_TEST_REQUEST",
    GET_TEST_SUCCESS: "GET_TEST_SUCCESS",
    GET_TEST_FAILED: "GET_TEST_FAILED",
};

export const actionCreators = {
    getTest: (body) => ({ type: actionTypes.GET_TEST_REQUEST, body }),
};

const initialState = {
    actionType: '',
    isLoading: false,
    error: null,
    test: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TEST_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });

        case actionTypes.GET_TEST_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                test: action.payload
            });
        case actionTypes.GET_TEST_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error && action.error.message
            });

        default:
            return state;
    }
};
