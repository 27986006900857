export const actionTypes = {
  GET_SERVICEABLE_PROVINCES: "GET_SERVICEABLE_PROVINCES",
  GET_SERVICEABLE_PROVINCES_SUCCESS: "GET_SERVICEABLE_PROVINCES_SUCCESS",
  GET_SERVICEABLE_PROVINCES_FAILED: "GET_SERVICEABLE_PROVINCES_FAILED",

  GET_SENDER_SERVICEABLE_PROVINCES: "GET_SENDER_SERVICEABLE_PROVINCES",
  GET_SENDER_SERVICEABLE_PROVINCES_SUCCESS: "GET_SENDER_SERVICEABLE_PROVINCES_SUCCESS",
  GET_SENDER_SERVICEABLE_PROVINCES_FAILED: "GET_SENDER_SERVICEABLE_PROVINCES_FAILED",

  GET_SERVICEABLE_MUNICIPALITIES: "GET_SERVICEABLE_MUNICIPALITIES",
  GET_SERVICEABLE_MUNICIPALITIES_SUCCESS:
    "GET_SERVICEABLE_MUNICIPALITIES_SUCCESS",
  GET_SERVICEABLE_MUNICIPALITIES_FAILED:
    "GET_SERVICEABLE_MUNICIPALITIES_FAILED",

  GET_SERVICEABLE_SAR: "GET_SERVICEABLE_SAR",
  GET_SERVICEABLE_SAR_SUCCESS: "GET_SERVICEABLE_SAR_SUCCESS",
  GET_SERVICEABLE_SAR_FAILED: "GET_SERVICEABLE_SAR_FAILED",

  GET_ALL_SERVICEABLE_SAR: "GET_ALL_SERVICEABLE_SAR",
  GET_ALL_SERVICEABLE_SAR_SUCCESS: "GET_ALL_SERVICEABLE_SAR_SUCCESS",
  GET_ALL_SERVICEABLE_SAR_FAILED: "GET_ALL_SERVICEABLE_SAR_FAILED",

  GET_ALL_SENDER_SERVICEABLE_SAR: "GET_ALL_SENDER_SERVICEABLE_SAR",
  GET_ALL_SENDER_SERVICEABLE_SAR_SUCCESS: "GET_ALL_SENDER_SERVICEABLE_SAR_SUCCESS",
  GET_ALL_SENDER_SERVICEABLE_SAR_FAILED: "GET_ALL_SENDER_SERVICEABLE_SAR_FAILED",

  GET_PROVINCES_REQUEST: "GET_PROVINCES_REQUEST",
  GET_PROVINCES_SUCCESS: "GET_PROVINCES_SUCCESS",
  GET_PROVINCES_FAILED: "GET_PROVINCES_FAILED",

  GET_PROFILE_ADDRESS_REQUEST: "GET_PROFILE_ADDRESS_REQUEST",
  GET_PROFILE_ADDRESS_SUCCESS: "GET_PROFILE_ADDRESS_SUCCESS",
  GET_PROFILE_ADDRESS_FAILED: "GET_PROFILE_ADDRESS_FAILED",

  GET_PROFILE_BARANGAY_REQUEST: "GET_PROFILE_BARANGAY_REQUEST",
  GET_PROFILE_BARANGAY_SUCCESS: "GET_PROFILE_BARANGAY_SUCCESS",
  GET_PROFILE_BARANGAY_FAILED: "GET_PROFILE_BARANGAY_FAILED",

  GET_MUNICIPALITIES_REQUEST: "GET_MUNICIPALITIES_REQUEST",
  GET_MUNICIPALITIES_SUCCESS: "GET_MUNICIPALITIES_SUCCESS",
  GET_MUNICIPALITIES_FAILED: "GET_MUNICIPALITIES_FAILED",

  GET_MUNICIPALITIES_SAC_REQUEST: "GET_MUNICIPALITIES_SAC_REQUEST",
  GET_MUNICIPALITIES_SAC_SUCCESS: "GET_MUNICIPALITIES_SAC_SUCCESS",
  GET_MUNICIPALITIES_SAC_FAILED: "GET_MUNICIPALITIES_SAC_FAILED",

  GET_BARANGAYS_REQUEST: "GET_BARANGAYS_REQUEST",
  GET_BARANGAYS_SUCCESS: "GET_BARANGAYS_SUCCESS",
  GET_BARANGAYS_FAILED: "GET_BARANGAYS_FAILED",

  GET_SENDER_BARANGAYS_REQUEST: "GET_SENDER_BARANGAYS_REQUEST",
  GET_SENDER_BARANGAYS_SUCCESS: "GET_SENDER_BARANGAYS_SUCCESS",
  GET_SENDER_BARANGAYS_FAILED: "GET_SENDER_BARANGAYS_FAILED",

  GET_BARANGAY_ID_REQUEST: "GET_BARANGAY_ID_REQUEST",
  GET_BARANGAY_ID_SUCCESS: "GET_BARANGAY_ID_SUCCESS",
  GET_BARANGAY_ID_FAILED: "GET_BARANGAY_ID_FAILED",

  GET_SHIPPING_FEE: "GET_SHIPPING_FEE",
  GET_SHIPPING_FEE_SUCCESS: "GET_SHIPPING_FEE_SUCCESS",
  GET_SHIPPING_FEE_FAILED: "GET_SHIPPING_FEE_FAILED",

  GET_BULK_SHIPPING_FEE: "GET_BULK_SHIPPING_FEE",
  GET_BULK_SHIPPING_FEE_SUCCESS: "GET_BULK_SHIPPING_FEE_SUCCESS",
  GET_BULK_SHIPPING_FEE_FAILED: "GET_BULK_SHIPPING_FEE_FAILED",

  GET_PH_HOLIDAYS_LIST: "GET_PH_HOLIDAYS_LIST",
  GET_PH_HOLIDAYS_LIST_SUCCESS: "GET_PH_HOLIDAYS_LIST_SUCCESS",
  GET_PH_HOLIDAYS_LIST_FAILED: "GET_PH_HOLIDAYS_LIST_FAILED",

  RESET_SHIPPING_FEE: "RESET_SHIPPING_FEE",
  RESET_BULK_SHIPPING_FEE: "RESET_BULK_SHIPPING_FEE",
  RESET_ADDRESS: "RESET_ADDRESS",
  RESET_ADDRESS_ACTION_TYPE: "RESET_ADDRESS_ACTION_TYPE",

  ASSIGN_COD_CLIENT_TO_USERS_REQUEST: "ASSIGN_COD_CLIENT_TO_USERS_REQUEST",
  ASSIGN_COD_CLIENT_TO_USERS_SUCCESS: "FASSIGN_COD_CLIENT_TO_USERS_SUCCESS",
  ASSIGN_COD_CLIENT_TO_USERS_FAILED: "ASSIGN_COD_CLIENT_TO_USERS_FAILED",

  GET_USER_DETAILS_REQUEST: "GET_USER_DETAILS_REQUEST",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILED: "GET_USER_DETAILS_FAILED",

  UPDATE_USER_DETAILS_REQUEST: "UPDATE_USER_DETAILS_REQUEST",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILED: "UPDATE_USER_DETAILS_FAILED",



  RESET_ERROR: "RESET_ERROR"
};

export const actionCreators = {
  getProvinces: () => ({ type: actionTypes.GET_PROVINCES_REQUEST }),
  resetErrorMsg: () => ({ type: actionTypes.RESET_ERROR }),
  getServiceableProvinces: () => ({
    type: actionTypes.GET_SERVICEABLE_PROVINCES,
  }),
  getSenderServiceableProvinces: () => ({
    type: actionTypes.GET_SENDER_SERVICEABLE_PROVINCES,
  }),
  getServiceableMunicipalities: (id) => ({
    type: actionTypes.GET_SERVICEABLE_MUNICIPALITIES,
    id,
  }),
  getServiceableSAR: (id) => ({
    type: actionTypes.GET_SERVICEABLE_SAR,
    id,
  }),
  getAllServiceableSAR: (id, city) => ({
    type: actionTypes.GET_ALL_SERVICEABLE_SAR,
    id,
    city,
  }),
  getAllSenderServiceableSAR: (id, city) => ({
    type: actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR,
    id,
    city,
  }),
  getMunicipalities: (id, city) => ({
    type: actionTypes.GET_MUNICIPALITIES_REQUEST,
    id,
    city,
  }),
  getMunicipalitiesSAC: (id, city) => ({
    type: actionTypes.GET_MUNICIPALITIES_SAC_REQUEST,
    id,
    city,
  }),
  getBarangays: (id) => ({
    type: actionTypes.GET_BARANGAYS_REQUEST,
    id,
  }),
  getSenderBarangays: (id) => ({
    type: actionTypes.GET_SENDER_BARANGAYS_REQUEST,
    id,
  }),
  getBarangayById: (id) => ({
    type: actionTypes.GET_BARANGAY_ID_REQUEST,
    id,
  }),
  getProfileAddress: (provinceId, cityId, barangayId) => ({
    type: actionTypes.GET_PROFILE_ADDRESS_REQUEST,
    provinceId,
    cityId,
    barangayId,
  }),
  getProfileBarangay: (id) => ({
    type: actionTypes.GET_PROFILE_BARANGAY_REQUEST,
    id,
  }),
  getShippingFee: (body) => ({ type: actionTypes.GET_SHIPPING_FEE, body }),
  getBulkShippingFee: (body) => ({ type: actionTypes.GET_BULK_SHIPPING_FEE, body }),
  getPHholidaysList: () => ({ type: actionTypes.GET_PH_HOLIDAYS_LIST }),
  resetShippingFee: () => ({ type: actionTypes.RESET_SHIPPING_FEE }),
  resetBulkShippingFee: () => ({ type: actionTypes.RESET_BULK_SHIPPING_FEE }),
  resetAddress: () => ({ type: actionTypes.RESET_ADDRESS }),
  resetAddressActionType: () => ({
    type: actionTypes.RESET_ADDRESS_ACTION_TYPE,
  }),

  assignCodClientToUsers: (body) => ({ type: actionTypes.ASSIGN_COD_CLIENT_TO_USERS_REQUEST, body }),

  getUserDetails: (id) => ({
    type: actionTypes.GET_USER_DETAILS_REQUEST,
    id,
  }),
  updateUserAddress: (body) => ({ type: actionTypes.UPDATE_USER_DETAILS_REQUEST, body })
};

const initialState = {
  actionType: "",
  isLoading: false,
  shippingFeeLoading: false,
  provinces: [],
  cities: [],
  sarCities: {},
  barangays: {},
  barangay: {},
  senderProvinces: [],
  senderCities: {},
  senderBarangays: {},
  profileAddress: {},
  profileBarangay: {},
  shippingFee: 0,
  userDetails: {},
  PhHolidaysList: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SHIPPING_FEE:
      return Object.assign({}, state, {
        shippingFeeLoading: true,
      });
    case actionTypes.GET_BULK_SHIPPING_FEE:
      return Object.assign({}, state, {
        shippingFeeLoading: true,
      });
    case actionTypes.RESET_ERROR:
      return Object.assign({}, state, {
        error: null
      });

    case actionTypes.GET_PROVINCES_REQUEST:
    case actionTypes.GET_SERVICEABLE_PROVINCES:
    case actionTypes.GET_SENDER_SERVICEABLE_PROVINCES:
    case actionTypes.GET_SERVICEABLE_SAR:
    case actionTypes.GET_ALL_SERVICEABLE_SAR:
    case actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR:
    case actionTypes.GET_MUNICIPALITIES_REQUEST:
    case actionTypes.GET_BARANGAYS_REQUEST:
    case actionTypes.GET_SENDER_BARANGAYS_REQUEST:
    case actionTypes.GET_BARANGAY_ID_REQUEST:
    case actionTypes.GET_PROFILE_ADDRESS_REQUEST:
    case actionTypes.GET_PROFILE_BARANGAY_REQUEST:
    case actionTypes.ASSIGN_COD_CLIENT_TO_USERS_REQUEST:
    case actionTypes.GET_MUNICIPALITIES_SAC_REQUEST:
    case actionTypes.GET_PH_HOLIDAYS_LIST:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case actionTypes.GET_PROVINCES_SUCCESS:
    case actionTypes.GET_SERVICEABLE_PROVINCES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        provinces: action.payload,
        actionType: action.type,
      });
    case actionTypes.GET_SENDER_SERVICEABLE_PROVINCES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        senderProvinces: action.payload,
        actionType: action.type,
      });
    case actionTypes.GET_MUNICIPALITIES_SUCCESS:
    case actionTypes.GET_SERVICEABLE_MUNICIPALITIES_SUCCESS:
    case actionTypes.GET_MUNICIPALITIES_SAC_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        cities: action.payload,
      });

    case actionTypes.GET_SERVICEABLE_SAR_SUCCESS:
    case actionTypes.GET_ALL_SERVICEABLE_SAR_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        sarCities: action.payload,
      });
    case actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        senderCities: action.payload,
      });
    case actionTypes.GET_BARANGAYS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_BARANGAYS_SUCCESS,
        barangays: action.payload,
      });
    case actionTypes.GET_SENDER_BARANGAYS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_SENDER_BARANGAYS_SUCCESS,
        senderBarangays: action.payload,
      });
    case actionTypes.GET_BARANGAY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        barangay: action.payload,
      });

    case actionTypes.GET_PROFILE_ADDRESS_SUCCESS:
      const { payload } = action;
      payload.city = payload.cities.find(
        (x) => x.id === payload.secondParams
      ).name;
      return Object.assign({}, state, {
        isLoading: false,
        profileAddress: action.payload,
      });

    case actionTypes.GET_PROFILE_BARANGAY_SUCCESS:
      const { profileAddress } = state;
      return Object.assign({}, state, {
        isLoading: false,
        profileAddress: {
          ...profileAddress,
          barangay: action.payload.name,
        },
      });

    case actionTypes.GET_SHIPPING_FEE_SUCCESS:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_SHIPPING_FEE_SUCCESS,
        shippingFeeLoading: false,
        shippingFee: action.payload,
        error: null
      });

    case actionTypes.GET_BULK_SHIPPING_FEE_SUCCESS:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_BULK_SHIPPING_FEE_SUCCESS,
        shippingFeeLoading: false,
        shippingFee: action.payload,
        error: null
      });

    case actionTypes.GET_PH_HOLIDAYS_LIST_SUCCESS:
      return Object.assign({}, state, {
        actionType: action.type,
        isLoading: false,
        PhHolidaysList: action.payload
      });

    case actionTypes.RESET_SHIPPING_FEE:
      return Object.assign({}, state, {
        shippingFee: 0.0,
      });

    case actionTypes.RESET_BULK_SHIPPING_FEE:
      return Object.assign({}, state, {
        shippingFee: 0.0,
      });

    case actionTypes.RESET_ADDRESS:
    case actionTypes.GET_MUNICIPALITIES_SAC_FAILED:
      return Object.assign({}, state, {
        barangays: {},
        cities: {},
      });

    case actionTypes.RESET_ADDRESS_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: "",
      });


    case actionTypes.GET_SERVICEABLE_SAR_FAILED:
    case actionTypes.GET_ALL_SERVICEABLE_SAR_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        sarCities: [],
        error: action.error && action.error.message,
      });

    case actionTypes.ASSIGN_COD_CLIENT_TO_USERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case actionTypes.GET_PROVINCES_FAILED:
    case actionTypes.GET_SERVICEABLE_PROVINCES_FAILED:
    case actionTypes.GET_SERVICEABLE_MUNICIPALITIES_FAILED:
    case actionTypes.GET_MUNICIPALITIES_FAILED:
    case actionTypes.GET_BARANGAYS_FAILED:
    case actionTypes.GET_BARANGAY_ID_FAILED:
    case actionTypes.GET_PROFILE_ADDRESS_FAILED:
    case actionTypes.GET_PROFILE_BARANGAY_FAILED:
    case actionTypes.ASSIGN_COD_CLIENT_TO_USERS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_SENDER_SERVICEABLE_PROVINCES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        senderProvinces: [],
        error: action.error && action.error.message,
      });

    case actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        senderCities: {},
        error: action.error && action.error.message,
      });

    case actionTypes.GET_SENDER_BARANGAYS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        senderBarangays: {},
        error: action.error && action.error.message,
      });


    case actionTypes.GET_USER_DETAILS_REQUEST:
    case actionTypes.UPDATE_USER_DETAILS_REQUEST:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        actionType: action.type,
        isLoading: false,
        userDetails: action.payload
      });

    case actionTypes.UPDATE_USER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        actionType: action.type,
        isLoading: false,
        userDetails: action.payload
      });

    case actionTypes.GET_USER_DETAILS_FAILED:
    case actionTypes.UPDATE_USER_DETAILS_FAILED:
      return Object.assign({}, state, {
        actionType: action.type,
        isLoading: false,
      });

    case actionTypes.GET_PH_HOLIDAYS_LIST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        PhHolidaysList: [],
        error: action.error.message,
        actionType: actionTypes.GET_PH_HOLIDAYS_LIST_FAILED
      });

    case actionTypes.GET_SHIPPING_FEE_FAILED:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_SHIPPING_FEE_FAILED,
        shippingFeeLoading: false,
        shippingFee: 0,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_BULK_SHIPPING_FEE_FAILED:
      return Object.assign({}, state, {
        actionType: actionTypes.GET_BULK_SHIPPING_FEE_FAILED,
        shippingFeeLoading: false,
        shippingFee: 0,
        error: action.error && action.error.message,
      });

    default:
      return state;
  }
};
