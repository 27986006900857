import { message } from 'antd';

export const actionTypes = {
    GET_SURCHARGE_REQUEST: "GET_SURCHARGE_REQUEST",
    GET_SURCHARGE_SUCCESS: "GET_SURCHARGE_SUCCESS",
    GET_SURCHARGE_FAILED: "GET_SURCHARGE_FAILED",

    UPDATE_SURCHARGE_REQUEST: "UPDATE_SURCHARGE_REQUEST",
    UPDATE_SURCHARGE_SUCCESS: "UPDATE_SURCHARGE_SUCCESS",
    UPDATE_SURCHARGE_FAILED: "UPDATE_SURCHARGE_FAILED",

    RESET_ACTION_TYPE: "RESET_ACTION_TYPE",
};

export const actionCreators = {
    getSurcharge: () => ({ type: actionTypes.GET_SURCHARGE_REQUEST }),
    updateSurcharge: (body) => ({ type: actionTypes.UPDATE_SURCHARGE_REQUEST, body }),
    resetActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
};

const initialState = {
    actionType: '',
    isLoading: false,
    error: null,
    surcharge: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SURCHARGE_REQUEST:
        case actionTypes.GET_SURCHARGE_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });

        case actionTypes.GET_SURCHARGE_SUCCESS:
            return Object.assign({}, state, {
                surcharge: action.payload,
                isLoading: false,
            });
        case actionTypes.UPDATE_SURCHARGE_SUCCESS:
            message.success('Updated Surcharge Fee');
            return Object.assign({}, state, {
                isLoading: false,
                surcharge: action.payload,
                actionType: action.type,
            });
        case actionTypes.UPDATE_SURCHARGE_FAILED:
        case actionTypes.GET_SURCHARGE_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error && action.error.message
            });
        case actionTypes.RESET_ACTION_TYPE:
            return Object.assign({}, state, {
                actionType: '',
            })
        default:
            return state;
    }
};
