export const actionTypes = {
    GET_KIOSK: "GET_KIOSK",
    GET_KIOSK_SUCCESS: "GET_KIOSK_SUCCESS",
    GET_KIOSK_FAILED: "GET_KIOSK_FAILED",

    RESET_ACTION_TYPE: "RESET_ACTION_TYPE",
  };
  
  export const actionCreators = {
    getKioskById: id => ({
      type: actionTypes.GET_KIOSK,
      id
    }),
    resetKioskActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
  };
  
  const initialState = {
    actionType: "",
    kiosk:{},
    errors: null,
    isLoading: false,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      
      case actionTypes.GET_KIOSK:
        return Object.assign({}, state, {
          isLoading: true,
        });
  
      case actionTypes.GET_KIOSK_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          actionType: action.type,
          kiosk: action.payload
        });
      case actionTypes.RESET_ACTION_TYPE:
        return Object.assign({}, state, {
          isLoading: false,
          actionType: '',
        })

      case actionTypes.GET_KIOSK_FAILED:
        return Object.assign({}, state, {
          isLoading: false,
          kiosk: {},
          errors: action.error.message
        });
  
      default:
        return state;
    }
  };
  