import auth from "../modules/auth/routes";
import dashboard from "../modules/dashboard/routes";
import booking from "../modules/booking/routes";
import terms from "../modules/terms/routes";
import restrictedGoods from "../modules/restricted-goods/routes";
import packagingGuidelines from "../modules/packaging-guidelines/routes";
import claimsPolicy from "../modules/claims-policy/routes";
import rtsPolicy from "../modules/rts-policy/routes";
// import faqs from "../modules/faqs/routes"; Commented routes for faqs, pouchbox and shippingFee since this has no use for now but still have future plans for this components.
import rates from "../modules/rates/routes";
import addresses from "../modules/addresses/routes";
import coverage from "../modules/coverage/routes";
import privacy from "../modules/privacy/routes";
import home from "../modules/home/routes";
// import pouchbox from "../modules/pouch-box-request/routes";
import pouchboxviewPackageDetails from "../modules/pouch-box-View-package-details/routes";
// import shippingFee from "../modules/shipping-fee/routes";
import cepdashboard from "../modules/cep-dashboard/routes";
import cepbooking from "../modules/cep-booking/routes";
import gcashPayment from "../modules/g-cash/routes";

export default [
  ...auth,
  ...dashboard,
  ...booking,
  ...terms,
  ...restrictedGoods,
  ...packagingGuidelines,
  ...claimsPolicy,
  ...rtsPolicy,
  // ...faqs,
  ...rates,
  ...addresses,
  ...coverage,
  ...privacy,
  ...home,
  // ...pouchbox,
  ...pouchboxviewPackageDetails,
  // ...shippingFee,
  ...cepdashboard,
  ...cepbooking,
  ...gcashPayment
];
