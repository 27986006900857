



import React from "react";
import { Button, Col, Form, Input, message, Popover, Row, Spin, Select, Upload, Alert } from "antd";
import Modal from '../../../../commons/Modal';
import ContactModal from "../../../../commons/Modal";
import "./styles.css";

const { Option } = Select;

class Page extends React.Component {
  state = {
    disabled:true,
    clearModal: false,
    fileList: [],
    setVisible: false,
    isProofSuccess: false,
    isProofFailed: false,
    bankProofModalOpen: false,
    uploadMessage: "",
  };

  componentDidMount() {
    this.props.getBank();
  }

  componentDidUpdate() {
    const { bankProofActionType, resetBankProofActiontype, actionType, resetAuthActionType } = this.props;
    if(actionType === "UPDATE_BANK_DETAILS_SUCCESS" || actionType === "CREATE_BANK_DETAILS_SUCCESS"){
      this.uploadBankProof();
      resetAuthActionType();
    }
    if (bankProofActionType === "UPDATE_BANK_PROOF_SUCCESS") {
      this.setState({isProofSuccess: true});
      this.setState({isProofFailed: false});
      this.setState({uploadMessage: "File successfully uploaded."});
      this.changeValue();
      resetBankProofActiontype();
      this.props.onClose();
    } else if (bankProofActionType === "UPDATE_BANK_PROOF_FAILED") {
      this.setState({isProofFailed: false});
      this.setState({isProofSuccess: false});
      this.setState({uploadMessage: ""});
      this.setState({fileList: []})
      resetBankProofActiontype();
    }
    
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, profile, bankDetails } = this.props;
    if (this.state.fileList && this.state.fileList.length > 0) {
      form.validateFields((err, fields) => {
        if (!err) {
          const body = {
            userId: profile.userId,
            accountType: fields.accountTypes,
            accountName: fields.accountName,
            accountNumber: fields.accountNumber,
            bankProviderId: fields.bank
          };

          if (Object.keys(bankDetails).length) {
            this.props.updateBankDetails(body);
          } else {
            this.props.createBankDetails(body);
          }

        }
      });
    } else {
      this.setState({bankProofModalOpen:true });
    }
  };

  changeValue = () => {
    this.setState({disabled:false})
  }

  handleOpenModal = () => {
    this.setState({
      clearModal: true,
    })
  }

  handleCloseModal = () => {
    this.setState({
      clearModal: false,
    })
  }

  handleClearBank = () => {
    const { clearBankDetails, profile, onClose } = this.props;
    const { userId } = profile;
    clearBankDetails(userId);
    onClose();
  }

  hide = () => this.setState({ setVisible: !this.state.setVisible });
  handleVisibleChange = (newVisible) => this.setState({ setVisible: newVisible });

  openbankProofConfirmModal(isOpen) {
    this.setState({ bankProofModalOpen: isOpen });
  }

  uploadBankProof = () => {
    const { profile } = this.props;
    const { fileList } = this.state;
    const id = profile.userId;
    const formData = new FormData();
    fileList.forEach(files => {
      formData.append('file', files);
    });
    this.props.uploadBankProof({formData, id});
  }
  
 
  render() {
    const { form, isLoading, profile, bank, bankDetails } = this.props;
    const { addressList, authorities = [] } = profile;
    const roleName = authorities.map(val => (val.name));
    const roles = roleName.includes('ROLE_SSO_CUSTOMER_SUPPORT') ? true : false;
    const { fileList } = this.state;
    const accountTypes = [
        {value:"SAVINGS",name:"Savings",},
        // {value:"JOINT",name:"Joint"},
        {value:"CHECKING",name:"Checking"},
        // {value:"PAYROLL",name:"Payroll"},
        // {value:"PASSBOOK",name:"Passbook"},
        // {value:"TIME_DEPOSIT",name:"Time Deposit"},
        // {value:"FOREIGN",name:"Foreign"},
    ]

    form.getFieldDecorator("address_id", {
      initialValue: addressList.length
        ? addressList[addressList.length - 1].id
        : null
    });

    const props = { 
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            isProofSuccess: false,
            isProofFailed:false,
            uploadMessage: "",
            disabled:true,
          };
        });
      },
      beforeUpload: file => {
        const size = file.size / 1024 / 1024 < 5;
        const filename = file.name.split('.');
        if(filename[filename.length -1] === 'jpg' || filename[filename.length -1] === 'png' || filename[filename.length -1] === 'pdf'){
          if (!size) {
            message.error('File must be smaller than 5 MB');
            this.setState(state => ({
              fileList: [],
              isProofFailed: true,
              isProofSuccess: false,
              uploadMessage: "File must be smaller than 5 MB"
            }));
          }
           else {
            this.setState(state => ({
              fileList: [file],
              isProofFailed: false,
              isProofSuccess: true,
              uploadMessage: "File attached successfully."
            }),()=>this.changeValue())
            
          }
        } else {
          message.error('Please upload a PDF, JPG, or PNG file');
          this.setState(state => ({
            fileList: [],
            isProofFailed: true,
            isProofSuccess: false,
            uploadMessage: "Please upload a PDF, JPG, or PNG file"
          }));
        }
        return false;
      },
      fileList,
    };

    return (
      <div>
        <Spin spinning={isLoading}>
          <Form onSubmit={this.onSubmit}>
            <Row>
            <Col span={24} style={{marginBottom:'1rem'}}>
            <Alert
              message="Important"
              description={<p>Please note that remittances, for transactions made, prior to bank details update will be remitted to the <b>OLD</b> Bank account. To avoid any issues, please take note of your pending transactions before updating your bank details.</p>}
              type="info"
              showIcon
            />
            </Col>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Bank</label>
                  {form.getFieldDecorator("bank", {
                    rules: [
                      { required: true, message: "this field is required" }
                    ],
                    initialValue: Object.keys(bankDetails).length
                      ? bankDetails.bankProviderId
                      : null
                  })(
                    <Select
                      className="entrego-select"
                      showSearch
                      placeholder="Select Bank"
                      disabled = {roles}
                      onChange={this.changeValue}
                    >
                    {bank.length ? (
                      bank.map((item, i) => (
                        <Option key={i} value={item.bankId}>
                            {item.name}
                        </Option>
                      ))
                    ) : null}  
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Account Type</label>
                  {form.getFieldDecorator("accountTypes", {
                    rules: [
                      { required: true, message: "this field is required" }
                    ],
                    initialValue: Object.keys(bankDetails).length
                      ? bankDetails.accountType
                      : null
                  })(
                    <Select
                      className="entrego-select"
                      showSearch
                      placeholder="Select Bank"
                      disabled = {roles}
                      onChange={this.changeValue}
                    >
                      {accountTypes.map((item, i) => (
                        <Option key={i} value={item.value}>
                            {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              

              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Account Name</label>
                  {form.getFieldDecorator("accountName", {
                    rules: [
                        { required:true, message: 'this field is required' },
                        { min: 2, message: 'Account name must be minimum of 2 characters.' },
                        { max: 50, message: 'Account name must be maximum of 50 characters'},
                        { pattern: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                        message: 'This field can only contain letters, digits and space in between'
                      }
                    ],
                    initialValue: bankDetails.accountName
                  })(<Input className="material-input" disabled = {roles} onChange={this.changeValue}/>)}
                </Form.Item>
              </Col>
            
              <Col span={24}>
                <Form.Item>
                  <label className="profile-label-text">*Account Number</label>
                  {form.getFieldDecorator("accountNumber", {
                     rules: [
                        { max: 16, message: "Maximum of only 16 digits." },
                        { required:true, message: 'this field is required'},
                      ],
                      getValueFromEvent: e => {
                        const { target } = e;
                        
                        // return empty string if empty
                        if ( target.value.length === 0 ) {
                          return form.setFieldsValue({"accountNumber": ""})
                        }

                        
                        const intValue = /^\+?[0-9][\d]*$/.test(target.value);
                        // check if value is int
                        if( intValue ) {
                          return target.value
                        } else {
                          return form.getFieldValue("accountNumber")
                        }
                      },
                    initialValue: bankDetails.accountNumber
                  })(<Input className="material-input" disabled = {roles} onChange={this.changeValue} />)}
                </Form.Item>
              </Col>
              
            </Row>

            <p className="bankproof-label-text">
              Proof of Bank Account
              <span>
                <Button className="pouchbox-tooltip-style">
                  <Popover
                    placement="rightTop"
                    width={"30%"}
                    onClick={this.hide}
                    content={
                      <div>
                        <Row>
                          <Col span={24}>
                            <p className="bankproof-text-style">
                              To ensure faster processing of your remittance, <br />kindly upload your bank account proof in jpg,png/pdf format only,<br /> with a maximum file size of 5 MB.
                            </p>
                          </Col>
                        </Row>
                      </div>}
                    trigger="click"
                    visible={this.state.setVisible}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5013 0.416504C3.5913 0.416504 0.417969 3.58984 0.417969 7.49984C0.417969 11.4098 3.5913 14.5832 7.5013 14.5832C11.4113 14.5832 14.5846 11.4098 14.5846 7.49984C14.5846 3.58984 11.4113 0.416504 7.5013 0.416504ZM8.20964 11.0415H6.79297V6.7915H8.20964V11.0415ZM8.20964 5.37484H6.79297V3.95817H8.20964V5.37484Z" fill="#712B81" />
                    </svg>
                  </Popover>
                </Button>
              </span>
            </p>

            {this.state.isProofSuccess ?
              <Row>
                <Col span={2} style={{ marginTop: "10px" }}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z" fill="#06BF38" />
                  </svg>
                </Col>
                <Col span={22} className="proof-success-label-style">
                  {this.state.uploadMessage}
                </Col>
              </Row> : null
            }
            {this.state.isProofFailed ? <p className="proof-failure-label-style">
              {this.state.uploadMessage}
            </p> : null
            }
            <Col span={24}>
              <Upload {...props}>
                <Button
                  id="upload-bank"
                  className="upload-proof-btn-style"
                  disabled = {roles}
                >
                  Upload
                </Button>
              </Upload>
            </Col>
            <Row gutter={16} type="flex" justify="space-between">
              <Col span={24}>
                <Button
                  id="update-bank"
                  htmlType="submit"
                  disabled={ this.state.disabled || roles }
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-grey mt-3"
                  style={{ width: "100%" }}
                  disabled={ roles }
                  onClick={this.handleOpenModal}
                >
                  Clear
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-white mt-3"
                  style={{ width: "100%" }}
                  onClick={this.props.onClose}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>

        <Modal 
            visible={this.state.clearModal}
            onCancel={this.handleCloseModal}
            title="Reset Bank Settings"
            content={
              <div>
              <p>Do you want to clear your bank account settings? This will clear data records but will also disable cash-on-delivery packages.</p>
              </div>
            }
            footer={[
              <Button
                key="close"
                className="btn-white"
                onClick={this.handleCloseModal}
              >
                NO
              </Button>,
              <Button
              key="confirm"
              className="btn-orange"
              onClick={this.handleClearBank}
            >
              CONFIRM
            </Button>,
            ]}
          />

        <ContactModal title=""
          className={"upload-proof-modal-style"}
          visible={this.state.bankProofModalOpen}
          onOk={() => this.openbankProofConfirmModal(false)}
          onCancel={() => this.openbankProofConfirmModal(false)}
          content={
            <div  >
              <Row>
                <p className="bank-proof-title">
                  Proof of Bank
                  Account Needed </p>
                <p className="bank-proof-content">
                  Please update your bank information and upload proof <br /> showing your name and bank account number
                </p>
              </Row>
              <Row>
                <Col className="button-align" span={24}>
                  <Button key="back" className="bank-ok-btn-style" onClick={() => this.openbankProofConfirmModal(false)}>
                    Okay
                  </Button>
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    );
  }
}

export default Form.create()(Page);
