import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  { 
    path: "/gcash-success-payment",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/success-payment/page"),
      loading: LoadingComponent
    })
  },{ 
    path: "/gcash-failure-payment",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/failure-payment/page"),
      loading: LoadingComponent
    })
  },
];
