export const actionTypes = {
  GET_BOOKING: "GET_BOOKING",
  GET_BOOKING_SUCCESS: "GET_BOOKING_SUCCESS",
  GET_BOOKING_FAILED: "GET_BOOKING_FAILED",

  GET_SURCHARGE: "GET_SURCHARGE",
  GET_SURCHARGE_SUCCESS: "GET_SURCHARGE_SUCCESS",
  GET_SURCHARGE_FAILED: "GET_SURCHARGE_FAILED",

  BOOKING_REQUEST: "BOOKING_REQUEST",
  BOOKING_SUCCESS: "BOOKING_SUCCESS",
  BOOKING_FAILED: "BOOKING_FAILED",

  BULK_UPLOAD_REQUEST: "BULK_UPLOAD_REQUEST",
  BULK_UPLOAD_SUCCESS: "BULK_UPLOAD_SUCCESS",
  BULK_UPLOAD_FAILED: "BULK_UPLOAD_FAILED",

  UPDATE_BOOKING: "UPDATE_BOOKING",
  UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  UPDATE_BOOKING_FAILED: "UPDATE_BOOKING_FAILED",

  UPDATE_BOOKING_STATUS: "UPDATE_BOOKING_STATUS",
  UPDATE_BOOKING_STATUS_SUCCESS: "UPDATE_BOOKING_STATUS_SUCCESS",
  UPDATE_BOOKING_STATUS_FAILED: "UPDATE_BOOKING_STATUS_FAILED",

  CLOSE_SUCCESS_BOOKING_MODAL: "CLOSE_SUCCESS_BOOKING_MODAL",

  SET_BOOKING_NUMBER: "SET_BOOKING_NUMBER",
  SET_BOOKING_STATUS: "SET_BOOKING_STATUS",
  RESET_BOOKING_ACTION_TYPE: "RESET_BOOKING_ACTION_TYPE",
  RESET_BULK_ACTION_TYPE: "RESET_BULK_ACTION_TYPE",

  RESET_BOOKING_NUMBER: "RESET_BOOKING_NUMBER",
  RESET_BOOKING_STATUS_AFTER_UPDATE: "RESET_BOOKING_STATUS_AFTER_UPDATE",

  RESET_ACTION_TYPES: "RESET_ACTION_TYPES",
};

export const actionCreators = {
  getAllBookings: (body) => ({
    type: actionTypes.GET_BOOKING,
    body
  }),
  resetActiontype: () => ({ type: actionTypes.RESET_ACTION_TYPES }),
  getSurcharge: () => ({ type: actionTypes.GET_SURCHARGE }),
  createBooking: body => ({ type: actionTypes.BOOKING_REQUEST, body }),
  bulkUpload: body => ({ type: actionTypes.BULK_UPLOAD_REQUEST, body }),
  updateBookingDetails: (body, id) => ({
    type: actionTypes.UPDATE_BOOKING,
    body,
    id
  }),
  updateBookingStatus: (body, id) => ({
    type: actionTypes.UPDATE_BOOKING_STATUS,
    body,
    id
  }),
  closeModal: () => ({ type: actionTypes.CLOSE_SUCCESS_BOOKING_MODAL }),
  resetBookingNumber: () => ({ type: actionTypes.RESET_BOOKING_NUMBER }),
  resetBookingStatus: () => ({
    type: actionTypes.RESET_BOOKING_STATUS_AFTER_UPDATE
  }),
  resetBookingActionType: () => ({
    type: actionTypes.RESET_BOOKING_ACTION_TYPE
  }),
  resetBulkActionType: () => ({
    type: actionTypes.RESET_BULK_ACTION_TYPE
  }),
  setBookingNumber: id => ({ type: actionTypes.SET_BOOKING_NUMBER, id }),
  setBookingStatus: status => ({ type: actionTypes.SET_BOOKING_STATUS, status })
};

const initialState = {
  actionType: "",
  bookingNumber: "",
  bookingStatus: "",
  bookingDetails: {},
  bookings: [],
  bulk: {},
  paginationInfo: {},
  errors: null,
  isLoading: false,
  isBookingStatusUpdated: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_BOOKING:
    case actionTypes.GET_SURCHARGE:
    case actionTypes.BOOKING_REQUEST:
    case actionTypes.BULK_UPLOAD_REQUEST:
    case actionTypes.UPDATE_BOOKING_STATUS:
    case actionTypes.UPDATE_BOOKING:
      return Object.assign({}, state, {
        isLoading: true,
        isPackageFinished: false
      });

    case actionTypes.GET_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        actionType: action.type,
        isLoading: false,
        paginationInfo: action.payload && action.payload.meta,
        bookings: action.payload && action.payload.bookings
      });

    case actionTypes.GET_SURCHARGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        surcharge: action.payload.surchargeFee
      });

    case actionTypes.BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.BOOKING_SUCCESS,
        bookingNumber: action.payload.bookingNumber,
        selectedPickupDetails: action.payload,
        bookingStatus: action.payload.status
      });

    case actionTypes.BULK_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.BULK_UPLOAD_SUCCESS,
        bulk: action.payload,
      });

    case actionTypes.UPDATE_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.UPDATE_BOOKING_SUCCESS,
        bookingNumber: action.payload.bookingNumber,
        bookingStatus: action.payload.status,
        bookingDetails: action.payload,
        selectedPickupDetails: action.payload
      });

    case actionTypes.UPDATE_BOOKING_STATUS_SUCCESS:
      return Object.assign({}, state, {
        actionType: actionTypes.UPDATE_BOOKING_STATUS_SUCCESS,
        isBookingStatusUpdated: true,
        bookingStatus: action.payload.status,
        selectedPickupDetails: action.payload,
        isLoading: false
      });

    case actionTypes.CLOSE_SUCCESS_BOOKING_MODAL:
      return Object.assign({}, state, {
        isBookingStatusUpdated: false,
        bookingNumber: null
      });

    case actionTypes.SET_BOOKING_NUMBER:
      return Object.assign({}, state, {
        bookingNumber: action.id
      });

    case actionTypes.SET_BOOKING_STATUS:
      return Object.assign({}, state, {
        bookingStatus: action.status
      });

    case actionTypes.RESET_BOOKING_NUMBER:
      return Object.assign({}, state, {
        bookingNumber: null
      });

    case actionTypes.RESET_BOOKING_STATUS_AFTER_UPDATE:
      return Object.assign({}, state, {
        bookingStatus: null,
        isBookingStatusUpdated: null
      });

    case actionTypes.RESET_BOOKING_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: null
      });

    case actionTypes.RESET_BULK_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: null
      });


    case actionTypes.RESET_ACTION_TYPES:
      return Object.assign({}, state, {
        actionType: null
      });

    case actionTypes.BULK_UPLOAD_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        bulk: {},
        errors: action.error.message,
        actionType: actionTypes.BULK_UPLOAD_FAILED
      });

    case actionTypes.GET_BOOKING_FAILED:
    case actionTypes.GET_SURCHARGE_FAILED:
    case actionTypes.BOOKING_FAILED:
    case actionTypes.UPDATE_BOOKING_STATUS_FAILED:
    case actionTypes.UPDATE_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error.message
      });

    default:
      return state;
  }
};
