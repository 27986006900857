import request from "../../utils/api";

export const getProvinces = () => request(`locations/provinces`);

export const getMunicipalities = (id) =>
  request(`locations/provinces/${id}/cities?coverageType=SA-C,SA-O`);

export const getMunicipalitiesSAC = (id) =>
  request(`locations/provinces/${id}/cities/serviceable?coverageType=SA-C`);

// Commented as per CEP-806
// export const getBarangays = (id) =>
//   request(`locations/cities/${id}/barangays?filtered=true`);

export const getBarangays = (id) =>
  request(`locations/cities/${id}/barangays`);

export const getShippingFee = (body) =>
  // request(`bookings/rates/shipping-rate`, { method: "POST", body });
  request(`pcal/calculate-fees/retail/consumer`, { method: "POST", body });

export const getBulkShippingFee = (body) =>
  request(`pcal/calculate-fees/retail/consumer/bulk`, { method: "POST", body });

export const servicableProvince = () =>
  request("locations/provinces/serviceable");

export const serviceableMunicipalities = (id) =>
  request(`locations/provinces/${id}/municipalities/serviceable`);

export const serviceableSAR = (id) =>
  request(
    `locations/provinces/${id}/cities/serviceable?coverageType=SA-C,SA-O`
  );

export const getAllServiceableSAR = (id) =>
  request(
    `locations/provinces/${id}/cities/serviceable`
  );

export const getBarangayById = (id) => request(`locations/barangays/${id}`);

export const getProfileAddress = (id) =>
  request(`locations/provinces/${id}/cities`);

export const getProfileBarangay = (id) => request(`locations/barangays/${id}`);

// export const assignCodClientToUsers = body =>
// request(`users/account/roles`, { method: "PATCH", body });
export const assignCodClientToUsers = body =>
  request(`users/account/cep/roles`, { method: "PATCH", body });
export const getUserDetails = (id) => request(`users/account/get/${id}`);
export const getPHholidaysList = () => request(`bookings/holiday/list`);
export const updateUserAddress = body => request(`users/account/update`, { method: "PUT", body });

export const getSenderServiceableProvinces = () =>
  request("locations/provinces/serviceable");

export const getAllSenderServiceableSAR = (id) =>
  request(
    `locations/provinces/${id}/cities/serviceable`
  );

// Commented as per CEP-806
// export const getSenderBarangays = (id) =>
//   request(`locations/cities/${id}/barangays?filtered=true`);

export const getSenderBarangays = (id) =>
  request(`locations/cities/${id}/barangays`);