import { all, put, takeLatest, call } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import { actionTypes as packageActionTypes } from "../packages/reducer";
import {
  book as bookService,
  updateBookingStatus as updateBookingStatusService,
  getUserBookings as getUserBookingsService,
  getSurcharge as getSurchargeService,
  updateBooking as updateBookingService,
  bulkUpload as bulkUploadService,
} from "./service";

import processAction from "../../utils/processAction";

function* getUserBookings({ body }) {
  if (body && body.userId) {
    yield processAction({
      params: body,
      service: getUserBookingsService,
      success: actionTypes.GET_BOOKING_SUCCESS,
      failed: actionTypes.GET_BOOKING_FAILED
    });
  }
}

function* getSurcharge({ id }) {
  yield processAction({
    params: id,
    service: getSurchargeService,
    success: actionTypes.GET_SURCHARGE_SUCCESS,
    failed: actionTypes.GET_SURCHARGE_FAILED
  })
}

function* book({ body }) {
  yield processAction({
    params: body,
    service: bookService,
    success: actionTypes.BOOKING_SUCCESS,
    failed: actionTypes.BOOKING_FAILED
  });
}

function* bulkUpload({ body }) {
  yield processAction({
    params: body,
    service: bulkUploadService,
    success: actionTypes.BULK_UPLOAD_SUCCESS,
    failed: actionTypes.BULK_UPLOAD_FAILED
  });
}

function* updateBooking({ body, id }) {
  try {
    const data = yield call(updateBookingService, body);

    if (data) {
      yield put({ type: packageActionTypes.GET_PACKAGES, id: body.bookingNumber });
    }

    yield put({ type: actionTypes.UPDATE_BOOKING_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_BOOKING_FAILED, error });
  }
}

function* updateBookingStatus({ body, id }) {
  yield processAction({
    params: body,
    service: updateBookingStatusService,
    success: actionTypes.UPDATE_BOOKING_STATUS_SUCCESS,
    failed: actionTypes.UPDATE_BOOKING_STATUS_FAILED
  });

  if (id) {
    yield put({ type: "GET_BOOKING", id });
  }
}

function* watchBookingRequests() {
  yield all([
    takeLatest(actionTypes.GET_BOOKING, getUserBookings),
    takeLatest(actionTypes.GET_SURCHARGE, getSurcharge),
    takeLatest(actionTypes.BOOKING_REQUEST, book),
    takeLatest(actionTypes.BULK_UPLOAD_REQUEST, bulkUpload),
    takeLatest(actionTypes.UPDATE_BOOKING_STATUS, updateBookingStatus),
    takeLatest(actionTypes.UPDATE_BOOKING, updateBooking)
  ]);
}

export default [watchBookingRequests()];
