import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import "./styles.css";
import Air21Logo from "../../images/air21booking_white.svg";

const Footer = () => {
  return (
    <div className="background-primary">
      
      <Row
        className="entrego-footer text-white"
        type="flex"
        justify="space-around"
      >
        <div className="container-fluid">
          <Col span={24} style={{marginTop: "40px"}}>
            <img 
              src={Air21Logo}
              height="60"
              width="283"
              alt="air21-logo.png"
            />
          </Col>
          <Col xs={24} lg={10} >
          </Col>

          <Col xs={24} lg={14} style={{marginTop: "-40px"}}>
            <Row style={{ fontSize: 14, textTransform: "uppercase" }}>
              <Col span={8}>
                <p className="text-right">
                  <a 
                    className="footer-link"
                    href="https://air21.com.ph/history.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About
                  </a>
                </p>
                
              </Col>
              <Col span={8}>
              <p className="text-right">
                  <Link
                    target="_blank"
                    className="footer-link"
                    to="/termsandconditions"
                  >
                    Terms and Conditions
                  </Link>
                </p>
                <p className="text-right">
                  <Link
                    target="_blank"
                    className="footer-link"
                    to="/termsofuse"
                  >
                    Terms of use
                  </Link>
                </p>
                
              </Col>
              <Col span={8}>
                <p className="text-right">
                  {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                    href="https://gdoc.pub/doc/e/2PACX-1vTsPY5qn41hRakQh6ZP5QsZZWr4BDZeouK_8E8n0zSAUN7r8iStOy2IpR-hwp_TQWEDHC1D0IBv_mo_"
                  >
                    Serviceable Area Coverage
                  </a> */}
                  <Link target="_blank" className="footer-link" to="/coverage">
                    Serviceable Areas
                  </Link>
                </p>
                <p className="text-right">
                  <Link
                    target="_blank"
                    className="footer-link"
                    to="/privacystatement"
                  >
                    Privacy Statement
                  </Link>
                </p>
              </Col>
            </Row>
          </Col>
        </div>
        
      </Row>
    </div>
  );
};

export default connect()(Footer);
