import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/coverage",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./"),
      loading: LoadingComponent
    })
  }
];
