import request from "../../utils/api";

/**
 * Cancel packages service endpoint
 *
 * @param {{trackingNumbers: string[], cancellationReason: string}} body
 * @returns request
 */
export const cancelPackages = (body) =>
  request(`bookings/packages/cancel`, { method: "POST", body });
