import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getProvincesDropDown as getProvincesDropDownService,
  getPickUpcityDropDown as getPickUpcityDropDownService,
  getPouchBoxCalculatedValue as getPouchBoxCalculatedValueService,
  getBarangaysUnfiltered as getBarangaysUnfilteredService,
  pouchBoxBooking as pouchBoxBookingService,
  pouchBoxPackage as pouchBoxPackageService,
  pouchBoxFinalize as pouchBoxFinalizeService,
} from "./service";
import processAction from "../../utils/processAction";

function* getProvincesDropDown() {
  yield processAction({
    service: getProvincesDropDownService,
    success: actionTypes.GET_PROVINCE_DROPDOWNS_SUCCESS,
    failed: actionTypes.GET_PROVINCE_DROPDOWNS_FAILED,
  });

}
function* getBarangaysUnfiltered({ id }) {
  yield processAction({
    params: id,
    service: getBarangaysUnfilteredService,
    success: actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_SUCCESS,
    failed: actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_FAILED,
  });
}
function* getPickUpCityDropDown({ provinceId }) {
  yield processAction({
    params: provinceId,
    service: getPickUpcityDropDownService,
    success: actionTypes.GET_CITY_DROPDOWNS_PICKUP_SUCCESS,
    failed: actionTypes.GET_CITY_DROPDOWNS_PICKUP_FAILED,
  });

}
function* pouchBoxBooking({ body }) {
  yield processAction({
    params: body,
    service: pouchBoxBookingService,
    success: actionTypes.GET_POUCH_BOX_BOOKING__SUCCESS,
    failed: actionTypes.GET_POUCH_BOX_BOOKING__FAILED,
  });
}
function* pouchBoxFinalize({ body }) {
  yield processAction({
    params: body,
    service: pouchBoxFinalizeService,
    success: actionTypes.GET_POUCH_BOX_FINALIZE__SUCCESS,
    failed: actionTypes.GET_POUCH_BOX_FINALIZE__FAILED,
  });
}
function* pouchBoxPackage({ body }) {
  yield processAction({
    params: body,
    service: pouchBoxPackageService,
    success: actionTypes.GET_POUCH_BOX_PACKAGE__SUCCESS,
    failed: actionTypes.GET_POUCH_BOX_PACKAGE__FAILED,
  });
}
function* getPouchBoxCalculatedValue({ body }) {
  yield processAction({
    params: body,
    service: getPouchBoxCalculatedValueService,
    success: actionTypes.GET_CALCULATOR_POUCH_BOX__SUCCESS,
    failed: actionTypes.GET_CALCULATOR_POUCH_BOX__FAILED,
  });
}

function* watchHomeRequests() {
  yield all([
    takeLatest(actionTypes.GET_POUCH_BOX_BARANGAYS_UNFILTERED_REQUEST, getBarangaysUnfiltered),
    takeLatest(actionTypes.GET_CITY_DROPDOWNS_PICKUP_REQUEST, getPickUpCityDropDown),
    takeLatest(actionTypes.GET_PROVINCE_DROPDOWNS_REQUEST, getProvincesDropDown),
    takeLatest(actionTypes.GET_CALCULATOR_POUCH_BOX__REQUEST, getPouchBoxCalculatedValue),
    takeLatest(actionTypes.GET_POUCH_BOX_BOOKING__REQUEST, pouchBoxBooking),
    takeLatest(actionTypes.GET_POUCH_BOX_PACKAGE__REQUEST, pouchBoxPackage),
    takeLatest(actionTypes.GET_POUCH_BOX_FINALIZE__REQUEST, pouchBoxFinalize),
  ]);
}

export default [watchHomeRequests()];
