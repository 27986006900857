import { all, takeLatest, call, put } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getCODTransactions as getCODTransactionsService,
  getNextCODTransactions as getNextCODTransactionsService,
  getCODTransactionByReference as getCODTransactionByReferenceService,
  getCODReports as getCODReportsService,
  getCODRemittances as getCODRemittancesService,
  updateCODOrderStatus as updateCODOrderStatusService,
  downloadAction as downloadActionService
} from "./service";
import { message } from 'antd';
import processAction from "../../../../utils/processAction";
// import { toast } from 'react-toastify';

function* getCODTransactions({ body }) {
  yield processAction({
    params: body,
    service: getCODTransactionsService,
    success: actionTypes.GET_COD_TRANSACTION_SUCCESS,
    failed: actionTypes.GET_COD_TRANSACTION_FAILED
  });
}

function* getNextCODTransactions({ body }) {
  yield processAction({
    params: body,
    service: getNextCODTransactionsService,
    success: actionTypes.GET_NEXT_COD_TRANSACTION_SUCCESS,
    failed: actionTypes.GET_NEXT_COD_TRANSACTION_FAILED
  });
}

function* getCODTransactionByReference({ body }) {
  try {
    const transaction = yield call(getCODTransactionByReferenceService, body);
    const data = {
      meta: {
        limit: 1,
        count: 1,
        relLinks: {}
      },
      orders: [transaction]
    };

    yield put({ type: actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_SUCCESS, payload: data });
  } catch (error) {
    message.error(error.details);
    yield put({ type: actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_FAILED });
  }
}

function* getCODRemittances({ body }, checkAnomaly) {
  try {
    const remittances = yield call(getCODRemittancesService, body);
    const { meta, orders } = remittances || {};
    const next = meta.relLinks.next || '';

    let data = { meta, orders };
  
    if (next) {
      const nextRemittances = yield call(getNextCODTransactionsService, { next: next });
      const { meta, orders } = nextRemittances || {};

      if (nextRemittances) {
        data.meta.relLinks.next = '';
        data.meta.limit += meta.limit;
        data.orders = data.orders.concat(orders);
      }
    }

    if (checkAnomaly) {
      data.orders = data.orders.filter(e => !e.anomaly); 
    } 

    data.meta.count = data.orders.length;

    yield put({ type: actionTypes.GET_COD_REMITTANCE_SUCCESS, payload: data });
  } catch(error) {
    message.error(error.message || error.details);
    yield put({ type: actionTypes.GET_COD_REMITTANCE_FAILED });
  }
}

function* downloadAction({body}) {
  try{
    const data = yield call(downloadActionService, body);
     window.open(
  `${data.presigned_url}`,
      '_self'
    );
    if(!data.presigned_url) {
      message.error("No proof of bank found");
    }
    yield put({ type: actionTypes.GET_DOWNLOAD_SUCCESS, payload: data });
  } catch(error) {
    message.error(error.message || error.details);
    yield put({ type: actionTypes.GET_DOWNLOAD_FAILED });
    message.error("No proof of bank found");
  }
}

function* updateCODOrderStatus({ body }) {
  yield processAction({
    params: body,
    service: updateCODOrderStatusService,
    success: actionTypes.UPDATE_COD_STATUS_SUCCESS,
    failed: actionTypes.UPDATE_COD_STATUS_FAILED
  });
}

function* batchUpdateCODStatus({ body }) {
  try { 
    if (body.orders.length > 100){
      const filterOneHundredOrders = body.orders.slice(0,100);
      const data = yield call(updateCODOrderStatusService, { op: body.op, orders: filterOneHundredOrders });
      if(data){
        body.orders = body.orders.splice(100);
        yield put({ type: actionTypes.BATCH_UPDATE_COD_STATUS_SUCCESS, payload: data });
        yield put({ type: actionTypes.BATCH_UPDATE_COD_STATUS_REQUEST, body});
      }
    } else {
      yield put({ type: actionTypes.UPDATE_COD_STATUS_REQUEST, body });
    }
  } catch(error) {
    message.error(error.message || error.details);
    yield put({ type: actionTypes.BATCH_UPDATE_COD_STATUS_FAILED });
  }
}

function* getCODReports({ body }) {
  yield processAction({
    params: body,
    service: getCODReportsService,
    success: actionTypes.GET_COD_REPORTS_SUCCESS,
    failed: actionTypes.GET_COD_REPORTS_FAILED
  });
}



function* watchFinanceRequests() {
  yield all([
    takeLatest(actionTypes.GET_COD_TRANSACTION_REQUEST, getCODTransactions),
    takeLatest(actionTypes.GET_NEXT_COD_TRANSACTION_REQUEST, getNextCODTransactions),
    takeLatest(actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_REQUEST, getCODTransactionByReference),
    takeLatest(actionTypes.GET_COD_REMITTANCE_REQUEST, getCODRemittances),
    takeLatest(actionTypes.GET_COD_REPORTS_REQUEST, getCODReports),
    takeLatest(actionTypes.UPDATE_COD_STATUS_REQUEST, updateCODOrderStatus),
    takeLatest(actionTypes.BATCH_UPDATE_COD_STATUS_REQUEST, batchUpdateCODStatus),
    takeLatest(actionTypes.GET_DOWNLOAD_REQUEST, downloadAction)
  ]);
}

export default [watchFinanceRequests()];
