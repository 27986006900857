import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getServiceableCities as getServiceableCitiesService,
  getBarangaysUnfiltered as getBarangaysUnfilteredService,
  serviceableAreaChecker as serviceableAreaCheckerService,
} from "./service";
import processAction from "../../utils/processAction";

function* getBarangaysUnfiltered({ id }) {
  yield processAction({
    params: id,
    service: getBarangaysUnfilteredService,
    success: actionTypes.GET_BARANGAYS_UNFILTERED_SUCCESS,
    failed: actionTypes.GET_BARANGAYS_UNFILTERED_FAILED,
  });
}

function* getServiceableCities({ id }) {
  yield processAction({
    params: id,
    service: getServiceableCitiesService,
    success: actionTypes.GET_SERVICEABLE_CITIES_SUCCESS,
    failed: actionTypes.GET_SERVICEABLE_CITIES_FAILED,
  });
}

function* serviceableAreaChecker({ body }) {
  yield processAction({
    params: body,
    service: serviceableAreaCheckerService,
    success: actionTypes.CHECK_SERVICEABLE_AREA_SUCCESS,
    failed: actionTypes.CHECK_SERVICEABLE_AREA_FAILED,
  });
}

function* watchCoverageRequests() {
  yield all([
    takeLatest(actionTypes.GET_BARANGAYS_UNFILTERED_REQUEST, getBarangaysUnfiltered),
    takeLatest(actionTypes.GET_SERVICEABLE_CITIES_REQUEST, getServiceableCities),
    takeLatest(actionTypes.CHECK_SERVICEABLE_AREA_REQUEST, serviceableAreaChecker),
  ]);
}

export default [watchCoverageRequests()];
