import { all, put, takeLatest, call } from "redux-saga/effects";
import { message } from "antd";
import { actionTypes } from "./reducer";
import {
  login as loginService,
  logout as logoutService,
  register as registerService,
  googleLogin as googleLoginService,
  facebookLogin as facebookLoginService,
  getProfile as getProfileService,
  getBank as getBankService,
  getBankDetails as getBankDetailsService,
  createBankDetails as createBankDetailsService,
  updateBankDetails as updateBankDetailsService,
  updateProfile as updateProfileService,
  //updateIdentification as updateIdentificationService,
  verify as verifyService,
  resendVerification as resendVerificationService,
  forgotPassword as forgotPasswordService,
  renewPassword as renewPasswordService,
  forgotPasswordValidateToken as forgotPasswordValidateTokenService,
  clearBankDetails as clearBankDetailsService,
  getCepProfile as getCepProfileService,
  uploadBankProof as uploadBankProofService,
} from "./service";

import { actionTypes as locationActionTypes } from '../location/reducer';


import processAction from "../../utils/processAction";
import { validateRole, role } from '../../utils/cep-role-validation';

function* login({ body }) {
  try {
    const payload = yield call(loginService, body);
    const { userId, authorities } = payload;
    yield put({ type: actionTypes.LOGIN_SUCCESS, payload });
    yield put({ type: locationActionTypes.GET_PROVINCES_REQUEST });
    yield put({ type: actionTypes.GET_PROFILE, id: userId });
    const validatedRole = validateRole(authorities,
      [role.ROLE_BOOK_MANAGED_ACCOUNT]);
    const currentProfile = validatedRole ?
      yield call(getCepProfileService, userId) : { accounts: [] };
    payload.accounts = currentProfile.accounts;
    if (payload.accounts && payload.accounts.length > 0) {
      const cepAccountId = validatedRole ? payload.accounts && payload.accounts[0].accountId : null;
      yield put({ type: actionTypes.CEP_PROFILE_SUCCESS, id: cepAccountId });
    }
  } catch (error) {
    message.error(error.details || error.message);
    yield put({ type: actionTypes.LOGIN_FAILED, error })
  }

}

function* logout() {
  yield processAction({
    service: logoutService,
    success: actionTypes.LOGOUT_SUCCESS,
    failed: actionTypes.LOGOUT_FAILED
  });
}

function* verify({ body }) {
  yield processAction({
    params: body,
    service: verifyService,
    success: actionTypes.VERIFY_SUCCESS,
    failed: actionTypes.VERIFY_FAILED
  });
}

function* resendVerification({ body }) {
  yield processAction({
    params: body,
    service: resendVerificationService,
    success: actionTypes.RESEND_VERIFICATION_SUCCESS,
    failed: actionTypes.RESEND_VERIFICATION_FAILED
  });
}

function* forgotPassword({ body }) {
  yield processAction({
    params: body,
    service: forgotPasswordService,
    success: actionTypes.FORGOT_PASSWORD_SUCCESS,
    failed: actionTypes.FORGOT_PASSWORD_FAILED
  });
}

function* renewPassword({ body }) {
  yield processAction({
    params: body,
    service: renewPasswordService,
    success: actionTypes.RENEW_PASSWORD_SUCCESS,
    failed: actionTypes.RENEW_PASSWORD_FAILED
  });
}

function* forgotPasswordValidateToken({ body }) {
  yield processAction({
    params: body,
    service: forgotPasswordValidateTokenService,
    success: actionTypes.FORGOT_PASSWORD_VALIDATE_SUCCESS,
    failed: actionTypes.FORGOT_PASSWORD_VALIDATE_FAILED
  });
}

function* signup({ body }) {
  yield processAction({
    params: body,
    service: registerService,
    success: actionTypes.REGISTER_SUCCESS,
    failed: actionTypes.REGISTER_FAILED
  });
}

function* updateProfile({ id, body }) {
  yield processAction({
    params: body,
    service: updateProfileService,
    success: actionTypes.UPDATE_PROFILE_SUCCESS,
    failed: actionTypes.UPDATE_PROFILE_FAILED,
  });

  yield put({ type: actionTypes.GET_PROFILE, id });
}

function* updateIdentification({ id, body, profilePayload }) {
  try {
    //const data = yield call(updateIdentificationService, id, body);
    //yield put({ type: actionTypes.UPDATE_IDENTIFICATION_SUCCESS, data });
    yield put({ type: actionTypes.UPDATE_PROFILE, id, body: profilePayload });
  } catch (error) {
    message.error(error.message);
    yield put({ type: actionTypes.UPDATE_PROFILE_FAILED, error });
  }
}

function* googleLogin({ body }) {
  try {
    const payload = yield call(googleLoginService, body);
    const { userId } = payload;
    yield put({ type: actionTypes.GOOGLE_LOGIN_SUCCESS, payload });
    yield put({ type: locationActionTypes.GET_PROVINCES_REQUEST });
    yield put({ type: actionTypes.GET_PROFILE, id: userId });
  } catch (error) {
    message.error(error.details || error.message);
    yield put({ type: actionTypes.GOOGLE_LOGIN_FAILED, error })
  }
};

function* facebookLogin({ body }) {
  try {
    const payload = yield call(facebookLoginService, body);
    const { userId } = payload;
    yield put({ type: actionTypes.FACEBOOK_LOGIN_SUCCESS, payload });
    yield put({ type: locationActionTypes.GET_PROVINCES_REQUEST });
    yield put({ type: actionTypes.GET_PROFILE, id: userId });
  } catch (error) {
    message.error(error.details || error.message);
    yield put({ type: actionTypes.FACEBOOK_LOGIN_FAILED, error })
  }
}

function* getAddress({ address }) {
  yield put({
    type: actionTypes.GET_LOCATION_SUCCESS,
    payload: { address }
  });
}

function* getProfile({ id }) {
  yield processAction({
    params: id,
    service: getProfileService,
    success: actionTypes.GET_AUTHENTICATION_SUCCESS,
    failed: actionTypes.GET_AUTHENTICATION_FAILED
  });
}

function* getBank({ id }) {
  yield processAction({
    params: id,
    service: getBankService,
    success: actionTypes.GET_BANK_SUCCESS,
    failed: actionTypes.GET_BANK_FAILED
  });
}

function* getBankDetails({ id }) {
  yield processAction({
    params: id,
    service: getBankDetailsService,
    success: actionTypes.GET_BANK_DETAILS_SUCCESS,
    failed: actionTypes.GET_BANK_DETAILS_FAILED
  });
}

function* clearBankDetails({ id }) {
  yield processAction({
    params: id,
    service: clearBankDetailsService,
    success: actionTypes.CLEAR_BANK_DETAILS_SUCCESS,
    failed: actionTypes.CLEAR_BANK_DETAILS_FAILED
  });
}



function* createBankDetails({ body }) {
  yield processAction({
    params: body,
    service: createBankDetailsService,
    success: actionTypes.CREATE_BANK_DETAILS_SUCCESS,
    failed: actionTypes.CREATE_BANK_DETAILS_FAILED
  });
}

function* updateBankDetails({ body }) {
  yield processAction({
    params: body,
    service: updateBankDetailsService,
    success: actionTypes.UPDATE_BANK_DETAILS_SUCCESS,
    failed: actionTypes.UPDATE_BANK_DETAILS_FAILED
  });
}

function* uploadBankProof({ body }) {
  yield processAction({
    params: body,
    service: uploadBankProofService,
    success: actionTypes.UPDATE_BANK_PROOF_SUCCESS,
    failed: actionTypes.UPDATE_BANK_PROOF_FAILED
  });
}

function* watchAuthRequests() {
  yield all([
    takeLatest(actionTypes.LOGIN_REQUEST, login),
    takeLatest(actionTypes.LOGOUT_REQUEST, logout),
    takeLatest(actionTypes.GET_LOCATION_REQUEST, getAddress),
    takeLatest(actionTypes.REGISTER_REQUEST, signup),
    takeLatest(actionTypes.GOOGLE_LOGIN_REQUEST, googleLogin),
    takeLatest(actionTypes.FACEBOOK_LOGIN_REQUEST, facebookLogin),
    takeLatest(actionTypes.UPDATE_PROFILE, updateProfile),
    takeLatest(actionTypes.UPDATE_IDENTIFICATION, updateIdentification),
    takeLatest(actionTypes.GET_PROFILE, getProfile),
    takeLatest(actionTypes.GET_BANK, getBank),
    takeLatest(actionTypes.GET_BANK_DETAILS, getBankDetails),
    takeLatest(actionTypes.CLEAR_BANK_DETAILS_REQUEST, clearBankDetails),
    takeLatest(actionTypes.CREATE_BANK_DETAILS, createBankDetails),
    takeLatest(actionTypes.UPDATE_BANK_DETAILS, updateBankDetails),
    takeLatest(actionTypes.VERIFY_REQUEST, verify),
    takeLatest(actionTypes.RESEND_VERIFICATION_REQUEST, resendVerification),
    takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(actionTypes.RENEW_PASSWORD_REQUEST, renewPassword),
    takeLatest(actionTypes.FORGOT_PASSWORD_VALIDATE_REQUEST, forgotPasswordValidateToken),
    takeLatest(actionTypes.UPDATE_BANK_PROOF, uploadBankProof),
  ]);
}

export default [watchAuthRequests()];
