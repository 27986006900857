import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  { 
    path: "/addresses",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/"),
      loading: LoadingComponent
    })
  },
  {
    path: "/pages/Addaddress/page",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/Addaddress/page"),
      loading: LoadingComponent
    })
  },
  {
    path: "/pages/Editaddress/page",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/Editaddress/page"),
      loading: LoadingComponent
    })
  }
];
