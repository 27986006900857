import request from "../../utils/api";

export const getPackages = id => request(`bookings/${id}/packages-with-total`, {});

export const createPackage = body =>
  request(`bookings/packages/`, { method: "POST", body });

export const updatePackage = body =>
  request(`bookings/packages/`, { method: "PATCH", body });

export const deletePackage = id =>
  request(`bookings/package/${id}`, { method: "DELETE" });

export const getPackagesStatus = id => request(`lms/package-status/${id}`);

export const getNCRDetails = body => 
  request(`bookings/delivery-type/check-available-service`, { method: "POST", body })
