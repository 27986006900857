
export const actionTypes = {
  GET_ALL_CEP_BOOKING_ACCOUNT_REQUEST: 'GET_ALL_CEP_BOOKING_ACCOUNT_REQUEST',
  GET_ALL_CEP_BOOKING_ACCOUNT_SUCCESS: 'GET_ALL_CEP_BOOKING_ACCOUNT_SUCCESS',
  GET_ALL_CEP_BOOKING_ACCOUNT_FAILED: 'GET_ALL_CEP_BOOKING_ACCOUNT_FAILED',

  GET_CEP_CLIENT_DETAILS_REQUEST: 'GET_CEP_CLIENT_DETAILS_REQUEST',
  GET_CEP_CLIENT_DETAILS_SUCCESS: 'GET_CEP_CLIENT_DETAILS_SUCCESS',
  GET_CEP_CLIENT_DETAILS_FAILED: 'GET_CEP_CLIENT_DETAILS_FAILED',

  GET_CEP_USER_DETAILS_REQUEST: 'GET_CEP_USER_DETAILS_REQUEST',
  GET_CEP_USER_DETAILS_SUCCESS: 'GET_CEP_USER_DETAILS_SUCCESS',
  GET_CEP_USER_DETAILS_FAILED: 'GET_CEP_USER_DETAILS_FAILED',

  GET_CEP_USER_BOOKINGS_REQUEST: 'GET_CEP_USER_BOOKINGS_REQUEST',
  GET_CEP_USER_BOOKINGS_SUCCESS: 'GET_CEP_USER_BOOKINGS_SUCCESS',
  GET_CEP_USER_BOOKINGS_FAILED: 'GET_CEP_USER_BOOKINGS_FAILED',

  GET_CEP_BOOKING_STATUS: 'GET_CEP_BOOKING_STATUS',
  GET_CEP_BOOKING_STATUS_SUCCESS: 'GET_CEP_BOOKING_STATUS_SUCCESS',
  GET_CEP_BOOKING_STATUS_FAILED: 'GET_CEP_BOOKING_STATUS_FAILED',

  UPDATE_CEP_BOOKING_UPDATE_TYPE: 'UPDATE_CEP_BOOKING_UPDATE_TYPE',
  UPDATE_CEP_BOOKING_UPDATE_TYPE_SUCCESS: 'UPDATE_CEP_BOOKING_UPDATE_TYPE_SUCCESS',

  GET_CEP_PROFILE: "GET_CEP_PROFILE",
  GET_CEP_PROFILE_SUCCESS: "GET_CEP_PROFILE_SUCCESS",
  GET_CEP_PROFILE_FAILED: "GET_CEP_PROFILE_FAILED",

  RESET_CEP_BOOKING_DETAILS: "RESET_CEP_BOOKING_DETAILS",
  RESET_CEP_DASHBOARD_ACTION_TYPE: "RESET_CEP_DASHBOARD_ACTION_TYPE",
  RESET_ERROR: "RESET_ERROR"
};

export const actionCreators = {
  getAllCepBookingAccounts: (limit) => ({ type: actionTypes.GET_ALL_CEP_BOOKING_ACCOUNT_REQUEST, limit }),
  getCepClientDetails: (id) => ({ type: actionTypes.GET_CEP_CLIENT_DETAILS_REQUEST, id }),
  getCepUserBookings: (body) => ({ type: actionTypes.GET_CEP_USER_BOOKINGS_REQUEST, body }),
  getCepUserDetails: (body) => ({ type: actionTypes.GET_CEP_USER_DETAILS_REQUEST, body }),
  cepBookingUpdateType: (updateType)  => ({type: actionTypes.UPDATE_CEP_BOOKING_UPDATE_TYPE, updateType}),
  getCepProfileDetails:(id) => ({type: actionTypes.GET_CEP_PROFILE, id}),
  resetCepDashboardActionType: () => ({ type: actionTypes.RESET_CEP_DASHBOARD_ACTION_TYPE }),
  resetErrorMsg: () => ({ type: actionTypes.RESET_ERROR }),
};

const initialState = {
  cepDashboardActionType: "",
  accountList: {
    meta: {
      count: 0,
      limit: 0,
      relLinks: {},
    },
    accounts: [],
  },
  cepProfile: {
    firstName: '',
    lastName: '',
    email: '',
    contactList: [],
    lastLogin: '',
    registrationDate: '',
    addressList: [],
    userId: '',
    accounts: [],
    authorities: [],
    accessToken: '',
    refreshToken: '',
  },
  clientDetails: {
    accountId: '',
  },
  cepBookings: {
    meta: {
      relLinks: {},
      limit: 0,
      count: 0,
    },
    bookings: [],
  },
  cepBookingPayload: 0,
  cepUserDetails: [{
    userId: '',
    fullName: '',
    email: '',
    isVerified: false,
    registrationDate: ''
  }],
  cepBookingUpdateType:'',
  cepAccountDetails: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CEP_BOOKING_ACCOUNT_REQUEST:
    case actionTypes.GET_CEP_CLIENT_DETAILS_REQUEST:
    case actionTypes.GET_CEP_USER_BOOKINGS_REQUEST:
    case actionTypes.GET_CEP_USER_DETAILS_REQUEST:
    case actionTypes.GET_CEP_BOOKING_STATUS:
    case actionTypes.UPDATE_CEP_BOOKING_UPDATE_TYPE:
    case actionTypes.GET_CEP_PROFILE:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case actionTypes.GET_ALL_CEP_BOOKING_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        accountList: action.payload,
        cepDashboardActionType: action.type,
      });

    case actionTypes.GET_CEP_CLIENT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepProfile: action.payload,
        clientDetails: action.payload,
        cepDashboardActionType: action.type,
      });

    case actionTypes.GET_CEP_USER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepUserDetails: action.data,
        cepDashboardActionType: action.type,
      });

    case actionTypes.GET_CEP_USER_BOOKINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepBookings: action.data,
        cepDashboardActionType: actionTypes.GET_CEP_USER_BOOKINGS_SUCCESS,
      });

    case actionTypes.GET_CEP_BOOKING_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepBookings: action.payload,
        cepDashboardActionType: actionTypes.GET_CEP_BOOKING_STATUS_SUCCESS,
      });

    case actionTypes.GET_CEP_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepAccountDetails: action.payload && action.payload.accounts,
        cepDashboardActionType: actionTypes.GET_CEP_PROFILE_SUCCESS,
      });

    case actionTypes.RESET_ERROR:
      return Object.assign({}, state, {
        error: null
      });

    case actionTypes.GET_ALL_CEP_BOOKING_ACCOUNT_FAILED:
      return Object.assign({}, state, {
        accountList: initialState.accountList,
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_CEP_CLIENT_DETAILS_FAILED:
      return Object.assign({}, state, {
        cepProfile: initialState.cepProfile,
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_CEP_USER_BOOKINGS_FAILED:
      return Object.assign({}, state, {
        cepBookings: initialState.cepBookings,
        isLoading: false,
        error: action.error && action.error.message,
      });

      case actionTypes.GET_CEP_PROFILE_FAILED:
      return Object.assign({}, state, {
        cepAccountDetails: initialState.cepAccountDetails,
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_CEP_BOOKING_STATUS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_CEP_USER_DETAILS_FAILED:
      return Object.assign({}, state, {
        cepUserDetails: initialState.cepUserDetails,
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.UPDATE_CEP_BOOKING_UPDATE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        cepBookingUpdateType: action.updateType,
        isLoading: false,
      }
      )

    case actionTypes.RESET_CEP_BOOKING_DETAILS:
      return Object.assign({}, state, {
        bookingDetails: initialState.bookingDetails,
      })

    case actionTypes.RESET_CEP_DASHBOARD_ACTION_TYPE:
      return Object.assign({}, state, {
        cepDashboardActionType: '',
      })

    default:
      return state;
  }
};
