import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/Login"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/login",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/AdminLogin"),
      loading: LoadingComponent
    })
  },
  {
    path: "/campaign/signup",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/CampaignRegister"),
      loading: LoadingComponent
    })
  },
  {
    path: "/forgot-password",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/ForgotPassword"),
      loading: LoadingComponent
    })
  },
  {
    path: "/verify",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/Verify"),
      loading: LoadingComponent
    })
  }
];
