import request from '../../../../utils/api';
export const getCODTransactions = body => {
    if ( body.status ){
        return request(`cod/orders?${body.dateFilter}=to:${body.startDate},${body.endDate}&limit=${body.limit}&page=${body.page}&status=${body.status}`)
    }
    return request(`cod/orders?${body.dateFilter}=to:${body.startDate},${body.endDate}&limit=${body.limit}&page=${body.page}&status=PaymentSubmittedToHub,RemittanceFailed,RemittanceRequested,RemittanceCompleted`)
};

export const getCODTransactionByReference = body => {
  return request(`cod/orders/${body.referenceNumber}`)
};

export const getCODRemittances = body => {
  if ( body.status ) {
    return request(`cod/orders?deliveryDate=to:${body.startDate},${body.endDate}&limit=500&status=${body.status}`)
  }

  return request(`cod/orders?deliveryDate=to:${body.startDate},${body.endDate}&limit=500&status=PaymentReceived,PaymentSubmittedToHub,RemittanceFailed,RemittanceRequested,RemittanceCompleted`)
};

export const updateCODOrderStatus = body => (
    request(`cod/orders`, { method: 'PATCH', body })
);
  
export const getNextCODTransactions = body => (
    request(``, {}, body.next)
);

export const getCODReports = body => (
    request(`cod/transactions/report?start=${body.startDate}&end=${body.endDate}&limit=${body.limit}&offset=${body.page}` , {} , process.env.REACT_APP_SECOND_API_URL)
);  

export const downloadAction = body => {
  return request(`users/account/${body.userId}/bank/download`, {method: 'GET'})
}