import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Routes from "./routes";
import store from "./store";
import "antd/dist/antd.css";
import "./index.css";
import { register } from "./serviceWorker.js";
import { PersistGate } from "redux-persist/integration/react";
import CookieConsent from "react-cookie-consent";

const base_url = window.location.origin;

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="entregoCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#ffff",
          fontSize: "13px",
          background: "#00B4A9",
        }}
        expires={150}
      >
        Our website uses cookies to help enhance your browsing experience.
        Continue to browse our site if you agree to our use of cookies.
        Otherwise, you may change your cookie settings. For information on how
        we protect your privacy, please read our{" "}
        <a
          href={`${base_url}/privacystatement/`}
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policy
        </a>
        .
      </CookieConsent>
      <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

register();
