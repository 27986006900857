import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Main from "../App";

const AdminRoute = ({ component: Component, isAuthenticated, profile, ...rest }) => {


const adminRole = profile.authorities || [];


// get all available roles for user
const availableRoles = adminRole.map(val=>{
    return val.name
});

// temporary will remove 
// if(adminRole.length > 0) 
// {
//     availableRoles.push('ROLE_ADMIN');
// }

// get current route role
const routeRole = rest.role;

// check if route role is inside available roles
const adminRouteChecking = availableRoles.includes(routeRole);

  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated && adminRole.length > 0) ? (
        <>
        {adminRouteChecking ? 
          <Main isAuthenticated={props.isAuthenticated}>
            <Component {...props} />
            </Main> : <>
                <Redirect to="/dashboard" />
            </> }
        </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
  profile: PropTypes.object
};

AdminRoute.defaultProps = {
    profile: {
        authorities: []
    }
}

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    profile: store.auth.profile
  };
}



export default connect(mapStateToProps)(AdminRoute);
