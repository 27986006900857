// all available roles
export const role = {
  ROLE_BOOK_MANAGED_ACCOUNT: 'ROLE_BOOK_MANAGED_ACCOUNT',
  ROLE_BOOK_MANAGED_ADMIN: 'ROLE_BOOK_MANAGED_ADMIN',
  ROLE_SSO_CUSTOMER_SERVICE: 'ROLE_SSO_CUSTOMER_SERVICE',
};

// validate roles here (only accepts arrays)
export const validateRole = (roles, allowedRoles) => {
  const availableRoles = roles.map((val) => val.name);

  // NOTE: if allowed role has an undefined value,
  // it will be available to all users
  if (allowedRoles === undefined) {
    return true;
  }

  // check if associated roles are in allowable roles
  const allowableRoles = allowedRoles || [];
  for (const allowableRole of allowableRoles) {
    if (availableRoles.includes(allowableRole)) {
      return true;
    }
  }
  return false;
};