
import request from "../../utils/api";

export const getAllCepBookingAccounts = (limit) =>
  request(`pbookings/accounts?platformCode=CEP&limit=${1000}`);

export const getCepClientDetails = id =>
  request(`pbookings/accounts/${id}`);

export const getCepUserBookings = (body) =>
  request(`pbookings/bookings?limit=${body.limit}&accountId=${body.id}`, {});

export const getCepUserDetails = (distinctIds) => {
  const allIds = distinctIds.join();
  return request(`users/adm/users?userId=${allIds}&selectCols=fullName`);
}
export const getCepBookingStatus = (bookingNumber) =>
  request(`pbookings/bookings/${bookingNumber}/status`);

  export const getCepProfileDetails = (id) =>
  request(`pbookings/accounts/personnel/verification/${id}`);  