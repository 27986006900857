import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getSurcharge as getSurchargeService,
  updateSurcharge as updateSurchargeService,
} from "./service";
import processAction from "../../../../utils/processAction";

function* getSurcharge() {
  yield processAction({
    service: getSurchargeService,
    success: actionTypes.GET_SURCHARGE_SUCCESS,
    failed: actionTypes.GET_SURCHARGE_FAILED
  });
}
function* updateSurcharge({ body }) {
  yield processAction({
    params: body,
    service: updateSurchargeService,
    success: actionTypes.UPDATE_SURCHARGE_SUCCESS,
    failed: actionTypes.UPDATE_SURCHARGE_FAILED
  });
}

function* watchSurchargeRequest() {
  yield all([
    takeLatest(actionTypes.GET_SURCHARGE_REQUEST, getSurcharge),
    takeLatest(actionTypes.UPDATE_SURCHARGE_REQUEST, updateSurcharge),
  ]);
}

export default [watchSurchargeRequest()];
