import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "../modules/auth/reducer";
import profile from "../modules/profile/reducer";
import booking from "../modules/booking/reducer";
import location from "../modules/location/reducer";
import credits from "../modules/credits/reducer";
import packages from "../modules/packages/reducer";
import admin from "../modules/admin/reducer";
import viewBooking from "../modules/dashboard/reducer";
import coverage from "../modules/coverage/reducer";
import home from "../modules/home/reducer";
import pouchbox from "../modules/pouch-box-request/reducer";
import shippingFee from "../modules/shipping-fee/reducer";
import cepBooking from "../modules/cep-booking/reducer";
import cepDashboard from "../modules/cep-dashboard/reducer";

const authPersistConfig = {
  key: "entrego_auth",
  storage
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  profile,
  location,
  booking,
  packages,
  credits,
  ...admin,
  viewBooking,
  coverage,
  home,
  pouchbox,
  shippingFee,
  cepBooking,
  cepDashboard,
});
