import { message } from 'antd';

export const actionTypes = {
    GET_USER_BY_EMAIL_REQUEST: "GET_USER_BY_EMAIL_REQUEST",
    GET_USER_BY_EMAIL_SUCCESS: "GET_USER_BY_EMAIL_SUCCESS",
    GET_USER_BY_EMAIL_FAILED: "GET_USER_BY_EMAIL_FAILED",

    GET_USER_BY_ROLE_REQUEST: "GET_USER_BY_ROLE_REQUEST",
    GET_USER_BY_ROLE_SUCCESS: "GET_USER_BY_ROLE_SUCCESS",
    GET_USER_BY_ROLE_FAILED: "GET_USER_BY_ROLE_FAILED",

    UPDATE_USER_ROLE_REQUEST: "UPDATE_USER_ROLE_REQUEST",
    UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
    UPDATE_USER_ROLE_FAILED: "UPDATE_USER_ROLE_FAILED",

    RESET_ACTION_TYPE: "RESET_ACTION_TYPE",
    RESET_ALL: "RESET_ALL",
};

export const actionCreators = {
    getUserByEmail: (body) => ({ type: actionTypes.GET_USER_BY_EMAIL_REQUEST, body }),
    getUserByRole: () => ({ type: actionTypes.GET_USER_BY_ROLE_REQUEST }),
    updateUserRole: (body) => ({ type: actionTypes.UPDATE_USER_ROLE_REQUEST, body }),
    resetActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
    resetAll: () => ({ type: actionTypes.RESET_ALL }),
};

const initialState = {
    actionType: '',
    isLoading: false,
    error: null,
    usersByEmail: [],
    usersByRole: [],
    usersResponse: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_BY_EMAIL_REQUEST:
        case actionTypes.GET_USER_BY_ROLE_REQUEST:
        case actionTypes.UPDATE_USER_ROLE_REQUEST:
        return Object.assign({}, state, {
                isLoading: true
            });

        case actionTypes.GET_USER_BY_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                usersByEmail: action.payload,
                isLoading: false,
            });
        case actionTypes.UPDATE_USER_ROLE_SUCCESS:
            return Object.assign({}, state, {
                usersResponse: action.payload,
                isLoading: false,
            });
        case actionTypes.GET_USER_BY_ROLE_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                actionType: action.type,
                usersByRole: action.payload.users || [],
            });
        case actionTypes.UPDATE_USER_ROLE_FAILED:
            message.error(action.error.message);
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error && action.error.message
            });
        case actionTypes.GET_USER_BY_ROLE_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error && action.error.message
            });
        case actionTypes.GET_USER_BY_EMAIL_FAILED:
            message.error('User not found');
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error && action.error.message
            });
        case actionTypes.RESET_ALL:
            return Object.assign({}, state, {
                usersByEmail: [],
                usersByRole: [],
                usersResponse: {},
            });
        case actionTypes.RESET_ACTION_TYPE:
            return Object.assign({}, state, {
                actionType: '',
            })
        default:
            return state;
    }
};
