import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as locationActionCreators } from "../../../location/reducer";
import { actionCreators as profileActionCreators } from "../../../auth/reducer";

import Page from "./Page";

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
  isLoading: state.auth.isLoading,
  isLocationLoading: state.location.isLoading,
  provinces: state.location.provinces,
  province: state.location.cities,
  city: state.location.barangays,
  actionType: state.location.actionType,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(locationActionCreators, dispatch),
  ...bindActionCreators(profileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
