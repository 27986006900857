export const actionTypes = {
    GET_COD_TRANSACTION_REQUEST: "GET_COD_TRANSACTION_REQUEST",
    GET_COD_TRANSACTION_SUCCESS: "GET_COD_TRANSACTION_SUCCESS",
    GET_COD_TRANSACTION_FAILED: "GET_COD_TRANSACTION_FAILED",

    GET_NEXT_COD_TRANSACTION_REQUEST: "GET_NEXT_COD_TRANSACTION_REQUEST",
    GET_NEXT_COD_TRANSACTION_SUCCESS: "GET_NEXT_COD_TRANSACTION_SUCCESS",
    GET_NEXT_COD_TRANSACTION_FAILED: "GET_NEXT_COD_TRANSACTION_FAILED",

    GET_COD_TRANSACTION_BY_REFERENCE_REQUEST: "GET_COD_TRANSACTION_BY_REFERENCE_REQUEST",
    GET_COD_TRANSACTION_BY_REFERENCE_SUCCESS: "GET_COD_TRANSACTION_BY_REFERENCE_SUCCESS",
    GET_COD_TRANSACTION_BY_REFERENCE_FAILED: "GET_COD_TRANSACTION_BY_REFERENCE_FAILED",

    GET_COD_REMITTANCE_REQUEST: "GET_COD_REMITTANCE_REQUEST",
    GET_COD_REMITTANCE_SUCCESS: "GET_COD_REMITTANCE_SUCCESS",
    GET_COD_REMITTANCE_FAILED: "GET_COD_REMITTANCE_FAILED",

    UPDATE_COD_STATUS_REQUEST: "UPDATE_COD_STATUS_REQUEST",
    UPDATE_COD_STATUS_SUCCESS: "UPDATE_COD_STATUS_SUCCESS",
    UPDATE_COD_STATUS_FAILED: "UPDATE_COD_STATUS_FAILED",

    BATCH_UPDATE_COD_STATUS_REQUEST: "BATCH_UPDATE_COD_STATUS_REQUEST",
    BATCH_UPDATE_COD_STATUS_SUCCESS: "BATCH_UPDATE_COD_STATUS_SUCCESS",
    BATCH_UPDATE_COD_STATUS_FAILED: "BATCH_UPDATE_COD_STATUS_FAILED",

    GET_COD_REPORTS_REQUEST: "GET_COD_REPORTS_REQUEST",
    GET_COD_REPORTS_SUCCESS: "GET_COD_REPORTS_SUCCESS",
    GET_COD_REPORTS_FAILED: "GET_COD_REPORTS_FAILED",

    RESET_TRANSACTIONS: "RESET_TRANSACTIONS",
    RESET_ACTION_TYPE: "RESET_ACTION_TYPE",

    GET_DOWNLOAD_REQUEST: "GET_DOWNLOAD_REQUEST",
    GET_DOWNLOAD_SUCCESS: "GET_DOWNLOAD_SUCCESS",
    GET_DOWNLOAD_FAILED: "GET_DOWNLOAD_FAILED"
  };
  
  export const actionCreators = {
    getCODTransactions: (body) => ({ type: actionTypes.GET_COD_TRANSACTION_REQUEST, body }),
    getNextCODTransactions: (body) => ({ type: actionTypes.GET_NEXT_COD_TRANSACTION_REQUEST, body }),
    getCODTransactionByReference: (body) => ({ type: actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_REQUEST, body }),
    getCODRemittances: (body) => ({ type: actionTypes.GET_COD_REMITTANCE_REQUEST, body }),
    updateCODOrderStatus: (body) => ({ type: actionTypes.UPDATE_COD_STATUS_REQUEST, body }),
    batchUpdateCODStatus: (body) => ({ type: actionTypes.BATCH_UPDATE_COD_STATUS_REQUEST, body }),
    getCODReports: (body) => ({ type: actionTypes.GET_COD_REPORTS_REQUEST, body }),
    resetTransactions: () => ({ type: actionTypes.RESET_TRANSACTIONS }),
    resetActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
    downloadAction: (body) => ({type:actionTypes.GET_DOWNLOAD_REQUEST, body})
  };
  
  const initialState = {
    actionType: '',
    isLoading: false,
    transactions: {},
    remittances: {},
    updatedTransactions: {},
    reports: {},
    error: null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_COD_TRANSACTION_REQUEST:
      case actionTypes.GET_NEXT_COD_TRANSACTION_REQUEST:
      case actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_REQUEST:
      case actionTypes.GET_COD_REMITTANCE_REQUEST:
      case actionTypes.GET_COD_REPORTS_REQUEST:
      case actionTypes.UPDATE_COD_STATUS_REQUEST:
      case actionTypes.BATCH_UPDATE_COD_STATUS_REQUEST:
      case actionTypes.GET_DOWNLOAD_REQUEST:  
        return Object.assign({}, state, {
          isLoading: true
        });
  
      case actionTypes.GET_COD_TRANSACTION_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          transactions: action.payload,
          actionType: action.type,
        });

      case actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          transactions: action.payload,
          actionType: action.type,
        });

      case actionTypes.GET_COD_REMITTANCE_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          remittances: action.payload,
          actionType: action.type,
        });

      case actionTypes.BATCH_UPDATE_COD_STATUS_SUCCESS:
      case actionTypes.UPDATE_COD_STATUS_SUCCESS:
        const batchOrders = state.updatedTransactions.orders;
        const batchSummary = state.updatedTransactions.summary;
        if (batchOrders !== undefined) {
          action.payload.orders = batchOrders.concat(action.payload.orders);
          action.payload.summary.total += batchSummary.total;
          action.payload.summary.failed += batchSummary.failed;
        }
        return Object.assign({}, state, {
          isLoading: false,
          updatedTransactions: action.payload,
          actionType: action.type,
        });
      
      case actionTypes.GET_NEXT_COD_TRANSACTION_SUCCESS:
        const orders = state.transactions.orders;
        if (orders !== undefined) {
          action.payload.orders = orders.concat(action.payload.orders)
        }
        return Object.assign({}, state, {
          isLoading: false,
          transactions: action.payload,
          actionType: action.type,
        });      
        
      case actionTypes.GET_COD_REPORTS_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          reports: action.payload,
          // actionType: action.type,
        });

        case actionTypes.GET_DOWNLOAD_SUCCESS:
          return Object.assign({}, state, {
            isLoading: false,
            reports: action.payload,
            // actionType: action.type,
          });
        
      case actionTypes.RESET_TRANSACTIONS:
        return Object.assign({}, state, {
          transactions: {},
          updatedTransactions: {},
          reports: {},
          remittances: {}
        });
      case actionTypes.RESET_ACTION_TYPE:
        return Object.assign({}, state, {
          actionType:'',
        });

      case actionTypes.GET_NEXT_COD_TRANSACTION_FAILED:
      case actionTypes.GET_COD_TRANSACTION_FAILED:
      case actionTypes.GET_COD_REMITTANCE_FAILED:
      case actionTypes.GET_COD_TRANSACTION_BY_REFERENCE_FAILED:
        return Object.assign({}, state, {
          actionType: action.type,
          isLoading: false,
          error: action.error && action.error.message,
          transactions: {},
        });
      case actionTypes.BATCH_UPDATE_COD_STATUS_FAILED:
      case actionTypes.UPDATE_COD_STATUS_FAILED:
        return Object.assign({}, state, {
          updatedTransactions: {},
          actionType: action.type,
          isLoading: false,
          error: action.error && action.error.message
        })
      case actionTypes.GET_COD_REPORTS_FAILED:
        return Object.assign({}, state, {
          actionType: action.type,
          isLoading: false,
          error: action.error && action.error.message
        });

        case actionTypes.GET_DOWNLOAD_FAILED:
        return Object.assign({}, state, {
          actionType: action.type,
          isLoading: false,
          error: action.error && action.error.message
        });
  
      default:
        return state;
    }
  };
  