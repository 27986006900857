import React from 'react'
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Air21Logo from "../../../images/Air21Booking.svg";
import './styles.css';
// import { Divider } from 'antd';
import ContactModal from "../../Modal";


const { Sider } = Layout;
class Page extends React.Component {
  state = {
    contactModalOpen: false
  };
  handleOpenModal = () => this.setState({ contactModalOpen: true });
  handleCloseModal = () => this.setState({ contactModalOpen: false });

  componentDidMount = () => {
    this.actionsideNavForDashbord()
  }
  actionsideNavForDashbord = () => {
    let pathname = this.props.history.location.pathname.replace("/", "")
    if (pathname === "dashboard") {
      document.querySelector(".site-layout").addEventListener("click", () => {
        if (document.querySelector(".bookingroute").style.left === "0px") {
          document.querySelector(".bookingroute").style.left = "-208px"
          document.querySelector(".site-layout").style.filter = "unset"
        }
      })
    }
  }

  render() {
    let pathname = this.props.history.location.pathname.replace("/", "");
    const { profile } = this.props;
    return (

      <div className={`sideNav ${pathname === "dashboard" || pathname === "book" ? "bookingroute" : ''}`} style={{
        borderRight: pathname === "dashboard" || pathname === "book" ? "unset" : "4px groove #712B81"
      }}>
        <Sider>
          <div className='sidenav-logo'>
            <img id='MainLogo' style={{
              width: pathname === "dashboard" || pathname === "book" ? "120px" : "150px"
            }} src={Air21Logo} alt="" />
            {
              pathname === "dashboard" || pathname === "book" ? (<div onClick={() => {
                document.querySelector(".bookingroute").style.left = "-208px"
                document.querySelector(".site-layout").style.filter = "unset"
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
                  <path d="M0 0V2.57143H25V0H0ZM0 7.71429V10.2857H25V7.71429H0ZM0 15.4286V18H25V15.4286H0Z" fill="#50B1A9" />
                </svg>
              </div>) : null
            }
          </div>

          {/* <div className="sideNav"
        style={{ borderRight: "4px groove #712B81" }}
      >
        <Sider>
          <div className='sidenav-logo'>
            <img id='MainLogo' src={myEntrego_Logo_Study} alt="" />
          </div> */}

          <Menu className='sideNav-menu-active'>

            {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_COD_CLIENT' || val.name === 'ROLE_CLIENT'
              || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' || val.name === 'ROLE_BOOK_MANAGED_ACCOUNT'
            ))) ?
              <Menu.Item className='sideNav-menu' default >
                <div className="row" >
                  <div className={`col-3 cstm-img-wrapper-svg ${pathname === "home" ? "cstm-img-svg-path-active" : ""}`}>

                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.5 17V11H12.5V17H17.5V9H20.5L10.5 0L0.5 9H3.5V17H8.5Z" fill="#50B1A9" />
                    </svg>
                  </div>
                  <div className='col-9' >
                    <Link to='/home'>
                      <div className={`anchor-text-properties-same-page ${pathname === "home" ? "active" : ""}`} >Home</div></Link>
                  </div>
                </div>
              </Menu.Item>
              : null}

            {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_COD_CLIENT' || val.name === 'ROLE_CLIENT' || val.name === 'ROLE_BRANCH_ADMIN'))) ?
              <Menu.Item className='sideNav-menu' >
                <div className="row" >
                  <div className={`col-3 cstm-img-wrapper-svg ${pathname === "dashboard" ? "cstm-img-svg-path-active" : ""}`}>
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.1659 7.059H17.3419V8.383H11.1659V7.059ZM11.1659 4.853H17.3419V6.177H11.1659V4.853ZM11.1659 9.265H17.3419V10.589H11.1659V9.265ZM18.2249 0H2.34288C1.87518 0.00131933 1.42701 0.187698 1.09629 0.518414C0.765579 0.84913 0.5792 1.2973 0.577881 1.765V13.235C0.5792 13.7027 0.765579 14.1509 1.09629 14.4816C1.42701 14.8123 1.87518 14.9987 2.34288 15H18.2249C18.6926 14.9987 19.1408 14.8123 19.4715 14.4816C19.8022 14.1509 19.9886 13.7027 19.9899 13.235V1.765C19.9886 1.2973 19.8022 0.84913 19.4715 0.518414C19.1408 0.187698 18.6926 0.00131933 18.2249 0ZM18.2249 13.235H10.2839V1.765H18.2249V13.235Z" fill="#712B81" />
                    </svg>
                  </div>
                  <div className='col-9' >
                    <Link to='/dashboard'>
                      <div className={`anchor-text-properties-same-page ${pathname === "dashboard" ? "active" : ""}`} >
                        Retail Bookings
                      </div>
                    </Link>
                  </div>
                </div>
              </Menu.Item>
              : null}
            {/* <Divider className='divider-properties' /> */}

            {/* commented as per deployment */}

            {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_BOOK_MANAGED_ACCOUNT' || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' ))) ?

              // {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN'))) ? 
              <Menu.Item className='sideNav-menu'>
                <div className="row" >
                  <div className={`col-3 cstm-img-wrapper-svg ${pathname === "cepdashboard" ? "cstm-img-svg-path-active" : ""}`}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" fill="#50B1A9" />
                    </svg>
                  </div>
                  <div className='col-9' >
                    <Link to='/cepdashboard'>
                      <div className={`anchor-text-properties-same-page ${pathname === "cepdashboard" ? "active" : ""}`} >Corporate Bookings</div></Link>
                  </div>
                </div>
              </Menu.Item>

              : null}
            {/* Commented the next lines since this is out dated and we still have future plan to use and update this one*/}
            {/* {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_COD_CLIENT' || val.name === 'ROLE_CLIENT'
             || val.name === 'ROLE_BRANCH_ADMIN' || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' || val.name ==='ROLE_RETAIL_USER' || val.name === 'ROLE_FINANCE' || val.name === 'ROLE_RETAIL_BRANCH_ADMIN'))) ?
              <Menu.Item className='sideNav-menu'>
                <div className="row" >
                  <div className={`col-3 cstm-img-wrapper-svg ${pathname === "shippingFee" ? "cstm-img-svg-path-active" : ""}`}>
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.5 17V11H12.5V17H17.5V9H20.5L10.5 0L0.5 9H3.5V17H8.5Z" fill="#004A87" />
                    </svg>
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24 0H3C1.35 0 0 1.35 0 3V24C0 25.65 1.35 27 3 27H24C25.65 27 27 25.65 27 24V3C27 1.35 25.65 0 24 0ZM15.045 6.09L16.635 4.5L18.75 6.615L20.865 4.5L22.455 6.09L20.34 8.205L22.455 10.32L20.865 11.91L18.75 9.81L16.635 11.925L15.045 10.335L17.16 8.22L15.045 6.09ZM4.875 7.08H12.375V9.33H4.875V7.08ZM12.75 19.5H9.75V22.5H7.5V19.5H4.5V17.25H7.5V14.25H9.75V17.25H12.75V19.5ZM22.5 21.375H15V19.125H22.5V21.375ZM22.5 17.625H15V15.375H22.5V17.625Z" fill="#004A87" />
                    </svg>
                  </div>
                  <div className='col-9' >
                    <Link to='/shippingFee'>
                      <div className={`anchor-text-properties-same-page ${pathname === "shippingFee" ? "active" : ""}`} >Shipping Fee Calculator</div>
                    </Link>
                  </div>
                </div>
              </Menu.Item>
              : null} */}
            {/* <Menu.Item className='sideNav-menu'>
              <div className="row">
                <div className={`col-3 cstm-img-wrapper-svg`}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6H4.28571V20H0V6ZM8 0H12V20H8V0ZM16 11.4286H20V20H16V11.4286Z" fill="white" />
                    <path d="M0 6H4.28571V20H0V6ZM8 0H12V20H8V0ZM16 11.4286H20V20H16V11.4286Z" fill="#712B81" />
                  </svg>
                </div>
                <div className='col-9'>
                  <a id='anchor-text-properties' target='_blank' rel="noopener noreferrer" href="https://book.air21.com.ph/rates">Rates</a>
                </div>
              </div>
            </Menu.Item> */}
            {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_COD_CLIENT' || val.name === 'ROLE_CLIENT' || val.name === 'ROLE_BRANCH_ADMIN' 
            || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' ))) ?
              <Menu.Item className='sideNav-menu'>
                <div className="row" >
                  <div className={`col-3 cstm-img-wrapper-svg ${pathname === "coverage" ? "cstm-img-svg-path-active" : ""}`}>
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 17H22V19H2V17ZM13.84 7.79C13.94 7.55 14 7.28 14 7C14 5.9 13.1 5 12 5C10.9 5 10 5.9 10 7C10 7.28 10.06 7.55 10.16 7.79C6.25 8.6 3.27 11.93 3 16H21C20.73 11.93 17.75 8.6 13.84 7.79Z" fill="#004A87" />
                    </svg> */}
                    <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 19H31.6667V22.1667H0V19ZM18.7467 4.4175C18.905 4.0375 19 3.61 19 3.16667C19 1.425 17.575 0 15.8333 0C14.0917 0 12.6667 1.425 12.6667 3.16667C12.6667 3.61 12.7617 4.0375 12.92 4.4175C6.72917 5.7 2.01083 10.9725 1.58333 17.4167H30.0833C29.6558 10.9725 24.9375 5.7 18.7467 4.4175Z" fill="#004A87" />
                    </svg>
                  </div>
                  <div className='col-9' >
                    <Link to='/coverage'>
                      <div className={`anchor-text-properties-same-page ${pathname === "coverage" ? "active" : ""}`} >Serviceable Areas</div></Link>
                  </div>
                </div>
              </Menu.Item>
              : null}
            {/* Commented the next lines since we still have future plan to use and update this one*/}
            {/* {
              (Object.keys(profile).length > 0 && profile.authorities.find(val => (
                (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_BRANCH_ADMIN' || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' || val.name === 'ROLE_RETAIL_BRANCH_ADMIN' )))) ?
                <Menu.Item className='sideNav-menu'>
                  <div className="row" >
                    <div className={`col-3 cstm-img-wrapper-svg ${pathname === "pouchbox" ? "cstm-img-svg-path-active" : ""}`}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.55 2.22L16.16 0.54C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.85 0.55L0.46 2.22C0.17 2.57 0 3.01 0 3.5V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.01 17.83 2.57 17.55 2.22ZM9 6.5L14.5 12H11V14H7V12H3.5L9 6.5ZM2.12 2L2.94 1H14.94L15.87 2H2.12Z" fill="white" />
                        <path d="M17.55 2.22L16.16 0.54C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.85 0.55L0.46 2.22C0.17 2.57 0 3.01 0 3.5V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.01 17.83 2.57 17.55 2.22ZM9 6.5L14.5 12H11V14H7V12H3.5L9 6.5ZM2.12 2L2.94 1H14.94L15.87 2H2.12Z" fill="#712B81" />
                      </svg>
                    </div>
                    <div className='col-9' >
                      <Link to='/pouchbox'>
                        <div className={`anchor-text-properties-same-page ${pathname === "pouchbox" ? "active" : ""}`} >Pouch/Box Request</div></Link>
                    </div>
                  </div>
                </Menu.Item>
                :
                (Object.keys(profile).length > 0 && profile.authorities.find(val => (
                  (val.name === 'ROLE_CLIENT' || val.name === 'ROLE_COD_CLIENT')))) ? null :
                  //   <Menu.Item className='sideNav-menu'>
                  //   <div className="row" >
                  //     <div className={`col-3 cstm-img-wrapper-svg ${pathname === "pouchbox" ? "cstm-img-svg-path-active" : ""}`}>
                  //       <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //         <path d="M17.55 2.22L16.16 0.54C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.85 0.55L0.46 2.22C0.17 2.57 0 3.01 0 3.5V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.01 17.83 2.57 17.55 2.22ZM9 6.5L14.5 12H11V14H7V12H3.5L9 6.5ZM2.12 2L2.94 1H14.94L15.87 2H2.12Z" fill="white" />
                  //         <path d="M17.55 2.22L16.16 0.54C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.85 0.55L0.46 2.22C0.17 2.57 0 3.01 0 3.5V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.01 17.83 2.57 17.55 2.22ZM9 6.5L14.5 12H11V14H7V12H3.5L9 6.5ZM2.12 2L2.94 1H14.94L15.87 2H2.12Z" fill="#712B81" />
                  //       </svg>
                  //     </div>
                  //     <div className='col-9' >
                  //       <Link to='/pouchbox'>
                  //         <div className={`anchor-text-properties-same-page ${pathname === "pouchbox" ? "active" : ""}`} >Pouch/Box Request</div></Link>
                  //     </div>
                  //   </div>
                  // </Menu.Item>
                  null
            } */}


            {/* : null} */}
            {/* Commented the next lines since this is out dated and we still have future plan to use and update this one*/}
            {/* {(Object.keys(profile).length > 0 && profile.authorities.find(val => (val.name === 'ROLE_RETAIL_ADMIN' || val.name === 'ROLE_COD_CLIENT' || val.name === 'ROLE_CLIENT'
             || val.name === 'ROLE_BRANCH_ADMIN' || val.name === 'ROLE_SSO_CUSTOMER_SUPPORT' || val.name === 'ROLE_RETAIL_USER' || val.name === 'ROLE_FINANCE' || val.name === 'ROLE_RETAIL_BRANCH_ADMIN'))) ?
              <Menu.Item className='sideNav-menu'>
                <div className="row">
                  <div className={`col-3 cstm-img-wrapper-svg`}>
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.7299 1.76955H11.0299C10.8488 1.25241 10.5115 0.804332 10.0646 0.487312C9.61768 0.170291 9.08331 0 8.53538 0C7.98746 0 7.45308 0.170291 7.00618 0.487312C6.55928 0.804332 6.22196 1.25241 6.04088 1.76955H2.34088C1.87316 1.77245 1.42551 1.95994 1.09534 2.29124C0.765165 2.62254 0.57919 3.07082 0.577881 3.53855V15.9225C0.5792 16.3913 0.766 16.8405 1.09747 17.172C1.42893 17.5034 1.87812 17.6902 2.34688 17.6915H14.7299C15.1986 17.6902 15.6478 17.5034 15.9793 17.172C16.3108 16.8405 16.4976 16.3913 16.4989 15.9225V3.53855C16.4976 3.06979 16.3108 2.6206 15.9793 2.28913C15.6478 1.95767 15.1986 1.77087 14.7299 1.76955ZM8.53788 1.76955C8.71292 1.76955 8.88402 1.82145 9.02956 1.9187C9.1751 2.01594 9.28853 2.15416 9.35551 2.31587C9.4225 2.47759 9.44002 2.65553 9.40588 2.8272C9.37173 2.99888 9.28744 3.15657 9.16367 3.28034C9.0399 3.40411 8.88221 3.48839 8.71054 3.52254C8.53886 3.55669 8.36092 3.53916 8.19921 3.47218C8.03749 3.4052 7.89928 3.29176 7.80203 3.14623C7.70479 3.00069 7.65288 2.82958 7.65288 2.65455C7.65341 2.41982 7.74696 2.19487 7.91303 2.02899C8.0791 1.86311 8.30415 1.76981 8.53888 1.76955H8.53788ZM10.3079 14.1525H4.11588V12.3835H10.3079V14.1525ZM12.9619 10.6145H4.11588V8.84555H12.9609L12.9619 10.6145ZM12.9619 7.07655H4.11588V5.30755H12.9609L12.9619 7.07655Z" fill="#712B81" />
                    </svg>
                  </div>
                  <div className='col-9'>
                    <a id='anchor-text-properties' target='_blank' rel="noopener noreferrer" href="https://book.air21.com.ph/faqs">FAQ'S</a>
                    <Link to='/faqs'>
                      <div className={`anchor-text-properties-same-page ${pathname === "faqs" ? "active" : ""}`} >FAQ'S</div>
                    </Link>
                  </div>
                </div>
              </Menu.Item>
              : null} */}
            <Menu.Item className='sideNav-menu' onClick={() => this.handleOpenModal()}>
              <div className="row">
                <div className={`col-3 cstm-img-wrapper-svg`}>
                  {/* <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.49 5.00045e-06C8.51262 0.00198239 6.5802 0.590152 4.93703 1.69017C3.29385 2.79018 2.01369 4.35266 1.25834 6.18009C0.502999 8.00753 0.306392 10.0179 0.693372 11.957C1.08035 13.8962 2.03355 15.6771 3.43247 17.0746C4.83139 18.4721 6.61324 19.4235 8.55278 19.8086C10.4923 20.1936 12.5025 19.995 14.3291 19.2378C16.1558 18.4807 17.717 17.1989 18.8154 15.5547C19.9138 13.9104 20.5 11.9774 20.5 10C20.5 8.68594 20.241 7.38477 19.7378 6.17086C19.2347 4.95695 18.4972 3.85412 17.5675 2.9254C16.6379 1.99668 15.5343 1.2603 14.3199 0.758338C13.1055 0.256379 11.8041 -0.00130906 10.49 5.00045e-06ZM17.42 6H14.47C14.157 4.76146 13.6936 3.5659 13.09 2.44001C14.9141 3.06801 16.4512 4.33173 17.42 6ZM10.5 2.04C11.34 3.25191 11.9845 4.58818 12.41 6H8.59C9.01549 4.58818 9.66001 3.25191 10.5 2.04ZM2.76 12C2.41332 10.6892 2.41332 9.3108 2.76 8H6.14C6.05288 8.66322 6.00612 9.33112 6 10C6.00612 10.6689 6.05288 11.3368 6.14 12H2.76ZM3.58 14H6.53C6.84297 15.2386 7.30642 16.4341 7.91 17.56C6.08397 16.9354 4.54584 15.6708 3.58 14ZM6.53 6H3.58C4.54584 4.32918 6.08397 3.06457 7.91 2.44001C7.30642 3.5659 6.84297 4.76146 6.53 6ZM10.5 17.96C9.66001 16.7481 9.01549 15.4118 8.59 14H12.41C11.9845 15.4118 11.34 16.7481 10.5 17.96ZM12.84 12H8.16C8.06122 11.3378 8.00777 10.6695 8 10C8.00736 9.33046 8.06082 8.66221 8.16 8H12.84C12.9392 8.66221 12.9926 9.33046 13 10C12.9922 10.6695 12.9388 11.3378 12.84 12ZM13.09 17.56C13.6936 16.4341 14.157 15.2386 14.47 14H17.42C16.4512 15.6683 14.9141 16.932 13.09 17.56ZM14.86 12C14.9471 11.3368 14.9939 10.6689 15 10C14.9939 9.33112 14.9471 8.66322 14.86 8H18.24C18.5867 9.3108 18.5867 10.6892 18.24 12H14.86Z" fill="#712B81" />
                  </svg> */}

                  <svg width="29" height="35" viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 0C6.38083 0 0 6.38083 0 14.25V25.3333C0 27.9617 2.12167 30.0833 4.75 30.0833H9.5V17.4167H3.16667V14.25C3.16667 8.1225 8.1225 3.16667 14.25 3.16667C20.3775 3.16667 25.3333 8.1225 25.3333 14.25V17.4167H19V30.0833H25.3333V31.6667H14.25V34.8333H23.75C26.3783 34.8333 28.5 32.7117 28.5 30.0833V14.25C28.5 6.38083 22.1192 0 14.25 0Z" fill="#004A87" />
                  </svg>
                </div>
                <div className='col-9'>
                  <div id='anchor-text-properties'>Contact Us</div>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item className='sideNav-menu'>
              <div className="row">
                <div className={`col-3 cstm-img-wrapper-svg`}>
                  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9.5H4V11.5H6V9.5ZM10 9.5H8V11.5H10V9.5ZM14 9.5H12V11.5H14V9.5ZM16 2.5H15V0.5H13V2.5H5V0.5H3V2.5H2C1.73786 2.50013 1.47833 2.55202 1.2363 2.6527C0.994268 2.75338 0.7745 2.90086 0.589606 3.08668C0.404713 3.27251 0.258334 3.49301 0.15887 3.73554C0.0594061 3.97808 0.00881501 4.23787 0.00999999 4.5L0 18.5C0 19.0304 0.210714 19.5391 0.585786 19.9142C0.960859 20.2893 1.46957 20.5 2 20.5H16C16.5299 20.4984 17.0377 20.2872 17.4125 19.9125C17.7872 19.5377 17.9984 19.0299 18 18.5V4.5C17.9984 3.97005 17.7872 3.46227 17.4125 3.08753C17.0377 2.7128 16.5299 2.50158 16 2.5ZM16 18.5H2V7.5H16V18.5Z" fill="#712B81" />
                  </svg>
                </div>
                <div className='col-9'>
                  {/* <a id='anchor-text-properties' target='_blank' rel="noopener noreferrer" href="https://book.air21.com.ph/termsofuse">Terms of Use</a> */}
                  <Link to='/termsofuse'>
                    <div className={`anchor-text-properties-same-page ${pathname === "termsofuse" ? "active" : ""}`} >Terms of Use</div>
                  </Link>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item className='sideNav-menu'>
              <div className="row">
                <div className={`col-3 cstm-img-wrapper-svg`}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.323 13.6079L9.70105 6.98195C10.0291 6.13046 10.1005 5.20152 9.9064 4.30991C9.71233 3.41829 9.26131 2.60307 8.60905 1.96495C7.91355 1.27933 7.02587 0.82162 6.06399 0.652643C5.1021 0.483665 4.11157 0.611427 3.22405 1.01895L6.35305 4.14795L4.17005 6.33095L0.969046 3.20195C0.545048 4.08516 0.408283 5.07898 0.577949 6.04389C0.747615 7.0088 1.21519 7.89635 1.91505 8.58195C2.55261 9.23635 3.36812 9.68936 4.26059 9.88489C5.15306 10.0804 6.08328 10.0098 6.93605 9.68195L13.558 16.3039C13.6235 16.3734 13.7024 16.4288 13.7901 16.4666C13.8777 16.5044 13.9721 16.5239 14.0675 16.5239C14.163 16.5239 14.2574 16.5044 14.345 16.4666C14.4326 16.4288 14.5116 16.3734 14.577 16.3039L16.251 14.6299C16.3317 14.5742 16.3988 14.501 16.4474 14.4159C16.496 14.3307 16.5249 14.2358 16.5319 14.138C16.5389 14.0402 16.5239 13.942 16.4879 13.8508C16.4519 13.7596 16.3959 13.6776 16.324 13.6109L16.323 13.6079Z" fill="#712B81" />
                  </svg>
                </div>
                <div className='col-9'>
                  {/* <a id='anchor-text-properties' target='_blank' rel="noopener noreferrer" href="https://book.air21.com.ph/termsandconditions">Terms & Conditions</a> */}
                  <Link to='/termsandconditions'>
                    <div className={`anchor-text-properties-same-page ${pathname === "termsandconditions" ? "active" : ""}`} >Terms & Conditions</div>
                  </Link>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item className='sideNav-menu'>
              <div className="row">
                <div className={`col-3 cstm-img-wrapper-svg`}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 8.5C9.29113 8.5 10.0645 8.26541 10.7223 7.82588C11.3801 7.38635 11.8928 6.76164 12.1955 6.03074C12.4983 5.29983 12.5775 4.49556 12.4231 3.71964C12.2688 2.94372 11.8878 2.23098 11.3284 1.67157C10.769 1.11216 10.0563 0.731202 9.28036 0.576861C8.50444 0.42252 7.70017 0.501733 6.96927 0.804484C6.23836 1.10723 5.61365 1.61992 5.17412 2.27772C4.7346 2.93552 4.5 3.70888 4.5 4.5C4.5 5.56087 4.92143 6.57828 5.67157 7.32843C6.42172 8.07857 7.43913 8.5 8.5 8.5ZM8.5 10.5C5.83 10.5 0.5 11.84 0.5 14.5V16.5H16.5V14.5C16.5 11.84 11.17 10.5 8.5 10.5Z" fill="#712B81" />
                  </svg>
                </div>
                <div className='col-9'>
                  {/* <a id='anchor-text-properties' target='_blank' rel="noopener noreferrer" href="https://book.air21.com.ph/privacystatement">Privacy Statement</a> */}
                  <Link to='/privacystatement'>
                    <div className={`anchor-text-properties-same-page ${pathname === "privacystatement" ? "active" : ""}`} >Privacy Statement</div>
                  </Link>
                </div>
              </div>
            </Menu.Item>
          </Menu>
        </Sider>

        <ContactModal
          title=" "
          visible={this.state.contactModalOpen}
          onCancel={this.handleCloseModal}
          content={
            <div className='contactus-modal'>
              <div>Feel free to send us a message!</div>
              <div id='contact-inquiries-styles'>For General inquiries, you may reach our customer support at </div>
              <div><a id='contact-style' href='customercare@af2100.com' target='_blank' rel="noopener noreferrer">customercare@af2100.com</a></div>
              <div id='contact-inquiries-styles'>Metro Manila:</div>
              <div id='contact-inquiries-styles'>+63 2 8854 2100</div>
              <div id='contact-inquiries-styles'>Operating Hours are Mondays to Saturdays</div>
              <div id='contact-inquiries-styles'>8:00 AM to 6:00 PM </div>
            </div>
          }
        />
      </div>
    )
  }

}

Page.propTypes = {
  profile: PropTypes.instanceOf(Object)
}

Page.defaultProps = {
  profile: {},
}
export default Page;
