import { all, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  getTest as getTestService,
} from "./service";
import processAction from "../../../../utils/processAction";

function* getTest() {
  yield processAction({
    service: getTestService,
    success: actionTypes.GET_TEST_SUCCESS,
    failed: actionTypes.GET_TEST_FAILED
  });
}

function* watchTrackingRequest() {
  yield all([
    takeLatest(actionTypes.GET_TEST_REQUEST, getTest),
  ]);
}

export default [watchTrackingRequest()];
