import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import routes from "./routes";
import adminRoutes from "./adminRoutes";
import PrivateRoute from "./Private";
import AdminRoute from "./Admin";
import NotFound from "./NotFound";
import PublicRoute from "./Public";

const Routes = () => {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          if (route.authL) {
            return <PrivateRoute key={i} {...route} />
          } else {
            return <PublicRoute key={i} {...route} />;
          }
        })}
        {/* render admin routes here */}
        {/* if admin key is available in route render AdminRoute Component  */}
        {adminRoutes.map((route, i) => {
          if (route.admin) {
            return <AdminRoute key={i} {...route} />
          } return null;
        })}
        {/* {routes.map((route, i) => {
          if(route.path !== '/'){
            return <MessengerCustomerChat
              key={i}
              pageId="413831412501708"
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
            />
          } 
          return null;  
        })} */}

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
