export const actionTypes = {
  CANCEL_PACKAGES_REQUEST: "CANCEL_PACKAGES_REQUEST",
  CANCEL_PACKAGES_SUCCESS: "CANCEL_PACKAGES_SUCCESS",
  CANCEL_PACKAGES_FAILED: "CANCEL_PACKAGES_FAILED",

  RESET_ACTION_TYPE: "RESET_ACTION_TYPE",
  CLEAR_CANCEL_PACKAGES_SUCCESS: "CLEAR_CANCEL_PACKAGES_SUCCESS",
};

export const actionCreators = {
  /**
  * Cancel packages action creator
  *
  * @param {{trackingNumbers: string[], cancellationReason: string}} body
  * @return {{type: string, body: {trackingNumbers: string[], cancellationReason: string}}}
  */
  cancelPackages: (body) => ({
    type: actionTypes.CANCEL_PACKAGES_REQUEST,
    body,
  }),

  resetViewActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
  clearCancelPackageSucess: () => ({ type: actionTypes.CLEAR_CANCEL_PACKAGES_SUCCESS }),
};

const initialState = {
  isLoading: false,
  errors: null,
  actionType: "",
  cancelPackageSucess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PACKAGES:
    case actionTypes.GET_BOOKING:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.GET_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      });

    case actionTypes.GET_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      });

    case actionTypes.GET_PACKAGES_FAILED:
    case actionTypes.GET_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false
      });

    case actionTypes.CANCEL_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        actionType: action.type,
        cancelPackageSucess: true
      });

    case actionTypes.CLEAR_CANCEL_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        cancelPackageSucess: false
      });

    case actionTypes.RESET_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: "",
      });


    default:
      return state;
  }
};
