import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as authActionCreators } from "../../../modules/auth/reducer";
import { actionCreators as locationActionCreators } from "../../../modules/location/reducer";
import Page from "./page";

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  profile: state.auth.profile,
  error: state.auth.error,
  actionType: state.auth.actionType,
  locationActionType: state.location.actionType
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(authActionCreators, dispatch),
  ...bindActionCreators(locationActionCreators, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);