import { all, takeLatest, call, put, select } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import { message } from "antd";
import {
  getProvinces as getProvincesService,
  getProfileAddress as getProfileAddressService,
  getProfileBarangay as getProfileBarangayService,
  getMunicipalities as getMunicipalitiesService,
  getMunicipalitiesSAC as getMunicipalitiesSACService,
  getBarangays as getBarangaysService,
  getBarangayById as getBarangayByIdService,
  getShippingFee as getShippingFeeService,
  getBulkShippingFee as getBulkShippingFeeService,
  servicableProvince as serviceableProvinceService,
  serviceableMunicipalities as serviceableMunicipalitiesService,
  serviceableSAR as serviceableSARService,
  getAllServiceableSAR as AllServiceableSARService,
  assignCodClientToUsers as assignCodClientToUsersService,
  getUserDetails as getUserDetailsService,
  updateUserAddress as updateUserDetailsService,
  getPHholidaysList as getPHholidaysListService,
  getSenderServiceableProvinces as senderServiceableProvinceService,
  getAllSenderServiceableSAR as AllSenderServiceableSARService,
  getSenderBarangays as getSenderBarangaysService,
} from "./service";
import processAction from "../../utils/processAction";

function* getProvinces() {
  yield processAction({
    service: getProvincesService,
    success: actionTypes.GET_PROVINCES_SUCCESS,
    failed: actionTypes.GET_PROVINCES_FAILED,
  });
}

function* getProfileAddress({ provinceId, cityId, barangayId }) {
  yield processAction({
    params: provinceId,
    service: getProfileAddressService,
    success: actionTypes.GET_PROFILE_ADDRESS_SUCCESS,
    failed: actionTypes.GET_PROFILE_ADDRESS_FAILED,
    secondAction: {
      action: actionTypes.GET_PROFILE_BARANGAY_REQUEST,
      id: barangayId,
    },
    secondParams: cityId,
  });
}

function* getProfileBarangay({ id }) {
  yield processAction({
    params: id,
    service: getProfileBarangayService,
    success: actionTypes.GET_PROFILE_BARANGAY_SUCCESS,
    failed: actionTypes.GET_PROFILE_BARANGAY_FAILED,
  });
}

function* getMunicipalities({ id, city }) {
  try {
    const payload = yield call(getMunicipalitiesService, id);
    let filteredCity;

    //filter if string
    if (typeof city === "string") {
      filteredCity = payload.cities.find((x) => x.name === city).id;
    }
    //change value to int
    if (typeof city === "number") {
      filteredCity = city;
    }

    yield put({ type: actionTypes.GET_MUNICIPALITIES_SUCCESS, payload });
    //check if not undefined dont call service
    if (typeof city !== "undefined") {
      yield put({ type: actionTypes.GET_BARANGAYS_REQUEST, id: filteredCity });
    }
  } catch (error) {
    message.error(error.message);
    yield put({ type: actionTypes.GET_MUNICIPALITIES_FAILED, error });
  }
}

function* getMunicipalitiesSAC({ id, city }) {
  try {
    const payload = yield call(getMunicipalitiesSACService, id);
    let filteredCity;

    //filter if string
    if (typeof city === "string") {
      filteredCity = payload.cities.find((x) => x.name === city).id;
    }
    //change value to int
    if (typeof city === "number") {
      filteredCity = city;
    }

    yield put({ type: actionTypes.GET_MUNICIPALITIES_SAC_SUCCESS, payload });
    //check if not undefined dont call service
    if (typeof city !== "undefined") {
      yield put({ type: actionTypes.GET_BARANGAYS_REQUEST, id: filteredCity });
    }
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.location.provinces;
      const province = provinces.find((x) => x.id === id);
      const { name = "" } = province;
      message.error(`${name} Province is not covered at the moment.`);
    } else {
      message.error(error.message);
    }
    yield put({ type: actionTypes.GET_MUNICIPALITIES_SAC_FAILED, error });
  }
}

function* getBarangays({ id }) {
  yield processAction({
    params: id,
    service: getBarangaysService,
    success: actionTypes.GET_BARANGAYS_SUCCESS,
    failed: actionTypes.GET_BARANGAYS_FAILED,
  });
}
function* getSenderBarangays({ id }) {
  yield processAction({
    params: id,
    service: getSenderBarangaysService,
    success: actionTypes.GET_SENDER_BARANGAYS_SUCCESS,
    failed: actionTypes.GET_SENDER_BARANGAYS_FAILED,
  });
}
function* getBarangayById({ id }) {
  yield processAction({
    params: id,
    service: getBarangayByIdService,
    success: actionTypes.GET_BARANGAY_ID_SUCCESS,
    failed: actionTypes.GET_BARANGAY_ID_FAILED,
  });
}

function* getShippingFee({ body }) {
  yield processAction({
    params: body,
    service: getShippingFeeService,
    success: actionTypes.GET_SHIPPING_FEE_SUCCESS,
    failed: actionTypes.GET_SHIPPING_FEE_FAILED,
  });
}

function* getBulkShippingFee({ body }) {
  yield processAction({
    params: body,
    service: getBulkShippingFeeService,
    success: actionTypes.GET_BULK_SHIPPING_FEE_SUCCESS,
    failed: actionTypes.GET_BULK_SHIPPING_FEE_FAILED,
  });
}

function* getPHholidaysList() {
  yield processAction({
    service: getPHholidaysListService,
    success: actionTypes.GET_PH_HOLIDAYS_LIST_SUCCESS,
    failed: actionTypes.GET_PH_HOLIDAYS_LIST_FAILED
  });
}

function* serviceableProvinces() {
  yield processAction({
    service: serviceableProvinceService,
    success: actionTypes.GET_SERVICEABLE_PROVINCES_SUCCESS,
    failed: actionTypes.GET_SERVICEABLE_PROVINCES_FAILED,
  });
}

function* getSenderServiceableProvinces() {
  yield processAction({
    service: senderServiceableProvinceService,
    success: actionTypes.GET_SENDER_SERVICEABLE_PROVINCES_SUCCESS,
    failed: actionTypes.GET_SENDER_SERVICEABLE_PROVINCES_FAILED,
  });
}

function* serviceableMunicipalities({ id }) {
  yield processAction({
    params: id,
    service: serviceableMunicipalitiesService,
    success: actionTypes.GET_SERVICEABLE_MUNICIPALITIES_SUCCESS,
    failed: actionTypes.GET_SERVICEABLE_MUNICIPALITIES_FAILED,
  });
}

function* serviceableSAR({ id }) {
  try {
    const payload = yield call(serviceableSARService, id);
    yield put({ type: actionTypes.GET_SERVICEABLE_SAR_SUCCESS, payload });
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.location.provinces;
      const province = provinces.find((x) => x.id === id);
      const { name = "" } = province;
      message.error(`${name} Province is not covered at the moment.`);
    } else {
      message.error(error.message);
    }
    yield put({ type: actionTypes.GET_SERVICEABLE_SAR_FAILED, error });
  }
}

function* getAllServiceableSAR({ id, city }) {
  try {
    const payload = yield call(AllServiceableSARService, id);
    let filteredCity;
    //filter if string
    if (typeof city === "string") {
      filteredCity = payload.cities.find((x) => x.name === city).id;
    }
    //change value to int
    if (typeof city === "number") {
      filteredCity = city;
    }
    yield put({ type: actionTypes.GET_ALL_SERVICEABLE_SAR_SUCCESS, payload });
    //check if not undefined dont call service
    if (typeof city !== "undefined") {
      yield put({ type: actionTypes.GET_BARANGAYS_REQUEST, id: filteredCity });
    }
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.location.provinces;
      const province = provinces.find((x) => x.id === id);
      const { name = "" } = province;
      message.error(`${name} Province is not covered at the moment.`);
    } else {
      message.error(error.message);
    }
    yield put({ type: actionTypes.GET_ALL_SERVICEABLE_SAR_FAILED, error });
  }
}

function* getAllSenderServiceableSAR({ id, city }) {
  try {
    const payload = yield call(AllSenderServiceableSARService, id);
    let filteredCity;
    //filter if string
    if (typeof city === "string") {
      filteredCity = payload.cities.find((x) => x.name === city).id;
    }
    //change value to int
    if (typeof city === "number") {
      filteredCity = city;
    }
    yield put({ type: actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR_SUCCESS, payload });
    //check if not undefined dont call service
    if (typeof city !== "undefined") {
      yield put({ type: actionTypes.GET_SENDER_BARANGAYS_REQUEST, id: filteredCity });
    }
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.location.senderProvinces;
      const province = provinces.find((x) => x.id === id);
      const { name = "" } = province;
      message.error(`${name} Province is not covered at the moment.`);
    } else {
      message.error(error.message);
    }
    yield put({ type: actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR_FAILED, error });
  }
}

function* assignCodClientToUsers({ body }) {
  yield processAction({
    params: body,
    service: assignCodClientToUsersService,
    success: actionTypes.ASSIGN_COD_CLIENT_TO_USERS_SUCCESS,
    failed: actionTypes.ASSIGN_COD_CLIENT_TO_USERS_FAILED
  });
}

function* getUserDetails({ id }) {
  yield processAction({
    params: id,
    service: getUserDetailsService,
    success: actionTypes.GET_USER_DETAILS_SUCCESS,
    failed: actionTypes.GET_USER_DETAILS_FAILED,
  });
}

function* updateUserAddress({ body }) {
  yield processAction({
    params: body,
    service: updateUserDetailsService,
    success: actionTypes.UPDATE_USER_DETAILS_SUCCESS,
    failed: actionTypes.UPDATE_USER_DETAILS_FAILED,
  });
}

function* watchLocationRequests() {
  yield all([
    takeLatest(actionTypes.GET_SERVICEABLE_SAR, serviceableSAR),
    takeLatest(actionTypes.GET_PROVINCES_REQUEST, getProvinces),
    takeLatest(actionTypes.GET_PROFILE_ADDRESS_REQUEST, getProfileAddress),
    takeLatest(actionTypes.GET_PROFILE_BARANGAY_REQUEST, getProfileBarangay),
    takeLatest(actionTypes.GET_SERVICEABLE_PROVINCES, serviceableProvinces),
    takeLatest(actionTypes.GET_MUNICIPALITIES_REQUEST, getMunicipalities),
    takeLatest(actionTypes.GET_MUNICIPALITIES_SAC_REQUEST, getMunicipalitiesSAC),
    takeLatest(
      actionTypes.GET_SERVICEABLE_MUNICIPALITIES,
      serviceableMunicipalities
    ),
    takeLatest(
      actionTypes.GET_SERVICEABLE_MUNICIPALITIES,
      serviceableMunicipalities
    ),
    takeLatest(actionTypes.GET_BARANGAYS_REQUEST, getBarangays),
    takeLatest(actionTypes.GET_BARANGAY_ID_REQUEST, getBarangayById),
    takeLatest(actionTypes.GET_SHIPPING_FEE, getShippingFee),
    takeLatest(actionTypes.GET_BULK_SHIPPING_FEE, getBulkShippingFee),
    takeLatest(actionTypes.GET_USER_DETAILS_REQUEST, getUserDetails),
    takeLatest(actionTypes.UPDATE_USER_DETAILS_REQUEST, updateUserAddress),
    takeLatest(actionTypes.ASSIGN_COD_CLIENT_TO_USERS_REQUEST, assignCodClientToUsers),
    takeLatest(actionTypes.GET_ALL_SERVICEABLE_SAR, getAllServiceableSAR),
    takeLatest(actionTypes.GET_PH_HOLIDAYS_LIST, getPHholidaysList),
    takeLatest(actionTypes.GET_SENDER_SERVICEABLE_PROVINCES, getSenderServiceableProvinces),
    takeLatest(actionTypes.GET_ALL_SENDER_SERVICEABLE_SAR, getAllSenderServiceableSAR),
    takeLatest(actionTypes.GET_SENDER_BARANGAYS_REQUEST, getSenderBarangays),

  ]);
}

export default [watchLocationRequests()];
