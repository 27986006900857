export const actionTypes = {

  GET_SERVICEABLE_CITIES_REQUEST: "GET_SERVICEABLE_CITIES_REQUEST",
  GET_SERVICEABLE_CITIES_SUCCESS: "GET_SERVICEABLE_CITIES_SUCCESS",
  GET_SERVICEABLE_CITIES_FAILED: "GET_SERVICEABLE_CITIES_FAILED",

  GET_BARANGAYS_UNFILTERED_REQUEST: "GET_BARANGAYS_FILTERED_REQUEST",
  GET_BARANGAYS_UNFILTERED_SUCCESS: "GET_BARANGAYS_FILTERED_SUCCESS",
  GET_BARANGAYS_UNFILTERED_FAILED: "GET_BARANGAYS_FILTERED_FAILED",

  CHECK_SERVICEABLE_AREA_REQUEST: "CHECK_SERVICEABLE_AREA_REQUEST",
  CHECK_SERVICEABLE_AREA_SUCCESS: "CHECK_SERVICEABLE_AREA_SUCCESS",
  CHECK_SERVICEABLE_AREA_FAILED: "CHECK_SERVICEABLE_AREA_FAILED",

};

export const actionCreators = {

  getServiceableCities: (id) => ({
    type: actionTypes.GET_SERVICEABLE_CITIES_REQUEST,
    id,
  }),

  getBarangaysUnfiltered: (id) => ({
    type: actionTypes.GET_BARANGAYS_UNFILTERED_REQUEST,
    id,
  }),

  serviceableAreaChecker: (body) => ({
    type: actionTypes.CHECK_SERVICEABLE_AREA_REQUEST, body
  }),

};

const initialState = {
  actionType: "",
  isLoading: false,
  sarCities: {},
  barangays: {},
  error: null,
  serviceInfo: {},
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_SERVICEABLE_CITIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        sarCities: action.payload,
      });

    case actionTypes.CHECK_SERVICEABLE_AREA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        serviceInfo: action.payload,
      });

    case actionTypes.GET_BARANGAYS_UNFILTERED_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_BARANGAYS_SUCCESS,
        barangays: action.payload,
      });

    case actionTypes.GET_SERVICEABLE_CITIES_FAILED:
    case actionTypes.GET_BARANGAYS_UNFILTERED_FAILED:
    case actionTypes.CHECK_SERVICEABLE_AREA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error && action.error.message,
      });

    default:
      return state;
  }
};
