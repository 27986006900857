import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../commons/Header";
import { Col, Row } from "antd";

const NotFound = ({ ...props }) => (
  <div>
    {props.isAuthenticated && (
      <Row>
        <Col span={24}>
          <Header />
        </Col>
      </Row>
    )}

    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <div>
          <h1 style={{ fontSize: 164 }}>404</h1>
          <h1>Page Not Found.</h1>
          <p>Whoops! The link is broken or the page has been moved.</p>
          <br />
          <Link to="/">Return to Home Page</Link>
        </div>
      </Col>
    </Row>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(NotFound);
