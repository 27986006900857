import { purgeStoredState } from "redux-persist";

export const setToken = (accessToken, refreshToken) => {
  window.localStorage.setItem("entregoTKN", accessToken);
  window.localStorage.setItem("entregoTKNRefresh", refreshToken);

  return accessToken;
};

export const getToken = () => {
  return window.localStorage.getItem("entregoTKN");
};

export const getRefreshToken = () => {
  return window.localStorage.getItem("entregoTKNRefresh");
};

export const deleteToken = () => {
  return window.localStorage.clear();
  // return window.localStorage.removeItem("entregoTKN");
};

export const backToLoginPage = () => {
  deleteToken();
  purgeStoredState();
  window.location.href = "/";
};
