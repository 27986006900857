const listOfCities = [
  'Angono',
  'Antipolo',
  'Binangonan',
  'Cainta',
  'San Mateo',
  'Taytay',
  'Bacoor',
  'Imus',
  'Carmona',
  'Dasmariñas',
  'Gen. Mariano Alvarez',
  'Noveleta',
  'Silang',
  'Angeles',
  'Mabalacat',
  'Magalang',
  'Biñan',
  'Cabuyao',
  'Lumban',
  'Magdalena',
  'Pagsanjan',
  'Pila',
  'San Pedro',
  'Santa Cruz',
  'Victoria',
  'Bulacan',
  'Calumpit',
  'Guiguinto',
  'Hagonoy',
  'Malolos',
  'Marilao',
  'Meycauayan',
  'Obando',
  'Paombong',
  'Plaridel',
  'Pulilan',
  'San Jose Del Monte',

];

export default listOfCities;