export const actionTypes = {
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED"
};

export const actionCreators = {
  getProfile: id => ({ type: actionTypes.GET_PROFILE, id })
};

const initialState = {
  isLoading: false,
  data: {},
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      return Object.assign({}, state, { isLoading: true });

    case actionTypes.GET_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload
      });

    case actionTypes.GET_PROFILE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors
      });

    default:
      return state;
  }
};
