import request from "../../utils/api";

export const book = body => request(`bookings`, { method: "POST", body });

export const updateBooking = body =>
  request(`bookings/status-update`, { method: "PATCH", body });

export const getUserBookings = (body) => request(`bookings/users/${body.userId}?page=${body.page}&size=${body.defaultSize}`, {});

export const getSurcharge = () => request(`bookings/rates/shipping-rate/surcharge`, {}); 

export const updateBookingStatus = body =>
  request(`bookings/status-update`, { method: "POST", body });

export const bulkUpload = body => {
  return  request(`bookings/packages/batch/validation`, { method: "POST", body });
}