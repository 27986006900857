import { all, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import {
  createPackage as createPackageService,
  getPackages as getPackagesService,
  getPackagesStatus as getPackagesStatusService,
  deletePackage as deletePackageService,
  updatePackage as updatePackageService,
  getNCRDetails as getNCRDetailsService
} from "./service";

import processAction from "../../utils/processAction";

function* getPackages({ id }) {
  yield processAction({
    params: id,
    service: getPackagesService,
    success: actionTypes.GET_PACKAGES_SUCCESS,
    failed: actionTypes.GET_PACKAGES_FAILED
  });
  yield processAction({
    params: id,
    service: getPackagesStatusService,
    success: actionTypes.GET_PACKAGES_STATUS_SUCCESS,
    failed: actionTypes.GET_PACKAGES_STATUS_FAILED,
  });
}

function* getPackageStatuses({ id }) {
  yield processAction({
    params: id,
    service: getPackagesStatusService,
    success: actionTypes.GET_PACKAGES_STATUS_SUCCESS,
    failed: actionTypes.GET_PACKAGES_STATUS_FAILED
  });
}

function* createPackage({ id, body }) {
  yield processAction({
    params: body,
    service: createPackageService,
    success: actionTypes.CREATE_PACKAGE_SUCCESS,
    failed: actionTypes.CREATE_PACKAGE_FAILED
  });

  yield put({ type: actionTypes.GET_PACKAGES, id });
}

function* getNCRDetails({ body }) {
  yield processAction({
    params: body,
    service: getNCRDetailsService,
    success: actionTypes.GET_NCR_DETAILS_SUCCESS,
    failed: actionTypes.GET_NCR_DETAILS_FAILED
  });
}


function* updatePackage({ id, body }) {
  yield processAction({
    params: body,
    service: updatePackageService,
    success: actionTypes.UPDATE_PACKAGE_SUCCESS,
    failed: actionTypes.UPDATE_PACKAGE_FAILED
  });

  yield put({ type: actionTypes.GET_PACKAGES, id });
}

function* finalizePackage({ id, body }) {
  yield processAction({
    params: body,
    service: updatePackageService,
    success: actionTypes.FINALIZE_PACKAGE_SUCCESS,
    failed: actionTypes.FINALIZE_PACKAGE_FAILED,
  });

  yield put({ type: actionTypes.GET_PACKAGES, id });
}

function* deletePackage({ id, bookingNumber }) {
  yield processAction({
    params: id,
    service: deletePackageService,
    success: actionTypes.DELETE_PACKAGE_SUCCESS,
    failed: actionTypes.DELETE_PACKAGE_FAILED
  });

  yield put({ type: actionTypes.GET_PACKAGES, id: bookingNumber });
}

function* watchPackageRequests() {
  yield all([
    takeLatest(actionTypes.GET_PACKAGES, getPackages),
    takeLatest(actionTypes.CREATE_PACKAGE, createPackage),
    takeLatest(actionTypes.UPDATE_PACKAGE, updatePackage),
    takeLatest(actionTypes.FINALIZE_PACKAGE, finalizePackage),
    takeLatest(actionTypes.DELETE_PACKAGE, deletePackage),
    takeLatest(actionTypes.GET_PACKAGES_STATUS, getPackageStatuses),
    takeLatest(actionTypes.GET_NCR_DETAILS, getNCRDetails)
  ]);
}

export default [watchPackageRequests()];
